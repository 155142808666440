import './style.css';

const BackgroundImage = () => {
  return (
    <div className='background-container'>
      <div className='background' />
    </div>
  );
};

export default BackgroundImage;
