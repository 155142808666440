import { CustomGripsConfig, Grips } from 'bluetooth/Bluetooth/Grips';
import {
  createFingerBitmask,
  postCurrentGrip,
  postFingerLimits,
  postGripConfig,
  postInitialGripPositions
} from 'configurator/bluetooth-handler/bluetoothFunctions';
import {
  useCreateCustomGrip,
  useCreateCustomGripTemplate,
  useDeleteCustomGrip,
  useGetCustomGrips,
  useGetCustomGripsTemplates,
  useUpdateCustomGrip,
  useUpdateDeviceConfig
} from 'configurator/hooks/api/useDevice';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { gripTypes } from 'constants/hand';
import { useState } from 'react';

const useCustomGrips = ({ selectedGrip, values, currentGrip, setValues, reset }) => {
  const [customGripAddMode, setCustomGripAddMode] = useState(false);
  const [selectedGripType, setSelectedGripType] = useState(gripTypes[0].id);
  const [showCustomGripTemplates, setShowCustomGripTemplates] = useState(false);
  const [lastSelectedGrip, setLastSelectedGrip] = useState<null | Grips>(null);
  const { setItemConfigStore, importConfig } = useConfigStore((state) => ({
    setItemConfigStore: state.setItemConfigStore,
    importConfig: state.importConfig
  }));
  const { connected: deviceConnected, deviceId } = useDeviceInfoStore((state) => ({
    connected: state.connected,
    deviceId: state.deviceId
  }));
  const { result: customGripsAllowed, isLoading: isLoadingCustomGrips } = useGetCustomGrips({
    deviceId
  });
  const { updateConfig, isLoading: isLoadingUpdateDeviceConfig } = useUpdateDeviceConfig();
  const { mutateAsync: createCustomGrip, isLoading: isLoadingCreateCustomGrip } =
    useCreateCustomGrip();
  const { mutateAsync: deleteCustomGrip, isLoading: isLoadingDeleteCustomGrip } =
    useDeleteCustomGrip();
  const { mutateAsync: createCustomGripTemplate, isLoading: isLoadingCreateCustomGripTemplate } =
    useCreateCustomGripTemplate();
  const { result: customGripsTemplates } = useGetCustomGripsTemplates();
  const { mutateAsync: updateCustomGrip, isLoading: isLoadingUpdateCustomGrip } =
    useUpdateCustomGrip();

  const customGripsLimitReached = customGripsAllowed?.length === 3;

  const handleAddCustomGrip = () => {
    reset();
    setSelectedGripType(gripTypes[0].id);
    setCustomGripAddMode(true);

    const isValidGrip = currentGrip !== null && currentGrip !== Grips.kGripTypeUnknown;
    setLastSelectedGrip(isValidGrip ? currentGrip : Grips.kGripPower);
    setItemConfigStore('handMovementAllowed', true);

    const gripOrder = [Grips.kGripCustom1, Grips.kGripCustom2, Grips.kGripCustom3];
    const firstMissingGrip = gripOrder.find(
      (grip) =>
        !customGripsAllowed?.some((customGripAllowed) => customGripAllowed?.grip_number === grip)
    );

    // If there's a missing grip, update the current grip
    if (firstMissingGrip) {
      setItemConfigStore('currentGrip', firstMissingGrip);
    }
  };

  const handleSubmitNewCustomGrip = async (e, saveTemplate = false) => {
    if (!deviceId) return;

    try {
      const result = await createCustomGrip({
        deviceId,
        data: {
          name: e.name,
          opposed: selectedGripType,
          grip_number: selectedGrip
        }
      });

      const configPayload = {
        deviceId,
        data: {
          common: JSON.stringify({
            gripsPositions: {
              [selectedGrip]: {
                initial: values[currentGrip].initial,
                limit: values[currentGrip].limit,
                activeFingers: values[currentGrip].activeFingers
              }
            }
          })
        }
      };
      const updatedConfig = await updateConfig(configPayload);
      if (saveTemplate) {
        await createCustomGripTemplate({
          data: {
            name: result.name,
            initial_position: JSON.stringify(values[currentGrip].initial),
            limit_position: JSON.stringify(values[currentGrip].limit),
            active_fingers: JSON.stringify(values[currentGrip].activeFingers)
          }
        });
      }
      importConfig({ common: updatedConfig?.common, modes: null });
      importConfig({ common: updatedConfig?.common, modes: null, importToApiConfig: true });
      setShowCustomGripTemplates(false);
      setCustomGripAddMode(false);
      setItemConfigStore('currentGrip', selectedGrip);
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddCustomGripTemplate = async (e) => {
    await createCustomGripTemplate({
      data: {
        name: e.name,
        initial_position: JSON.stringify(values[currentGrip].initial),
        limit_position: JSON.stringify(values[currentGrip].limit),
        active_fingers: JSON.stringify(values[currentGrip].activeFingers)
      }
    });
  };

  const handleDeleteCustomGrip = async () => {
    const customGripId = customGripsAllowed?.find(
      (customGrip) => customGrip?.grip_number === selectedGrip
    )?.id;
    if (!deviceId || !customGripId) return;

    await deleteCustomGrip({ deviceId, gripId: customGripId });
    setItemConfigStore('currentGrip', Grips.kGripPower);
  };

  const handleChangeGripTemplate = async (customGripTemplateId) => {
    const template = customGripsTemplates?.find(
      (customGripTemplate) => customGripTemplate?.id === customGripTemplateId
    );

    if (!template) return;

    const initialTemplate = JSON.parse(template?.initial_position);
    const limitTemplate = JSON.parse(template?.limit_position);
    const activeFingersTemplate = JSON.parse(template?.active_fingers);

    setValues((prev) => ({
      ...prev,
      [selectedGrip]: {
        initial: initialTemplate,
        limit: limitTemplate,
        activeFingers: activeFingersTemplate
      }
    }));

    if (customGripAddMode && deviceConnected) {
      await postInitialGripPositions(selectedGrip, initialTemplate);
      await postFingerLimits(selectedGrip, limitTemplate);
      await postGripConfig([
        selectedGrip,
        CustomGripsConfig.kActiveFingers,
        createFingerBitmask(activeFingersTemplate)
      ]);
      await postCurrentGrip(currentGrip);
      return;
    }
  };

  const handleUpdateCustomGrip = async (e) => {
    const customGripId = customGripsAllowed?.find(
      (customGrip) => customGrip?.grip_number === selectedGrip
    )?.id;
    if (!deviceId) return;

    await updateCustomGrip({
      deviceId,
      gripId: customGripId,
      data: { name: e.name, opposed: selectedGripType }
    });
  };

  return {
    handleDeleteCustomGrip,
    handleAddCustomGripTemplate,
    handleSubmitNewCustomGrip,
    customGripsAllowed,
    handleAddCustomGrip,
    customGripsLimitReached,
    customGripsTemplates,
    isLoadingCreateAndSaveTemplate:
      isLoadingCreateCustomGripTemplate || isLoadingCreateCustomGrip || isLoadingUpdateDeviceConfig,
    isLoadingCreate: isLoadingCreateCustomGrip || isLoadingUpdateDeviceConfig,
    showCustomGripTemplates,
    setShowCustomGripTemplates,
    isLoadingCustomGrips,
    isLoadingDeleteCustomGrip,
    setCustomGripAddMode,
    customGripAddMode,
    selectedGripType,
    setSelectedGripType,
    handleChangeGripTemplate,
    handleUpdateCustomGrip,
    isLoadingUpdateCustomGrip,
    lastSelectedGrip,
    setLastSelectedGrip
  };
};

export default useCustomGrips;
