import CustomTextField from 'components/FormFields/CustomTextField';
import CustomPasswordField from 'components/FormFields/CustomPasswordField';
import CustomLanguageSelect from 'components/FormFields/CustomLanguageSelect';
import { SectionHeader } from './styled';
import CustomPhoneField from 'components/FormFields/CustomPhoneFIeld';
import FormCheckbox from 'components/FormFields/FormCheckbox';
import PasswordStrength from 'configurator/components/atoms/PasswordStrength/PasswordStrength';
import { Control, UseFormWatch } from 'react-hook-form';
import i18n from 'i18n';
import CustomRegionSelect from 'components/FormFields/CustomRegionSelect';

interface BasicInfoProps {
  control: Control<any>;
  watch: UseFormWatch<any>;
  invitedUser?: boolean;
  setValue?: any;
}

const BasicInfo = ({ control, watch, invitedUser = false, setValue }: BasicInfoProps) => {
  const passwordWatch = watch('password');

  const openTermsPdf = () => {
    const pdfUrl =
      'https://aether-prod-bucket.s3.amazonaws.com/documents/POL-9_Terms_of_Service_of_Aether_Digital_Platform_v_2.pdf';
    window.open(pdfUrl, '_blank');
  };

  const openPolicyPdf = () => {
    const pdfUrl =
      'https://aether-prod-bucket.s3.us-east-1.amazonaws.com/documents/POL-8_Privacy_Policy_of_Aether_Digital_Platform_v_2.pdf';
    window.open(pdfUrl, '_blank');
  };
  return (
    <>
      <SectionHeader>{i18n.t('auth:component.register.subtitle', 'Basic Info')}</SectionHeader>
      <CustomTextField
        label={i18n.t('auth:register.form.name', 'Name')}
        id='name'
        control={control}
      />
      <CustomTextField
        label={i18n.t('auth:register.form.password', 'E-mail')}
        id='email'
        control={control}
        disabled={invitedUser}
      />
      {!invitedUser && (
        <CustomPhoneField
          label={i18n.t('common:phone_number', 'Phone number')}
          id='phone'
          noEndAdornment
          control={control}
          setValue={setValue}
        />
      )}
      <CustomPasswordField
        label={i18n.t('auth:component.register.form.password', 'Password')}
        id='password'
        control={control}
        noValidationMessage
      />
      <PasswordStrength password={passwordWatch} minLength={8} numbers specialChars upperCase />
      <CustomPasswordField
        label={i18n.t('auth:component.register.form.repassword', 'Re-type Password')}
        id='rePassword'
        control={control}
      />
      <CustomLanguageSelect
        id='language'
        label={i18n.t('auth:component.register.form.language', 'Language')}
        control={control}
        optional={false}
        showLabel={true}
        loading={false}
      />
      <CustomRegionSelect
        id='region'
        label={i18n.t('auth:component.register.form.region', 'Region')}
        control={control}
        invitedUser={invitedUser}
      />
      <FormCheckbox
        label={
          <span style={{ fontSize: '14px' }}>
            {i18n.t('auth:component.register.form.agree', 'I agree')}{' '}
            <span
              style={{ textDecoration: 'underline' }}
              onClick={(e) => {
                e.preventDefault();
                openTermsPdf();
              }}>
              {i18n.t('auth:component.register.form.terms_link', 'Terms of Service')}
            </span>
          </span>
        }
        id='termsOfService'
        control={control}
        shouldPreventChange
      />
      <FormCheckbox
        label={
          <span style={{ fontSize: '14px' }}>
            {i18n.t('auth:component.register.form.agree', 'I agree')}{' '}
            <span
              style={{ textDecoration: 'underline' }}
              onClick={(e) => {
                e.preventDefault();
                openPolicyPdf();
              }}>
              {i18n.t('auth:component.register.form.privacy_link', 'Private Policy')}
            </span>
          </span>
        }
        id='privacyPolicy'
        control={control}
        shouldPreventChange
      />
    </>
  );
};

export default BasicInfo;
