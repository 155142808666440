import React from 'react';
import { Autocomplete, InputLabel, TextField, Typography, CircularProgress } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { commonFormStyles, LabelStyle, LabelWrapper } from './commonStyles';
import {
  AutocompleteRenderOptionState,
  AutocompleteOwnerState,
  TextFieldProps
} from '@mui/material';
import CustomAvatar, { stringToColor } from 'adp-panel/components/CustomAvatar/CustomAvatar';
import CustomChip from 'components/CustomChip/CustomChip';
import { shortenString } from 'utils/utils';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import { UserEntry } from 'adp-panel/api/users/users.types';

interface FormComboBoxProps {
  id: string;
  label: string;
  control?: any;
  options: any;
  optionLabel: string;
  onChange?: any;
  disabled?: boolean;
  loading?: boolean;
  isOptionEqualToValue?: any;
  optional?: boolean;
  value?: any;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
    ownerState: AutocompleteOwnerState<any, false, false, false, 'div'>
  ) => React.ReactNode;
  multiple?: any;
  nameProperty?: string;
  showLabel?: boolean;
  placeholder?: string;
  noEndAdornment?: boolean;
  freeSolo?: boolean;
  style?: any;
  error?: boolean;
  helperText?: string | React.ReactNode;
  icon?: JSX.Element;
  onInputChange?: any;
  autoSelect?: boolean;
  autoHighlight?: boolean;
}

export const mapDevices = (devices: DeviceEntry[]) => {
  const mapper = (device) => ({
    fullName: `${device?.serial} (BT id: ${device?.bluetooth_id})`,
    ...device
  });

  if (devices.length > 0) {
    return devices.map(mapper);
  }

  return [];
};

export const mapPatients = (patients: UserEntry[]) => {
  const mapper = (patient) => ({
    ...patient,
    fullName: `${patient.name} ${patient.email !== null ? `(${patient.email})` : ''}`
  });
  if (patients.length > 0) {
    return patients?.map(mapper);
  }

  return [];
};

export const clinicianOption = (props, option, nameFullProperty, nameProperty) => {
  const { ...optionProps } = props;

  if (!option) return;

  const nameToRenderInAvatar = option[nameProperty] || option[nameFullProperty];
  return (
    <li
      style={{ display: 'flex', alignItems: 'center' }}
      {...optionProps}
      key={option.id || option[nameFullProperty]}>
      <CustomAvatar name={nameToRenderInAvatar} />
      <div style={{ marginLeft: '8px' }}>{option[nameFullProperty]}</div>
    </li>
  );
};

export const deviceOption = (props, option, nameFullProperty, patientName = null, t) => {
  const { ...optionProps } = props;

  if (!option) return;

  return (
    <li
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      {...optionProps}
      key={option.id || option[nameFullProperty]}>
      <div>{option[nameFullProperty]}</div>
      {patientName && (
        <CustomChip
          color='warning'
          label={t('devices:assigned_to', 'Assigned to') + ' ' + shortenString(patientName, 30)}
        />
      )}
    </li>
  );
};

const UserComboBoxAvatar = ({ name }: { name: string }) => (
  <CustomAvatar
    name={name}
    style={{
      bgcolor: stringToColor(name),
      fontSize: 12,
      width: 28,
      height: 28,
      position: 'absolute',
      transform: 'translateY(5px)',
      marginLeft: '16px',
      zIndex: 1
    }}
  />
);

export const CustomInputLabel = ({
  label,
  id,
  optional = false,
  optionalText,
  style
}: {
  label: string;
  id: string;
  optional?: boolean;
  optionalText?: string;
  style?: any;
}) => (
  <div style={{ display: 'flex', gap: '8px', ...style }}>
    <InputLabel
      shrink={false}
      htmlFor={id}
      sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
      <LabelWrapper>
        <Typography sx={{ ...LabelStyle }}>{label}</Typography>
        {optional && <Typography>({optionalText})</Typography>}
      </LabelWrapper>
    </InputLabel>
  </div>
);

const FormComboBox = ({
  id,
  label,
  control,
  options,
  optionLabel,
  onChange,
  disabled = false,
  loading = false,
  isOptionEqualToValue,
  optional = false,
  value = undefined,
  multiple,
  nameProperty,
  renderOption,
  showLabel = true,
  placeholder = '',
  noEndAdornment,
  freeSolo,
  style,
  error,
  helperText,
  icon,
  onInputChange,
  autoSelect,
  autoHighlight
}: FormComboBoxProps) => {
  const { t } = useTranslation();

  const AutocompleteCommonProps = {
    multiple,
    disablePortal: true,
    id,
    options: options ? options : [],
    disabled,
    getOptionLabel: (option) => option[optionLabel] || '',
    renderOption,
    isOptionEqualToValue,
    loading,
    autoSelect,
    autoHighlight
  };

  const TextFieldCommonProps: TextFieldProps = {
    InputLabelProps: {
      shrink: true
    },
    fullWidth: true,
    size: 'small',
    variant: 'outlined',
    id,
    name: id,
    placeholder
  };

  return control ? (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => {
        const selectedValue = () => {
          if (value !== undefined) return value;

          if (!field.value) {
            if (freeSolo) return undefined;
            return null;
          }

          return field.value;
        };

        return (
          <div style={style}>
            {showLabel && (
              <div style={{ display: 'flex' }}>
                <CustomInputLabel
                  id={id}
                  label={label}
                  optional={optional}
                  optionalText={t('common:form_combo_box.optional_label', 'Optional')}
                />
                {icon}
              </div>
            )}
            <Autocomplete
              {...field}
              onInputChange={(e, data) => {
                if (!freeSolo) return;

                if (e === null) return;
                field.onChange(data);
                if (onInputChange) onInputChange(data);
              }}
              onChange={(e, data) => {
                field.onChange(data);
                if (onChange) onChange(data);
              }}
              value={selectedValue()}
              // @ts-ignore
              freeSolo={freeSolo}
              {...AutocompleteCommonProps}
              renderInput={(params) => {
                const avatarName =
                  nameProperty && (field?.value?.[nameProperty] || value?.[nameProperty]);
                return (
                  <div style={{ position: 'relative' }}>
                    {avatarName && <UserComboBoxAvatar name={avatarName} />}
                    <TextField
                      {...params}
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress size={20} /> : null}
                              {!noEndAdornment && params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        },
                        htmlInput: {
                          ...params.inputProps,
                          ...(avatarName && {
                            style: { paddingLeft: '44px' }
                          })
                        }
                      }}
                      error={Boolean(fieldState.error) || error}
                      helperText={helperText || t(fieldState.error?.message || '')}
                      {...TextFieldCommonProps}
                    />
                  </div>
                );
              }}
            />
          </div>
        );
      }}
    />
  ) : (
    <div style={style}>
      {showLabel && (
        <CustomInputLabel
          id={id}
          label={label}
          optional={optional}
          optionalText={t('common:form_combo_box.optional_label', 'Optional')}
        />
      )}
      <Autocomplete
        onChange={(e, data) => {
          if (onChange) onChange(data);
        }}
        value={value}
        {...AutocompleteCommonProps}
        renderInput={(params) => {
          return (
            <div style={{ position: 'relative' }}>
              {nameProperty && value?.[nameProperty] && (
                <UserComboBoxAvatar name={value?.[nameProperty]} />
              )}
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color='inherit' size={20} /> : null}
                      {!noEndAdornment && params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
                inputProps={{
                  ...params.inputProps,
                  ...(nameProperty &&
                    value?.[nameProperty] && {
                      style: { paddingLeft: '44px' }
                    })
                }}
                error={error}
                helperText={helperText || ''}
                {...TextFieldCommonProps}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default FormComboBox;
