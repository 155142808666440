/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
import { useEffect } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { mapErrorMessage } from 'configurator/utils/notifications';
import toast from 'react-hot-toast';
import { importTemplate } from 'configurator/api/modes/modes';
import {
  ConfigNotesQueryParams,
  ConfigNoteQueryParams,
  ConfigTemplatesQueryParams,
  ConfigTemplateQueryParams,
  TemplateNotesQueryParams,
  TemplateNoteQueryParams,
  DeviceConfigBodyParams,
  TestConfigBodyParams
} from 'configurator/api/device/device.types';
import {
  getDeviceConfig,
  getDeviceConfigHistory,
  getDevice,
  getUserDevices,
  updateDeviceConfigEntry,
  getDeviceConfigHistoryEntry,
  getConfigEntryNotes,
  getConfigEntryNote,
  createConfigNote,
  deleteConfigNote,
  getDeviceConfigTemplates,
  createConfigTemplate,
  getDeviceConfigTemplate,
  deleteConfigTemplate,
  updateConfigTemplate,
  updateDevice,
  getTemplateNotes,
  getTemplateNote,
  createTemplateNote,
  deleteTemplateNote,
  updateDeviceConfig,
  sendTestConfig,
  updateConfigDemo,
  restoreConfig,
  checkDeviceSerial,
  addDeviceWithCode,
  getDevices,
  getCustomGrips,
  createCustomGrip,
  deleteCustomGrip,
  createCustomGripTemplate,
  getCustomGripsTemplates,
  updateCustomGrip
} from '../../api/device/device';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useTranslation } from 'react-i18next';

export const DEVICES_QUERY_KEY = 'devices';
export const DEVICE_MODELS_QUERY_KEY = 'device_models';
export const DEVICE_CONFIG_QUERY_KEY = 'device_config';
export const DEVICE_CONFIG_HISTORY_QUERY_KEY = 'device_config_history';
export const DEVICE_CONFIG_HISTORY_ENTRY_QUERY_KEY = 'device_config_history_entry';
export const USER_DEVICES_QUERY_KEY = 'user-devices';
export const CONFIG_ENTRY_NOTES_KEY = 'config_entry_notes';
export const CONFIG_ENTRY_NOTE_KEY = 'config_entry_note';
export const DEVICE_CONFIG_TEMPLATE_QUERY_KEY = 'device_config_template';
export const DEVICE_CONFIG_TEMPLATES_QUERY_KEY = 'device_config_templates';
export const TEMPLATE_NOTES_KEY = 'template_notes';
export const TEMPLATE_NOTE_KEY = 'template_note';
export const CHECK_DEVICE_SERIAL_QUERY_KEY = 'device_serial';
export const CUSTOM_GRIPS_QUERY_KEY = 'custom_grips';
export const CUSTOM_GRIPS_TEMPLATES_QUERY_KEY = 'custom_grips_templates';

export const useDevice = (deviceId: number, queryParams?: any, options?: any) => {
  const { data, isLoading, isError } = useQuery(
    [DEVICE_MODELS_QUERY_KEY, queryParams, deviceId],
    () => getDevice(deviceId, { ...queryParams }),
    { ...options }
  );

  return {
    result: data,
    isLoading,
    isError
  };
};

export const useDevicesList = (queryParams?: any, enabled = true) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [DEVICES_QUERY_KEY, queryParams],
    () => getDevices(queryParams),
    {
      enabled,
      onError(err) {
        mapErrorMessage(err);
      }
    }
  );

  return {
    result: data ? data.items : null,
    total: data?.paginator ? data.paginator.total : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useDevicesListInfinite = (queryParams?: any, enabled: boolean = true) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isSuccess,
    isRefetching,
    isRefetchError,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [DEVICES_QUERY_KEY, queryParams],
    ({ pageParam = 0 }) => getDevices({ ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    isSuccess
  };
};

export const useDeviceUpdate = () => {
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError, isSuccess } = useMutation(updateDevice, {
    onSuccess() {
      NotificationFactory.successNotification(t('notifications:device_updated', 'Device updated'));
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    isSuccess,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useAddDeviceWithCode = () => {
  const { data, mutateAsync, isLoading, isError, isSuccess, error } =
    useMutation(addDeviceWithCode);

  return {
    result: data,
    isSuccess,
    mutateAsync,
    isLoading,
    isError,
    error
  };
};

export const useDeviceConfig = (deviceId: number) => {
  const { data, isLoading, error } = useQuery(
    [DEVICE_CONFIG_QUERY_KEY],
    () => getDeviceConfig(deviceId),
    { enabled: !!deviceId }
  );

  return {
    result: data || null,
    isLoading,
    error
  };
};

export const useCheckDeviceSerial = (serial: any, enabled: boolean) => {
  const { data, isLoading, error, isSuccess } = useQuery(
    [CHECK_DEVICE_SERIAL_QUERY_KEY, serial],
    () => checkDeviceSerial(serial),
    { enabled }
  );

  return {
    result: data || null,
    isLoading,
    isSuccess,
    error
  };
};

export const useDeviceConfigTemplate = ({
  templateId,
  params = {}
}: {
  templateId: number;
  params?: ConfigTemplateQueryParams;
}) => {
  const { data, isLoading, error, refetch } = useQuery(
    [DEVICE_CONFIG_TEMPLATE_QUERY_KEY, templateId, params],
    () => getDeviceConfigTemplate({ templateId, params })
  );

  return {
    result: data || null,
    refetch,
    isLoading,
    error
  };
};

export const useDeviceConfigTemplates = ({
  params = {}
}: {
  params?: ConfigTemplatesQueryParams;
}) => {
  const { data, isLoading, error, refetch } = useQuery(
    [DEVICE_CONFIG_TEMPLATES_QUERY_KEY, params],
    () => getDeviceConfigTemplates({ params })
  );

  return {
    result: data || null,
    refetch,
    isLoading,
    error
  };
};

export const useCreateConfigTemplate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(createConfigTemplate, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:template_created', 'Template created')
      );
      queryClient.invalidateQueries([DEVICE_CONFIG_TEMPLATES_QUERY_KEY]);
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useUpdateConfigTemplate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(updateConfigTemplate, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:template_updated', 'Template updated')
      );
      queryClient.invalidateQueries([DEVICE_CONFIG_TEMPLATES_QUERY_KEY]);
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useDeleteConfigTemplate = () => {
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteConfigTemplate, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:template_deleted', 'Template deleted')
      );
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useDeviceConfigHistory = (deviceId: number, queryParams?: any) => {
  const { data, isLoading, error, refetch } = useQuery(
    [DEVICE_CONFIG_HISTORY_QUERY_KEY, deviceId, queryParams],
    () => getDeviceConfigHistory(deviceId, { ...queryParams }),
    {
      enabled: !!deviceId
    }
  );

  return {
    result: data || null,
    refetch,
    isLoading,
    error
  };
};

export const useDeviceConfigHistoryInfinite = (
  deviceId: number,
  queryParams?: any,
  dependency: any = true
) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [DEVICE_CONFIG_HISTORY_QUERY_KEY, deviceId, queryParams],
    ({ pageParam = 0 }) =>
      getDeviceConfigHistory(deviceId, { ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled: dependency
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useDeviceConfigHistoryEntry = (
  deviceId: number,
  configId: number,
  queryParams?: any
) => {
  const { data, isLoading, error, refetch } = useQuery(
    [DEVICE_CONFIG_HISTORY_ENTRY_QUERY_KEY, deviceId, configId, queryParams],
    () => getDeviceConfigHistoryEntry(deviceId, configId, { ...queryParams }),
    {
      enabled: !!deviceId && !!configId
    }
  );

  return {
    result: data || null,
    refetch,
    isLoading,
    error
  };
};

export const useDeviceHistory = (deviceId: number, queryParams: any) => {
  const { data, isLoading, error, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [DEVICE_CONFIG_HISTORY_QUERY_KEY, deviceId, queryParams],
    ({ pageParam = 0 }) =>
      getDeviceConfigHistory(deviceId, {
        ...queryParams,
        page: pageParam + 1
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      }
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result: data || null,
    fetchNextPage,
    isLoading,
    hasNextPage,
    error
  };
};

export const useUpdateDeviceConfig = () => {
  const {
    data,
    mutateAsync: createDeviceConfigRequest,
    isLoading
  } = useMutation(updateDeviceConfig);

  const updateConfig = ({ deviceId, data }: { deviceId: number; data: DeviceConfigBodyParams }) => {
    if (deviceId) {
      return createDeviceConfigRequest({
        deviceId,
        data
      });
    }
  };

  return {
    updateConfig,
    data,
    isLoading
  };
};

export const useSendTestConfig = () => {
  const { data, mutateAsync: sendTestConfigRequest, isLoading } = useMutation(sendTestConfig);

  const sendTest = ({ deviceId, data }: { deviceId: number; data: TestConfigBodyParams }) => {
    if (deviceId) {
      return sendTestConfigRequest({
        deviceId,
        data
      });
    }
  };

  return {
    sendTest,
    isLoading,
    data
  };
};

export const useUserDevices = (userId: number, queryParams?: any) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [USER_DEVICES_QUERY_KEY, queryParams],
    () => getUserDevices(userId, queryParams),
    {
      onError: (error) => {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data.items : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useUpdateDeviceConfigEntry = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(updateDeviceConfigEntry, {
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useUpdateConfigDemo = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(updateConfigDemo, {
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useConfigEntryNotes = ({
  deviceId,
  configId,
  params = {}
}: {
  deviceId: number;
  configId: number;
  params?: ConfigNotesQueryParams;
}) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [CONFIG_ENTRY_NOTES_KEY, params, deviceId, configId],
    () => getConfigEntryNotes({ deviceId, configId, params }),
    {
      enabled: !!deviceId && !!configId,
      onError: (error) => {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data.items : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useConfigEntryNote = ({
  deviceId,
  configId,
  noteId,
  params = {}
}: {
  deviceId: number;
  configId: number;
  noteId: number;
  params?: ConfigNoteQueryParams;
}) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [CONFIG_ENTRY_NOTE_KEY, params],
    () => getConfigEntryNote({ deviceId, configId, noteId, params }),
    {
      onError: (error) => {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data || null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useCreateConfigNote = () => {
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(createConfigNote, {
    onSuccess: () => {
      NotificationFactory.successNotification(t('notifications:note_added', 'Note added'));
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useDeleteConfigNote = () => {
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteConfigNote, {
    onSuccess: () => {
      NotificationFactory.successNotification(t('notifications:note_deleted', 'Note deleted'));
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useTemplateNotes = ({
  templateId,
  params = {}
}: {
  templateId: number;
  params?: TemplateNotesQueryParams;
}) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [TEMPLATE_NOTES_KEY, params, templateId],
    () => getTemplateNotes({ templateId, params }),
    {
      enabled: !!templateId,
      onError: (error) => {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data.items : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useTemplateNote = ({
  templateId,
  noteId,
  params = {}
}: {
  templateId: number;
  noteId: number;
  params?: TemplateNoteQueryParams;
}) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [TEMPLATE_NOTE_KEY, params],
    () => getTemplateNote({ templateId, noteId, params }),
    {
      onError: (error) => {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data || null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useCreateTemplateNote = () => {
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(createTemplateNote, {
    onSuccess: () => {
      NotificationFactory.successNotification(t('notifications:note_added', 'Note added'));
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useDeleteTemplateNote = () => {
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteTemplateNote, {
    onSuccess: () => {
      NotificationFactory.successNotification(t('notifications:note_deleted', 'Note deleted'));
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useRestoreConfig = () => {
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(restoreConfig, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:configuration_restored', 'Configuration restored')
      );
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useImportTemplate = () => {
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(importTemplate, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:template_imported', 'Template imported')
      );
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useGetCustomGrips = ({ deviceId }: { deviceId: any }) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [CUSTOM_GRIPS_QUERY_KEY, deviceId],
    () => getCustomGrips({ deviceId }),
    {
      enabled: Boolean(deviceId),
      onError() {
        NotificationFactory.errorNotification('Failed to fetch custom grips');
      }
    }
  );

  return {
    result: data ? data.items : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useCreateCustomGrip = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(createCustomGrip, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:api_notification.custom_grips.create.success', 'Custom grip created')
      );
      queryClient.resetQueries({ queryKey: [CUSTOM_GRIPS_QUERY_KEY] });
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useUpdateCustomGrip = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(updateCustomGrip, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:api_notification.custom_grips.update.success', 'Custom grip updated')
      );
      queryClient.resetQueries({ queryKey: [CUSTOM_GRIPS_QUERY_KEY] });
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useDeleteCustomGrip = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteCustomGrip, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:api_notification.custom_grips.delete.success', 'Custom grip removed')
      );
      queryClient.resetQueries({ queryKey: [CUSTOM_GRIPS_QUERY_KEY] });
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useCreateCustomGripTemplate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(createCustomGripTemplate, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t(
          'notifications:api_notification.custom_grips.template.create.success',
          'Custom grip template created'
        )
      );
      queryClient.resetQueries({ queryKey: [CUSTOM_GRIPS_TEMPLATES_QUERY_KEY] });
    },
    onError: (error) => {
      mapErrorMessage(error);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError
  };
};

export const useGetCustomGripsTemplates = () => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [CUSTOM_GRIPS_TEMPLATES_QUERY_KEY],
    () => getCustomGripsTemplates(),
    {
      onError() {
        NotificationFactory.errorNotification('Failed to fetch custom grips templates');
      }
    }
  );

  return {
    result: data ? data.items : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};
