import * as Sentry from '@sentry/react';

import { useDissociateUser } from 'adp-panel/hooks/api/useUsers';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useDetachUser = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: detachUser } = useDissociateUser();

  const handleSubmit = async (user_id: number) => {
    try {
      setIsLoading(true);
      await detachUser(user_id);
    } catch (error) {
      Sentry.captureException(error);
      NotificationFactory.errorNotification('Failed to remove user from users list');
    } finally {
      NotificationFactory.successNotification(
        t('notifications:detach_user_success', 'User removed user from users list')
      );
      setIsLoading(false);
    }
  };

  return { handleSubmit, isLoading };
};
