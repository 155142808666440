import React from 'react';
import { MainWrapper, Wrapper } from './styled';
import './style.css';
import { ReactComponent as LogoSVG } from 'assets/aether_biomedical_logo_2.svg';
import { LOGIN } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

const AuthLayout = ({
  children,
  lightVariant = false
}: {
  children: React.ReactNode;
  showUdi?: boolean;
  lightVariant?: boolean;
  noFooter?: boolean;
}) => {
  const redirection = LOGIN;
  const navigate = useNavigate();
  return (
    <Wrapper lightVariant={lightVariant} style={{ justifyContent: 'flex-start' }}>
      <div>
        <LogoSVG
          onClick={() => navigate(redirection)}
          style={{
            height: '40px',
            margin: '20px',
            cursor: 'pointer'
          }}
        />
      </div>
      <MainWrapper>{children}</MainWrapper>
    </Wrapper>
  );
};

export default AuthLayout;
