import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Center, Description, Text, Title, Wrapper } from './styled';
import CustomButton from 'components/Button/CustomButton';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD } from 'constants/routes';

const NotFoundScreenAuthentication = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Center>
      <Wrapper>
        <div>
          <Title>{t('common:component.not_found.title', '404')}</Title>
          <Description>{t('common:component.not_found.description', 'Page not found')}</Description>
        </div>
        <div>
          <Text>
            {t('common:component.not_found.footer', {
              defaultValue: '{{year}} © Aether Digital Platform',
              year: dayjs().format('YYYY')
            })}
          </Text>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CustomButton sx={{ marginTop: '10px' }} onClick={() => navigate(DASHBOARD)}>
            {t('common:error_component.home', 'Home')}
          </CustomButton>
        </div>
      </Wrapper>
    </Center>
  );
};

export default NotFoundScreenAuthentication;
