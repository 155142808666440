/* eslint-disable no-unused-vars */
import { Grips } from 'bluetooth/Bluetooth/Grips';
import i18n from 'i18n';

export const REQUIRED_SUPPORTED_FIRMWARE_VERSION = 10800;
export const MINIMAL_SUPPORTED_FIRMWARE_VERSION = 10800;
export const ACK_FIRMWARE = '02.03.00';
export const CURRENT_BOOTLOADER_VERSION = Number(process.env.REACT_APP_CURRENT_BOOTLOADER_VERSION);
export const ALLOW_MANUAL_UPDATE: boolean = process.env.REACT_APP_ALLOW_MANUAL_UPDATE === 'true';
export const REMOTE_SESSION_DEBUG: boolean = process.env.REACT_APP_REMOTE_SESSION_DEBUG === 'true';
export const MAIN_PCB_ORDER = 'C01PC-0100 - Main PCB';
export const MAIN_PCB_ORDER_V3 = 'C01PC-0100 - Main PCB V3';
export const MAIN_PCB_ORDER_V4 = 'C01PC-0105 - Main PCB V4';
export const ALLOWED_OS = ['Windows', 'OS X'];
export const CALIBRATION_PROCEDURE_TIMEOUT = 180000;
export const PCB_REPLACEMENT_VIDEO =
  'https://aetherbiomedical-images.s3.amazonaws.com/pcb_replacement.mp4';

export const GRIPS = [
  Grips.kGripPower,
  Grips.kGripTripodClosed,
  Grips.kGripTripodOpen,
  Grips.kGripPrecisionOpen,
  Grips.kGripPrecisionClosed,
  Grips.kGripTrigger,
  Grips.kGripKey,
  Grips.kGripHook,
  Grips.kGripMouse,
  Grips.kGripFingerPoint,
  Grips.kGripRestOpp,
  Grips.kGripRestNopp,
  Grips.kGripFingerPointOpen,
  Grips.kGripCounting,
  Grips.kGripCustom1,
  Grips.kGripCustom2,
  Grips.kGripCustom3
];

export const OpposedGrips = [
  Grips.kGripPower,
  Grips.kGripTripodOpen,
  Grips.kGripTripodClosed,
  Grips.kGripPrecisionOpen,
  Grips.kGripPrecisionClosed,
  Grips.kGripTrigger,
  Grips.kGripRestOpp
];

export const NonOpposedGrips = [
  Grips.kGripHook,
  Grips.kGripKey,
  Grips.kGripFingerPoint,
  Grips.kGripMouse,
  Grips.kGripRestNopp,
  Grips.kGripFingerPointOpen,
  Grips.kGripCounting
];

export const months = [
  'common:months.january',
  'common:months.february',
  'common:months.march',
  'common:months.april',
  'common:months.may',
  'common:months.june',
  'common:months.july',
  'common:months.august',
  'common:months.september',
  'common:months.october',
  'common:months.november',
  'common:months.december'
];
export const years = ['2022', '2023', '2024'];

export const MONTHS = [
  { id: 0, name: 'common:months.january' },
  { id: 1, name: 'common:months.february' },
  { id: 2, name: 'common:months.march' },
  { id: 3, name: 'common:months.april' },
  { id: 4, name: 'common:months.may' },
  { id: 5, name: 'common:months.june' },
  { id: 6, name: 'common:months.july' },
  { id: 7, name: 'common:months.august' },
  { id: 8, name: 'common:months.september' },
  { id: 9, name: 'common:months.october' },
  { id: 10, name: 'common:months.november' },
  { id: 11, name: 'common:months.december' }
];

export const YEARS = [
  { id: 2022, name: '2022' },
  { id: 2023, name: '2023' },
  { id: 2024, name: '2024' }
];

export const SUPPORTED_FEATURES = {
  remoteConfig: 'remote_config',
  singleElectrode: 'single-electrode',
  dum: 'dum',
  freezeModeEMG: 'freeze_mode_emg',
  feedbackType: 'feedback_type',
  speedCalibration: 'speed_calibration'
};

export enum HISTORY_EVENTS {
  chooseGrips = 'chooseGrips',
  emgSettings = 'emgSettings',
  gripsConfiguration = 'gripsConfiguration',
  prosthesisSettings = 'prosthesisSettings'
}

export enum LANGUAGES {
  english = 'en',
  polish = 'pl'
}

export enum FETCHING_STATES {
  loading = 'loading',
  successful = 'successful',
  failed = 'failed',
  idle = 'idle'
}
export const ChartsOptions = {
  stepX: 20,
  displayScaleX: true,
  callbackX(value) {
    return `${Math.round((value * 10) / 20) / 10}s`;
  }
};
