import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import {
  errorApiNotificationParse,
  errorNotification,
  mapErrorMessage
} from 'configurator/utils/notifications';
import { ServicingPartQueryParams } from '../../api/servicing/servicing.types';
import { createServicingRepair, getServicingParts } from '../../api/servicing/servicing';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useTranslation } from 'react-i18next';

export const SERVICING_PARTS_QUERY_KEY = 'servicingParts';

export const useServicingParts = (
  params: ServicingPartQueryParams = {},
  enabled: boolean = true
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [SERVICING_PARTS_QUERY_KEY, params],
    () => getServicingParts(params),
    {
      enabled,
      onError: (error) => {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data.items : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    total: data?.paginator ? data.paginator.total : null
  };
};

export const useServicingRepair = () => {
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError, isSuccess } = useMutation(createServicingRepair, {
    onSuccess() {
      NotificationFactory.successNotification(
        t('notifications:create_servicing_request', 'Create servicing repair request')
      );
    },
    onError(error: any) {
      if (errorApiNotificationParse(error).message === 'Request exceeds POST max size.') {
        errorNotification('Attachments size exceeds limit.');
        return;
      }
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isSuccess,
    isError
  };
};
