import { setAPIUrl } from 'adp-panel/api/utils/apiClient';
import AuthLayout from 'adp-panel/layouts/AuthLayout';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useTranslation } from 'react-i18next';
import { AuthParagraph, AuthTitle, AuthWrapperBox } from '../styled';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { FormWrapper } from '../Login/styled';
import { useEffect, useState } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import * as yup from 'yup';
import { emailSchema } from 'configurator/views/Register/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import { isStrongPassword } from 'configurator/views/Register/utils';
import CustomButton from 'components/Button/CustomButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LOGIN, REGISTER_USER_2FA } from '../../../../constants/routes';
import BasicInfo from './BasicInfo';
import { defaultLanguage } from 'components/FormFields/CustomLanguageSelect';
import ClinicsInfo from './ClinicsInfo';
import * as Sentry from '@sentry/react';

import { useRegisterInvitedUser } from 'hooks/useRegisterInvitedUser';
import BackgroundImage from '../BackgroundImage';
import useInvitationValidation from 'hooks/useInvitationValidation';
import { MODALS } from 'configurator/views/Modals';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useUserRegion } from 'adp-panel/hooks/api/useUserRegion';
import { loginUserSPA } from 'adp-panel/api/authentication/authentication';
import { getRegions } from 'components/FormFields/CustomRegionSelect';
import i18n from 'i18n';

const RegisterInvitedUser = () => {
  const { t } = useTranslation('auth');
  const { isLoading, handleSubmit } = useRegisterInvitedUser();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const expirationTime = searchParams.get('exp');
  const isExpired = useInvitationValidation(expirationTime);
  const { openModal } = useUiStore((state: any) => ({
    openModal: state.openModal
  }));
  const { checkRegion } = useUserRegion();

  const regions = getRegions();

  const fetchRegion = async () => {
    if (!email) {
      return;
    }
    try {
      const regionData = await checkRegion(email);
      const selectedRegion = regions.find((item) => item.region === regionData?.region);
      if (selectedRegion) setValue('region', selectedRegion);
      if (!regionData) {
        NotificationFactory.errorNotification(
          t(
            'common:notification.login.user_in_region_error',
            "We're sorry, but the email you entered is not associated with an account. Please check your email and try again."
          )
        );
        return;
      }
      setAPIUrl(regionData.endpoint);
      localStorage.setItem('endpoint', regionData.endpoint);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (isExpired) {
      navigate(redirection);
      openModal(MODALS.expiredInvitation);
    }
  }, [isExpired]);

  useEffect(() => {
    fetchRegion();
  }, []);

  const FORM_STEPS = [
    {
      id: 0,
      label: t('auth:register_user.title', 'Basic Info'),
      fieldsToValidated: [
        'name',
        'phone',
        'email',
        'password',
        'rePassword',
        'language',
        'region',
        'termsOfService',
        'privacyPolicy'
      ]
    },
    {
      id: 1,
      label: t('auth:register_form.step2.label', 'Clinicic`s Info'),
      fieldsToValidated: []
    }
  ];

  const [formStep, setFormStep] = useState(FORM_STEPS[0].id);

  const handleNext = async (names) => {
    const isStepValid = names ? await trigger(names) : true;
    if (isStepValid) setFormStep((prevActiveStep) => prevActiveStep + 1);
  };

  const setStep = (id: number) => {
    if (id > formStep) return;
    else setFormStep(id);
  };

  const onSubmit = async (data: any) => {
    const result = {
      token: token,
      name: data.name,
      email: data.email.trim(),
      password: data.password,
      language: data.language.code,
      region: data.region.region,
      clinic_name: data.clinicName,
      clinic_location: data.clinicLocation,
      address1: data.clinicAddress,
      address2: data.clinicAddress2,
      mfa_enabled: false
    };

    try {
      const newUser = await handleSubmit(result);
      i18n.changeLanguage(data.language.code);
      if (newUser.id) {
        await loginUserSPA({ email: newUser.email, password: data.password });
        window.location.assign(REGISTER_USER_2FA);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const registerInvitedUserSchema = yup.object().shape({
    name: yup.string().required(VALIDATOR_TEXT.REQUIRED),
    email: emailSchema.required(
      t('auth:component.register.email.validator', 'Email must be valid string')
    ),
    password: yup
      .string()
      .required('')
      .test('valid-pass', '', (value) => {
        if (value === undefined || value === null || value === '') return true;

        return isStrongPassword(value);
      })
      .nullable(),
    rePassword: yup
      .string()
      .required(VALIDATOR_TEXT.REQUIRED)
      .test('passwords-match', VALIDATOR_TEXT.PASSWORDS_MUST_MATCH, function (value) {
        const parentPassword = this.parent.password;
        if (parentPassword === undefined || parentPassword === null || parentPassword === '')
          return true;

        return parentPassword === value;
      }),
    language: yup
      .object({
        code: yup.string().typeError(VALIDATOR_TEXT.REQUIRED)
      })
      .nonNullable(VALIDATOR_TEXT.REQUIRED),
    region: yup
      .object()
      .required(t('auth:component.register.region.validator', 'Region is required')),
    termsOfService: yup
      .boolean()
      .oneOf(
        [true],
        t('auth:component.register.termsOfService.checkbox', 'You must accept the terms of service')
      )
      .required(
        t('auth:component.register.termsOfService.checkbox', 'You must accept the terms of service')
      ),
    privacyPolicy: yup
      .boolean()
      .oneOf(
        [true],
        t(
          'auth:component.register.termsOfService.checkbox',
          'You must accept the terms private policy'
        )
      )
      .required(
        t('auth:component.register.termsOfService.checkbox', 'You must accept the private policy')
      )
  });

  interface FormValues {
    name: string;
    email: string;
    password: string | null;
    rePassword: string;
    language: {
      code?: string;
    };
    region: object;
    termsOfService: boolean;
    privacyPolicy: boolean;
    mfaEnabled?: boolean;
  }

  const {
    handleSubmit: handleSubmitRegister,
    control,
    trigger,
    formState: { errors },
    watch,
    setValue
  } = useForm<FormValues>({
    resolver: yupResolver(registerInvitedUserSchema),
    mode: 'onChange',
    defaultValues: {
      language: defaultLanguage,
      email: email || '',
      password: '',
      region: { label: '', region: '' }
    }
  });

  const redirection = LOGIN;
  const navigate = useNavigate();

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '600px 1fr' }}>
      <div>
        <AuthLayout lightVariant noFooter>
          <AuthWrapperBox>
            <AuthTitle>{t('auth:component.register.title', 'Create account')}</AuthTitle>
            <AuthParagraph variant='body2'>
              {t(
                'auth:component.register.paragraph_invited',
                'Set up an account in two simple steps. Fill information below to continue registration.'
              )}
            </AuthParagraph>
            <form onSubmit={handleSubmitRegister(onSubmit)}>
              <Stepper alternativeLabel activeStep={formStep} sx={{ marginBottom: '25px' }}>
                {FORM_STEPS.map((step) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  return (
                    <Step key={step.label} {...stepProps} onClick={() => setStep(step.id)}>
                      <StepLabel
                        sx={{ '& .MuiStepLabel-label': { fontSize: '16px' } }}
                        {...labelProps}>
                        {step.label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <FormWrapper>
                <div
                  style={{ display: formStep === FORM_STEPS[0].id ? 'grid' : 'none', gap: '5px' }}>
                  <BasicInfo control={control} watch={watch} invitedUser />
                </div>
                <div
                  style={{ display: formStep === FORM_STEPS[1].id ? 'grid' : 'none', gap: '5px' }}>
                  <ClinicsInfo control={control} />
                </div>
                <FormButtonsWrapper>
                  <CustomButton type='button' color='light' onClick={() => navigate(redirection)}>
                    {t('auth:component.register_details.button.cancel', 'Cancel')}
                  </CustomButton>
                  {formStep === FORM_STEPS[1].id ? (
                    <LoadingButton type='submit' loading={isLoading}>
                      {t('auth:register.button.submit', 'Submit')}
                    </LoadingButton>
                  ) : (
                    <CustomButton
                      type='button'
                      onClick={() => handleNext(FORM_STEPS[formStep].fieldsToValidated)}>
                      {t('auth:register.button.next_step', 'Next step')}
                    </CustomButton>
                  )}
                </FormButtonsWrapper>
              </FormWrapper>
            </form>
          </AuthWrapperBox>
        </AuthLayout>
      </div>
      <BackgroundImage />
    </div>
  );
};

export default RegisterInvitedUser;
