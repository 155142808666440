import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  checkMfaStatus,
  fetchUserData,
  loginUserSPA,
  sendMfaCode,
  setMfaStatus,
  verifyMfaCode,
  verifyPhone
} from 'adp-panel/api/authentication/authentication';
import { MfaOptions, MfaRequire } from 'adp-panel/api/authentication/authentication.types';
import { ME_QUERY_KEY, USER_QUERY_KEY } from 'adp-panel/hooks/api/useUsers';
import {
  registerInvitedUser,
  registerNewUser,
  registerUser
} from 'configurator/api/authentication/authentication';
import { REDIRECT } from 'constants/routes';
import CryptoJS from 'crypto-js';
import i18next from 'i18next';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from 'reducers/authStore';
import { mapErrorMessage } from 'utils/notifications';
import useRefreshToken from '../adp-panel/hoc/useRefreshToken';
import useLogout from './useLogout';
import regionAPI from 'adp-panel/api/utils/apiRegionClient';
import { setAPIUrl } from 'adp-panel/api/utils/apiClient';
import i18n from 'i18n';
import parseApiError from 'adp-panel/utils/parseApiError';
import { AxiosError } from 'axios';
import { useUserRegion } from 'adp-panel/hooks/api/useUserRegion';

export const USER_QUERY_MFA = 'mfa';
export const useAuthentication = (blockRedirect = false) => {
  const broadcast = new BroadcastChannel('login');
  const { i18n } = useTranslation();
  const [is2FaPage, setIs2FaPage] = useState(false);
  const { mfaCodeRequire, mfaData } = useAuthStore((state) => ({
    mfaCodeRequire: state.mfaCodeRequire,
    mfaData: state.mfa
  }));
  const { refreshToken } = useRefreshToken();
  const { mfaLogout, isLoading: mfaLogoutLoading } = useLogout();
  const { isLoading: isLoadingSendCode, mutate: sendCode } = useMutation(sendMfaCode);
  const data: MfaRequire = {
    require: false,
    channel: null
  };
  const [isLoadingAuthentication, setIsLoadingAuthentication] = useState(false);

  const { getUserRegion, isLoading: isLoadingRegion } = useUserRegion();

  const login = async (data: any) => {
    setIsLoadingAuthentication(true);
    const regionFound = await getUserRegion(data.email);

    if (regionFound) {
      loginAPI(data);
    }
    setIsLoadingAuthentication(false);
  };

  const {
    isLoading: isLoadingVerify,
    isError: isErrorVerify,
    mutate: verifyCode
  } = useMutation(verifyMfaCode, {
    onSuccess: async (result, variables) => {
      setIsLoadingAuthentication(true);
      Promise.all([refreshToken, fetchUserData]).finally(() => {
        const hash = localStorage.getItem('hash');
        if (hash) {
          localStorage.setItem(hash, result.mfa_token);
        }
        mfaCodeRequire(data);
        if (variables?.blockRedirect !== true) {
          broadcast.postMessage({ typ: 'LOG_IN' });
        }
        setIsLoadingAuthentication(false);
      });
    },
    onError() {
      NotificationFactory.errorNotification(
        'We\'re sorry, but the MFA code you entered is no longer valid or invalid. To continue, please click on "Resend code" to generate a new MFA code that will be valid for 15 minutes.'
      );
    }
  });

  const setTokenToStorage = (userData: any): Promise<void> => {
    return new Promise((resolve) => {
      const hash = CryptoJS.SHA1(String(userData.email)).toString(CryptoJS.enc.Base64);
      localStorage.setItem('hash', hash);
      resolve();
    });
  };

  const {
    isLoading: isLoadingLogin,
    mutate: loginAPI,
    data: userData,
    error: errorLogin
  } = useMutation(loginUserSPA, {
    onSuccess: async (userData, variables) => {
      setIsLoadingAuthentication(true);
      await i18n.changeLanguage(userData.language);
      setTokenToStorage(userData).then(async () => {
        try {
          await fetchUserData();
          refreshToken().finally(() => {
            if (variables?.blockRedirect !== true) {
              broadcast.postMessage({ typ: 'LOG_IN' });
            }
            setIsLoadingAuthentication(false);
          });
        } catch (error: any) {
          if (error.isAxiosError) {
            if (error.response.status === 401 && error.response?.data?.method) {
              sendCode({
                channel: error.response.data.method
              });

              mfaCodeRequire({
                require: true,
                channel: error.response.data.method
              });

              setIs2FaPage(true);
            }

            if (error.response.status === 422) {
              NotificationFactory.errorNotification('Invalid email or password');
            }
          }
          setIsLoadingAuthentication(false);
        }
      });
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  const handleLogout = () => {
    mfaLogout();
  };

  const reSendCode = (method: any = undefined) => {
    sendCode({
      channel: method ?? mfaData.channel ?? MfaOptions.email
    });
  };

  useEffect(() => {
    broadcast.onmessage = () => {
      window.location.assign(REDIRECT);
    };
  }, []);

  return {
    isLoading:
      isLoadingSendCode ||
      isLoadingLogin ||
      isLoadingVerify ||
      mfaLogoutLoading ||
      isLoadingAuthentication ||
      isLoadingRegion,
    reSendCode,
    is2FaPage,
    login,
    verifyCode,
    isErrorVerify,
    mfaData,
    handleLogout,
    userData,
    errorLogin
  };
};

export const useMfaSend = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(sendMfaCode, {
    onSuccess: (successData) => {
      queryClient.setQueryData([USER_QUERY_MFA], successData);
      queryClient.invalidateQueries({ queryKey: [ME_QUERY_KEY] });
      NotificationFactory.successNotification(
        i18n.t('notifications:api_notification.code_send', 'Code send')
      );
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useMfaUpdate = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(setMfaStatus, {
    onSuccess: (successData) => {
      queryClient.setQueryData([USER_QUERY_MFA], successData);
      queryClient.invalidateQueries({ queryKey: [ME_QUERY_KEY] });
      NotificationFactory.successNotification(
        t('notifications:mfa_settings_updated', 'User two factor authentication settings updated')
      );
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useMfaVerify = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(verifyMfaCode, {
    onSuccess: (successData) => {
      queryClient.setQueryData([USER_QUERY_MFA], successData);
      queryClient.invalidateQueries({ queryKey: [ME_QUERY_KEY] });
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useVerifyPhone = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(verifyPhone, {
    onSuccess() {
      NotificationFactory.successNotification(
        i18next.t(
          'notifications:notification.phone_number_verified',
          'Phone number was successfully verified'
        )
      );
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useMfaMethod = () => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [USER_QUERY_MFA],
    checkMfaStatus,
    {
      retry: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError(error) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useRegisterUser = () => {
  const { data, mutateAsync, isLoading, isError, error, isSuccess } = useMutation(registerUser, {
    onError: (err) => {
      mapErrorMessage(err);
    }
  });

  return {
    mutateAsync,
    data,
    isLoading,
    isError,
    error,
    isSuccess
  };
};

export const useCreaterNewUser = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(registerNewUser, {
    onSuccess() {
      queryClient.invalidateQueries([USER_QUERY_KEY]);
      NotificationFactory.successNotification(
        t('notifications:request.register.create.success_toast', 'Account has been created')
      );
    },
    onError(error: AxiosError<any, any>) {
      const parsedError = parseApiError(error);
      const isActivationCodeInvatid = parsedError.message === 'Activation code is incorrect';
      const customMessageInvaidCode =
        'Activation code is incorrect, please check it in second step';
      isActivationCodeInvatid
        ? mapErrorMessage(error, customMessageInvaidCode)
        : mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useCreaterInvitedUser = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(registerInvitedUser, {
    onSuccess() {
      queryClient.invalidateQueries([USER_QUERY_KEY]);
      NotificationFactory.successNotification(
        t('notifications:request.register.create.success_toast', 'Account has been created')
      );
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
