/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import uniqid from 'uniqid';
import { Tooltip } from '@mui/material';
import { ReactComponent as AngleDown } from 'assets/angle-down-solid.svg';
import { TextM, TextS } from '../Typography/Typography';
import { OpacityAnimation } from '../Animations/Animations';
import { myTheme } from 'theme/theme';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const gripsBackground = myTheme.palette.gripsBackground;
const selectBorderColor = '#d0d5dd';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    min-width: 180px;
  }
`;

const LabelImage = styled.img`
  width: 120px;
  height: 120px;
  padding: 6%;
  border-radius: 50%;
  background-color: ${gripsBackground};
  border: 1px solid #667085;
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 140px;
    height: 140px;
    padding: 8px;
  }
`;

const Box = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  border: 1px solid ${selectBorderColor};
  border-radius: 5px;
  font-size: 14px;
  background-color: ${myTheme.palette.primaryLight};
  cursor: pointer;
  padding: 5px;
`;

const Choosen = styled.div`
  padding: 5px 30px 5px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Label = styled.div`
  ${TextM};
  margin-bottom: 15px;
`;

const Select = styled.ul`
  position: absolute;
  width: max-content;
  display: grid;
  grid-template-columns: repeat(3, 100px);
  border: 1px solid ${selectBorderColor};
  padding: 5px;
  left: 0;
  list-style: none;
  z-index: 1000;
  background-color: ${myTheme.palette.primaryLight};
  animation: ${OpacityAnimation} 0.3s linear;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    grid-template-columns: repeat(3, 125px);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    grid-template-columns: repeat(3, 165px);
  }
`;

const Option = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7%;

  &&:hover {
    .dropdown-image {
      transform: scale(1.03);
    }

    .dropdown-value {
      border-color: ${({ theme }) => theme.colorPrimary};
    }
  }

  &&:active {
    .dropdown-image {
      transform: scale(0.97);
    }
  }
`;

const OptionImage = styled.img`
  width: 70px;
  height: 70px;
  padding: 7px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: ${gripsBackground};
  transition: transform 0.2s;
`;

const OptionValue = styled.div`
  border-bottom: 1px solid transparent;
  transition: border 0.4s;
  text-align: center;
`;

const IconDown = styled(AngleDown)`
  position: absolute;
  color: #667085;
  width: 16px;
  right: 20px;
  top: 8px;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
`;

type DropdownProps = {
  options: any;
  label?: string;
  onChange: Function;
  selected: any;
  responsive?: boolean;
  isOpen?: boolean;
  directControl?: boolean;
  setOpenParent?: Function;
  active?: boolean;
  showImg?: boolean;
  disabled?: boolean;
  onOpen?: Function;
};

const DropdownImg = ({
  options,
  label = '',
  onChange,
  selected,
  responsive = false,
  isOpen = false,
  directControl = false,
  setOpenParent = () => false,
  active = true,
  showImg = true,
  disabled = false,
  onOpen,
  ...props
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<any>(null);
  const refBox = useRef<any>(null);
  const { t } = useTranslation();

  const updateOption = (option) => {
    if (disabled) {
      return;
    }
    onChange(option, open);
  };

  useEffect(() => {
    if (isOpen || open) {
      const viewPort = document.querySelector('#layout')!;
      const viewPortWidth = viewPort.getBoundingClientRect().width;
      const viewPortHeight = viewPort.getBoundingClientRect().height;

      const dropdown = ref.current?.getBoundingClientRect();
      const dropdownRight = dropdown.right;
      const dropdownBottom = dropdown.bottom;
      const dropdownTop = dropdown.top;

      if (viewPortWidth - dropdownRight < 70) {
        ref.current.style.right = 0;
        ref.current.style.left = 'auto';
      }

      const dropdownHeight = dropdownBottom - dropdownTop;
      const bottomSpace = viewPortHeight - dropdownTop;

      if (bottomSpace - dropdownHeight < -100 && dropdownTop > dropdownHeight) {
        ref.current.style.bottom = `${38}px`;
        ref.current.style.top = 'auto';
      }
    }
  }, [isOpen, open]);

  const updateOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleOpen = () => {
    if (disabled) {
      return;
    }

    if (onOpen) onOpen();

    if (directControl) {
      setOpenParent();
      return;
    }
    updateOpen();
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Wrapper
        id='dropdown-select-parent'
        open={directControl ? isOpen : open}
        responsive={responsive ? 1 : 0}
        {...props}>
        {showImg && <LabelImage src={selected.img} />}
        <Label>{label}</Label>
        <Tooltip title={!disabled ? null : "You don't have access"}>
          <Box
            responsive={responsive ? 1 : 0}
            onClick={handleOpen}
            ref={refBox}
            data-testid='select-dropdownimg'>
            <Choosen data-testid='select-dropdownimg-value'>{t(selected.value)}</Choosen>
            <IconDown responsive={responsive ? 1 : 0} open={isOpen || open} />
            {(directControl ? isOpen && active : open) && !disabled && (
              <Select id='dropdown-select' ref={ref}>
                {options.map((option, index) => (
                  <Option
                    onClick={() => {
                      updateOption(option.value);
                    }}
                    value='option'
                    key={uniqid()}
                    data-testid={`select-dropdownimg-option${index}`}>
                    <OptionImage className='dropdown-image' src={option.img} alt='Grip gif' />
                    <OptionValue className='dropdown-value'>{t(option.value)}</OptionValue>
                  </Option>
                ))}
              </Select>
            )}
          </Box>
        </Tooltip>
      </Wrapper>
    </ClickAwayListener>
  );
};

export default DropdownImg;
