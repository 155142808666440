import { useState } from 'react';
import { NotificationFactory } from 'lib/NotificationFactory';
import regionAPI from 'adp-panel/api/utils/apiRegionClient';
import { setAPIUrl } from 'adp-panel/api/utils/apiClient';
import i18next from 'i18next';

interface RegionResponse {
  endpoint: string;
  email: string;
  region: 'us' | 'eu';
}

export const useUserRegion = () => {
  const [isLoading, setIsLoading] = useState(false);

  const checkRegion = async (email: string): Promise<RegionResponse | null> => {
    try {
      const response = await regionAPI.get<RegionResponse>(`dev/get-region`, {
        params: { email }
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const getUserRegion = async (email: string): Promise<boolean> => {
    setIsLoading(true);
    try {
      const response = await regionAPI.get(`dev/get-region`, {
        params: { email }
      });

      setAPIUrl(response.data.endpoint);
      localStorage.setItem('endpoint', response.data.endpoint);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      NotificationFactory.errorNotification(
        i18next.t(
          'common:notification.login.user_in_region_error',
          "We're sorry, but the email you entered is not associated with an account. Please check your email and try again."
        )
      );
      return false;
    }
  };

  return {
    getUserRegion,
    checkRegion,
    isLoading
  };
};
