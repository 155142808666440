/* eslint-disable no-unused-vars */
import DummyPatientModal from 'adp-panel/components/Modals/DummyPatientModal';
import { icons } from 'components/CustomIcon/CustomIcon';
import { useState } from 'react';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import {
  CLINICIAN,
  CLINICIANS,
  DEVICE,
  DEVICE_ADD,
  DEVICE_EDIT,
  PATIENTS
} from '../../../constants/routes';
import {
  useDeviceCreateDummyPatient,
  useDeviceDelete,
  useDevicesList
} from '../../hooks/api/useDevices';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal from '../../components/Modals/ConfirmDeleteModal';
import { TableLink } from 'adp-panel/components/Links/Links';
import Card from 'adp-panel/components/Card/Card';
import useUserData from '../../../hooks/useUserData';
import {
  DeviceExtendOptions,
  DevicesQueryParams,
  DevicesSortOptions,
  DummyPatientEntry
} from 'adp-panel/api/devices/device.types';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import { RoleEnum } from 'adp-panel/api/users/users.types';
import { FilterValue } from 'adp-panel/components/Filters/FilterBox.types';
import { debounce, find } from 'lodash';
import { SortDirs } from 'types';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import CustomTable, { ActionsWrapper } from 'adp-panel/components/Table/CustomTable';
import { ReactComponent as UserChangeSvg } from 'assets/user-change.svg';
import { ReactComponent as TrashSvg } from 'assets/trash-icon.svg';
import AssignDeviceModal from 'adp-panel/pages/Devices/Forms/AssignDeviceModal';
import CustomButton from 'components/Button/CustomButton';
import { Chip, CircularProgress, TextField } from '@mui/material';
import { ReactComponent as EditSvg } from 'assets/edit-icon.svg';
import { useDetachDevice } from '../../../hooks/useDetachDevice';
import { DEBOUNCE_SEARCH_TIME } from 'adp-panel/constants/search';
import { ReactComponent as Add } from 'assets/add.svg';
import { shortenString } from 'utils/utils';
import PatientsModal from 'adp-panel/components/Modals/PatientsModal';
import MultipleItemsLinks from 'components/MultipleItemsLinks/MultipleItemsLinks';
import LoaderWrapper from 'components/Loader/Loader';

const fieldFiltersAdmin: FilterValue[] = [
  { field: 'company', value: [], role: RoleEnum.superAdmin },
  { field: 'model', value: [] },
  { field: 'active', value: true }
];

const mapDeviceRows = (apiData: DeviceEntry[]) =>
  apiData.map((device) => ({
    id: device.id,
    serial: device.serial,
    bluetooth_id: device.bluetooth_id,
    model: device.model?.name,
    company: device.company,
    amputee: device.amputee,
    clinician: device.clinician,
    clinicians: device.clinicians,
    amputee_accessible: device.amputee_accessible,
    device
  }));

const DevicesList = () => {
  const { t } = useTranslation('devices');
  const { rolesByName } = useUserData();
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [selectedDevice, setSelectedDevice] = useState<number>();
  const [selectedAssignDeviceData, setSelectedAssignDeviceData] = useState<null | {
    device: any;
    patient: any;
  }>();
  const [dummyPatientData, setDummyPatientData] = useState<DummyPatientEntry | null>(null);
  const navigate = useNavigate();
  const [selectedDeviceClinicians, setSelectedDeviceClinicians] = useState<any>(null);
  const [sortOptions, setSortOptions] = useState<{
    field: null | DevicesSortOptions;
    value: null | SortDirs;
  }>({
    field: null,
    value: null
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20
  });

  const commonQueryParams: DevicesQueryParams = {
    search: searchTerm,
    perpage: 20,
    page: paginationModel.page + 1,
    ...(sortOptions.field &&
      sortOptions.value && { sortby: sortOptions.field, sortdir: sortOptions.value })
  };

  const queryParamsDevices: DevicesQueryParams = {
    extend: [
      DeviceExtendOptions.model,
      DeviceExtendOptions.clinicians,
      DeviceExtendOptions.amputee,
      DeviceExtendOptions.company,
      DeviceExtendOptions.pcbVersion,
      DeviceExtendOptions.firmawreVersion,
      DeviceExtendOptions.activeCode
    ],
    ...commonQueryParams
  };

  const {
    result: devicesData,
    total: totalDevices,
    isLoading: isLoadingDevices,
    refetch: refetchDevicesList
  } = useDevicesList(queryParamsDevices);

  const isAdmin = userHasPermissions([RoleEnum.superAdmin], rolesByName);

  const {
    isOpen: isModalOpen,
    handleOpen: handleCliniciansModalOpen,
    handleClose: handleModalClose
  } = useModal();
  const { handleSubmit: handleSubmitDetachDevice, isLoading: isLoadingDetach } = useDetachDevice();
  const { mutateAsync: createDummyPatient, isLoading: isLoadingCreateDummyPatient } =
    useDeviceCreateDummyPatient();
  const { mutateAsync: handleSubmitDeleteDevice, isLoading: isLoadingDeleteDevice } =
    useDeviceDelete();
  const {
    isOpen: isDetachModalOpen,
    handleOpen: handleDetachModalOpen,
    handleClose: handleDetachModalClose
  } = useModal();
  const {
    isOpen: isDeviceAssignModalOpen,
    handleOpen: handleDeviceAssignModalOpen,
    handleClose: handleDeviceAssignModalClose
  } = useModal();
  const {
    isOpen: isDeleteModalOpen,
    handleOpen: handleDeleteModalOpen,
    handleClose: handleDeleteModalClose
  } = useModal();
  const {
    isOpen: isDummyPatientModalOpen,
    handleOpen: handleDummyPatientModalOpen,
    handleClose: handleDummyPatientModalClose
  } = useModal();

  const handleDetachDevice = async (deviceId: number) => {
    setSelectedDevice(deviceId);
    handleDetachModalOpen();
  };

  const handleAssignDevice = async (device: any, patient: any) => {
    setSelectedAssignDeviceData({ device, patient });
    handleDeviceAssignModalOpen();
  };

  const handleDeleteDevice = async (deviceId: number) => {
    setSelectedDevice(deviceId);
    handleDeleteModalOpen();
  };

  const handleCreateDummyPatient = async (deviceId: number) => {
    handleDummyPatientModalOpen();
    try {
      const dummyUserData = await createDummyPatient({ deviceId });
      setDummyPatientData(dummyUserData);
    } catch (error) {
      console.log('error', error);
    }
  };

  const detachDevice = async () => {
    if (selectedDevice) {
      await handleSubmitDetachDevice(selectedDevice);
      handleDetachModalClose();
    }
  };

  const deleteDevice = async () => {
    if (selectedDevice) {
      await handleSubmitDeleteDevice(selectedDevice);
      handleDeleteModalClose();
    }
  };

  const handleOpenClinicians = (clinicians) => {
    setSelectedDeviceClinicians(clinicians);
    handleCliniciansModalOpen();
  };

  return (
    <DefaultLayout>
      <Card>
        {isModalOpen && (
          <PatientsModal
            handleClose={handleModalClose}
            items={selectedDeviceClinicians}
            title='Clinicians'
            link={CLINICIANS}
          />
        )}
        {isDeviceAssignModalOpen && (
          <AssignDeviceModal
            isModalOpen={isDeviceAssignModalOpen}
            handleModalClose={handleDeviceAssignModalClose}
            device={selectedAssignDeviceData?.device}
            patient={selectedAssignDeviceData?.patient}
          />
        )}
        {isDetachModalOpen && (
          <ConfirmDeleteModal
            handleClose={handleDetachModalClose}
            handleAccept={detachDevice}
            isLoading={isLoadingDetach}
            title={t('devices:devices_list.detach_modal.title', 'Remove device from my view')}
            message={t(
              'devices:devices_list.detach_modal.message',
              "We don't delete the device, you just won't be attached to them anymore."
            )}
          />
        )}
        {isDeleteModalOpen && (
          <ConfirmDeleteModal
            handleClose={handleDeleteModalClose}
            handleAccept={deleteDevice}
            isLoading={isLoadingDeleteDevice}
            message={t('devices:devices_list.table.delete.message', {
              defaultValue: 'Do you want to delete device (ID: {{value}})?',
              value: selectedDevice
            })}
          />
        )}
        {isDummyPatientModalOpen && dummyPatientData && (
          <DummyPatientModal
            handleClose={() => {
              setDummyPatientData(null);
              handleDummyPatientModalClose();
            }}
            dummyPatientData={dummyPatientData}
          />
        )}
        <div>
          {isLoadingDevices ? (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          ) : (
            <CustomTable
              actions={
                isAdmin && (
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <TextField
                      size='small'
                      variant='outlined'
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder={t(
                        'devices:devices_list.search.placeholder',
                        'Search by serial or BT id'
                      )}
                      onChange={debounce(
                        (e) => setSearchTerm(e.target.value),
                        DEBOUNCE_SEARCH_TIME
                      )}
                    />
                    <CustomButton Icon={Add} onClick={() => navigate(DEVICE_ADD)}>
                      {t('devices:devices_list.buttons.add_new_device', 'Add new device')}
                    </CustomButton>
                  </div>
                )
              }
              tableData={devicesData}
              tableHeader={t('devices:devices_list.table.header', 'Devices')}
              totalItems={totalDevices}
              isLoading={isLoadingDevices}
              columnVisibilityModel={{ clinician: isAdmin }}
              columns={[
                {
                  field: 'serial',
                  headerName: t('devices:devices_list.table.columns.serial', 'Serial'),
                  flex: 1,
                  ...(isAdmin && {
                    renderCell: (params) => {
                      const { id, serial, device } = params.row;
                      return (
                        <td>
                          <TableLink to={`${DEVICE}/${id}`} state={{ device }}>
                            {serial}
                          </TableLink>
                        </td>
                      );
                    }
                  })
                },
                {
                  field: 'bluetooth_id',
                  headerName: t('devices:devices_list.table.columns.bluetooth_id', 'Bluetooth ID'),
                  flex: 1,
                  sortable: false,
                  renderCell: (params) => {
                    const { bluetooth_id } = params.row;
                    return <Chip size='small' label={bluetooth_id} />;
                  }
                },
                {
                  field: 'model',
                  headerName: t('devices:devices_list.table.columns.model', 'Model'),
                  flex: 1,
                  sortable: false
                },
                {
                  field: 'amputee',
                  headerName: t('devices:devices_list.table.columns.patient', 'Patient'),
                  flex: 1,
                  renderCell: (params) => {
                    const { amputee, amputee_accessible } = params.row;
                    return (
                      <td>
                        {amputee?.id ? (
                          amputee_accessible || isAdmin ? (
                            <TableLink to={`${PATIENTS}/${amputee?.id}`}>{amputee?.name}</TableLink>
                          ) : (
                            <div>{amputee?.name}</div>
                          )
                        ) : (
                          '-'
                        )}
                      </td>
                    );
                  }
                },
                {
                  field: 'clinician',
                  minWidth: 200,
                  headerName: 'Clinicians',
                  renderCell: (params) => {
                    const { clinicians } = params.row;
                    return (
                      <MultipleItemsLinks
                        maxItems={1}
                        items={clinicians}
                        link={CLINICIANS}
                        handleOpen={handleOpenClinicians}
                      />
                    );
                  }
                },
                {
                  field: 'actions',
                  width: 200,
                  headerName: '',
                  renderCell: (params) => {
                    const { id, amputee, device, amputee_accessible } = params.row;
                    const isClinician = userHasPermissions(
                      [RoleEnum.clinician, RoleEnum.clinicAdmin],
                      rolesByName
                    );
                    const isAlliedHealth = userHasPermissions(
                      [RoleEnum.clinicianSupport],
                      rolesByName
                    );

                    const disableUnassign = amputee_accessible && amputee;

                    const UnassignButton = (
                      <Tooltip
                        title={
                          disableUnassign
                            ? t(
                                'devices:devices_list.table.actions.detach.patient_assigned_tooltip',
                                'To remove device that is assigned to patient, you need to remove the patient first.'
                              )
                            : t('devices:devices_list.table.actions.remove_device', 'Remove device')
                        }>
                        <span>
                          <CustomButton
                            Icon={TrashSvg}
                            color='light'
                            data-testid='button-delete'
                            onClick={() => handleDetachDevice(id)}
                            disabled={disableUnassign}
                          />
                        </span>
                      </Tooltip>
                    );

                    const actionButtons = () => {
                      if (isAdmin)
                        return [
                          <CustomButton
                            Icon={EditSvg}
                            onClick={() => navigate(DEVICE_EDIT, { state: { detail: id } })}
                            data-testid='button-edit'
                            color='light'
                          />,
                          <>
                            <Tooltip
                              title={
                                !amputee
                                  ? t(
                                      'devices:devices_list.table.actions.dummy_patient.create',
                                      'Create dummy patient'
                                    )
                                  : t(
                                      'devices:devices_list.table.actions.dummy_patient.create_disabled',
                                      'Device is already assigned to a patient'
                                    )
                              }>
                              <span>
                                <CustomButton
                                  Icon={icons['dummy-user-create-icon']}
                                  disabled={amputee}
                                  color='light'
                                  data-testid='dummy-patient'
                                  onClick={() => handleCreateDummyPatient(id)}
                                />
                              </span>
                            </Tooltip>
                          </>,
                          <CustomButton
                            Icon={TrashSvg}
                            color='light'
                            data-testid='button-delete'
                            onClick={() => handleDeleteDevice(id)}
                          />
                        ];

                      if (isClinician) {
                        return [
                          <CustomButton
                            Icon={UserChangeSvg}
                            color='light'
                            data-testid='button-assign'
                            tooltip={t(
                              'devices:devices_list.table.actions.assign_device',
                              'Assign device'
                            )}
                            onClick={() => handleAssignDevice(device, amputee)}
                          />,
                          UnassignButton
                        ];
                      }

                      if (isAlliedHealth) {
                        return [UnassignButton];
                      }
                    };

                    return <ActionsWrapper>{actionButtons()}</ActionsWrapper>;
                  }
                }
              ]}
              mapTableData={mapDeviceRows}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
            />
          )}
        </div>
      </Card>
    </DefaultLayout>
  );
};

export default DevicesList;
