import AuthLayout from 'adp-panel/layouts/AuthLayout';
import { FORGOTTEN_PASSWORD, REGISTER_USER } from 'constants/routes';
import { useAuthentication } from 'hooks/useAuthentication';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthParagraph, AuthTitle, AuthWrapperBox, CreateAccountBox } from '../styled';
import MfaScreen from './MfaScreen';
import { BottomLoginSection, ForgottenPasswordLink, FormWrapper } from './styled';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import { LoadingButton } from '@mui/lab';
import CustomButton from 'components/Button/CustomButton';
import { useForm } from 'react-hook-form';
import CustomTextField from 'components/FormFields/CustomTextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomPasswordField from 'components/FormFields/CustomPasswordField';
import BackgroundImage from '../BackgroundImage';

const Login = () => {
  const { t } = useTranslation('auth');
  const { isLoading, login, is2FaPage, mfaData } = useAuthentication();
  const { state: locationState = {} } = useLocation();
  const { mfa = false }: any = locationState || {};

  const onSubmit = (data: any) => {
    const trimmedData = {
      email: data.email.trim(),
      password: data.password
    };

    login(trimmedData);
  };
  const navigate = useNavigate();

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(t('auth:component.login.email.text', 'Invalid email'))
      .required(t('auth:component.login.patient.text', 'Email is required')),
    password: yup.string().required(t('auth:component.login.password.text', 'Password is required'))
  });

  const { handleSubmit: handleSubmitLogin, control } = useForm({
    resolver: yupResolver(loginSchema)
  });

  const handleRegister = () => {
    navigate(REGISTER_USER);
  };

  const isMfaPage = is2FaPage || mfa || mfaData.required;

  return (
    <>
      {!isMfaPage && (
        <div style={{ display: 'grid', gridTemplateColumns: '600px 1fr', height: '100vh' }}>
          <AuthLayout lightVariant noFooter>
            <AuthWrapperBox>
              <AuthTitle>{t('auth:component.login.title', 'Sign In')}</AuthTitle>
              <AuthParagraph variant='body2'>
                {t(
                  'auth:component.login.paragraph',
                  'Already registered with us? Sign in with your email to connect to the configurator or explore the Aether Digital Platform (ADP) for additional functionalities.'
                )}
              </AuthParagraph>
              <form onSubmit={handleSubmitLogin(onSubmit)}>
                <FormWrapper>
                  <CustomTextField
                    label={t('auth:component.login.form.email', 'E-mail')}
                    id='email'
                    control={control}
                  />
                  <CustomPasswordField
                    label={t('auth:component.login.form.password', 'Password')}
                    id='password'
                    control={control}
                  />
                  <BottomLoginSection>
                    <ForgottenPasswordLink onClick={() => navigate(FORGOTTEN_PASSWORD)}>
                      {t('auth:component.login.link.forgotten_password', 'Forgotten password')}
                    </ForgottenPasswordLink>
                  </BottomLoginSection>
                  <FormButtonsWrapper>
                    <LoadingButton type='submit' loading={isLoading}>
                      {t('auth:component.login.button.login', 'Log in')}
                    </LoadingButton>
                  </FormButtonsWrapper>
                </FormWrapper>
              </form>
              <CreateAccountBox>
                <p style={{ textAlign: 'center', marginTop: '60px', color: '#344054' }}>
                  {t('auth:component.login.form.dont_have_account', 'Don`t have an account?')}
                </p>
                <FormButtonsWrapper>
                  <CustomButton color='light' type='button' onClick={handleRegister}>
                    {t('auth:component.login.form.careate_account', 'Create account')}
                  </CustomButton>
                </FormButtonsWrapper>
              </CreateAccountBox>
            </AuthWrapperBox>
          </AuthLayout>
          <BackgroundImage />
        </div>
      )}
      {isMfaPage && <MfaScreen />}
    </>
  );
};

export default Login;
