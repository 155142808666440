import { useEffect, useState } from 'react';
import VerificationInput from 'react-verification-input';
import './style.css';

const CustomVerificationCode = ({
  onChange,
  value,
  fullWidth = false,
  error
}: {
  onChange: (e: string) => void;
  value: undefined | string;
  fullWidth?: boolean;
  error?: boolean;
}) => {
  return (
    <VerificationInput
      value={value}
      length={6}
      validChars='A-Z0-9a-z'
      onChange={onChange}
      placeholder='-'
      classNames={{
        character: `character${error ? ' error' : ''}`,
        characterInactive: 'character-inactive',
        container: fullWidth ? 'container full-width' : 'container'
      }}
    />
  );
};

export const CustomVerificationNumberCode = ({
  onChange,
  value,
  error = false,
  fullWidth = false
}: {
  onChange: (e: string) => void;
  value: undefined | string;
  error?: boolean;
  fullWidth?: boolean;
}) => {
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setChanged(false);
  }, [error]);
  return (
    <VerificationInput
      value={value}
      length={6}
      validChars='0-9'
      onChange={onChange}
      inputProps={{ inputMode: 'numeric' }}
      placeholder='-'
      classNames={{
        character: error && !changed ? 'character-error' : 'character',
        characterInactive: 'character-inactive',
        container: fullWidth ? 'container full-width' : 'container'
      }}
    />
  );
};

export default CustomVerificationCode;
