import { useBackupConfigActions } from 'configurator/hooks/useBackupConfigActions';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { TextS } from 'configurator/components/atoms/Typography/Typography';
import useUnsaved from 'configurator/hooks/useUnsaved';
import ModalBase from './ModalBase';
import { pauseSoundElement, playSoundElement } from 'App';
import { useUiStore } from 'configurator/reducers/uiStore';
import { FETCHING_STATES } from 'configurator/consts/consts';

const ParagraphBold = styled.p`
  ${TextS};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
`;

type ModalProps = {
  handleClose: Function;
};

const LocalSessionConfigBackupRestoreModal = ({ handleClose }: ModalProps) => {
  const { isUnsaved } = useUnsaved();
  const { t } = useTranslation('configurator');
  const { deviceId, amputeeId } = useDeviceInfoStore((state: any) => ({
    deviceId: state.deviceId,
    amputeeId: state.amputeeId
  }));
  const { initialConfigState } = useUiStore((state) => ({
    initialConfigState: state.initialConfigState
  }));

  const { handleAcceptBackup, handleDenyBackup } = useBackupConfigActions(deviceId);

  useEffect(() => {
    playSoundElement();
  }, []);

  const accepted = () => {
    pauseSoundElement();
    handleAcceptBackup();
    handleClose();
  };

  const denied = () => {
    pauseSoundElement();
    handleDenyBackup();
    handleClose();
  };

  const canApplySettings = !isUnsaved && initialConfigState === FETCHING_STATES.successful;

  return (
    <ModalBase
      handleClick={() => {
        pauseSoundElement();
        handleClose();
      }}
      header={t(
        'configurator:component.modal.local_session_config_backup_restore_modal.header',
        'Backup config found'
      )}
      allowBackDropClose={false}>
      <DialogContent>
        <ParagraphBold>
          {t(
            'configurator:component.modal.local_session_config_backup_restore_modal.restore_message',
            'We have created a restore point for your last disconnected session.'
          )}
        </ParagraphBold>
        <ButtonsWrapper>
          {canApplySettings && (
            <Button sx={{ width: 200 }} onClick={accepted}>
              {t(
                'configurator:component.modal.local_session_config_backup_restore_modal.button_accept',
                'Yes, restore'
              )}
            </Button>
          )}
          <Button
            sx={{ width: 200 }}
            onClick={denied}
            variant={amputeeId ? 'outlined' : 'contained'}>
            {t(
              'configurator:component.modal.local_session_config_backup_restore_modal.button_cancel',
              "No, don't restore"
            )}
          </Button>
        </ButtonsWrapper>
      </DialogContent>
    </ModalBase>
  );
};

export default LocalSessionConfigBackupRestoreModal;
