/* eslint-disable react/jsx-key */
/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { initialState } from 'configurator/reducers/helpers/initialState';
import { useTranslation } from 'react-i18next';
import { MultiSlider } from '../../../components/molecules/Sliders/Sliders';

const FreezeModeEMGThresholds = ({
  addHistoryProsthesis,
  freezeModeEmg,
  replayIsEnabled,
  setConfigProperty,
  disabled
}: any) => {
  const { t } = useTranslation();

  const [freezeModeEmgLocal, setFreezeModeEmgLocal] = useState<any>([
    // @ts-ignore
    initialState.config.freezeModeEmg[1],
    // @ts-ignore
    initialState.config.freezeModeEmg[0]
  ]);

  const handleFreezeEmgSlider = (value: any, sliderNumber: any) => {
    const isOpening = sliderNumber === 0;
    addHistoryProsthesis(
      // @ts-ignore
      setConfigProperty('freezeModeEmg', [
        freezeModeEmg[2],
        !isOpening ? value : freezeModeEmg[0],
        isOpening ? value : freezeModeEmg[1]
      ])
    );
  };

  useEffect(() => {
    if (freezeModeEmg) {
      setFreezeModeEmgLocal([freezeModeEmg?.[1], freezeModeEmg?.[0]]);
    }
  }, [freezeModeEmg]);

  const FreezeModeEMGThresholds = useMemo(
    () => (
      <MultiSlider
        tooltip={t('configurator:component.freeze_mode_emg_thresholds.tooltip', 'Hello')}
        header={t(
          'configurator:component.freeze_mode_emg_thresholds.header',
          'EMG freeze mode relaxation'
        )}
        sliderValues={[freezeModeEmgLocal?.[0], freezeModeEmgLocal?.[1]]}
        disabled={disabled}
        limits={[
          { min: 1, max: 100 },
          { min: 1, max: 100 }
        ]}
        handleChange={(...args: any) =>
          setFreezeModeEmgLocal((prev: any) => {
            const localCopy = [...prev];
            localCopy[args[3]] = args[1];
            return localCopy;
          })
        }
        labelSliders={[
          <p>
            {t(
              'configurator:component.freeze_mode_emg_thresholds.relaxing_opening',
              'Relaxation opening: '
            )}
            {freezeModeEmgLocal?.[0]}
          </p>,
          <p>
            {t(
              'configurator:component.freeze_mode_emg_thresholds.relaxing_closing',
              'Relaxation closing: '
            )}
            {freezeModeEmgLocal?.[1]}
          </p>
        ]}
        handleOnAfterChange={(...args: any) => handleFreezeEmgSlider(args[1], args[2])}
        description={t(
          'configurator:component.freeze_mode_emg_thresholds.description',
          'Sets thresholds of minimum opening/closing signals of freeze mode switch'
        )}
      />
    ),
    [freezeModeEmg, freezeModeEmgLocal, disabled]
  );

  return FreezeModeEMGThresholds;
};

export default FreezeModeEMGThresholds;
