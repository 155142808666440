import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getP2PSession,
  initializeP2PSession,
  updateP2PSession
} from 'adp-panel/api/liveConfigurator/p2pSessions';
import { mapErrorMessage } from 'utils/notifications';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useTranslation } from 'react-i18next';

export const SESSION_QUERY_KEY = 'session_query';

export const useP2PSession = (clinicianId: number, amputeeId: number, enabled: boolean = false) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [SESSION_QUERY_KEY, clinicianId, amputeeId],
    () => getP2PSession({ clinicianId, amputeeId }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useP2PSessionActions = () => {
  const { t } = useTranslation();
  const {
    data,
    mutateAsync: initSessions,
    isLoading: createLoading
  } = useMutation(initializeP2PSession, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:session_created_waiting', 'Session created. Waiting for patient response')
      );
    },
    onError(error) {
      mapErrorMessage(error);
    }
  });

  const { mutateAsync: updateSession, isLoading: closeLoading } = useMutation(updateP2PSession, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:session.update.notification.closed', 'Session was closed')
      );
    }
  });

  return {
    initSessions,
    updateSession,
    data,
    createSessionIsLoading: createLoading,
    closeSessionIsLoading: closeLoading
  };
};
