import { codeIconMap, codeNameMap, CountryCode } from 'adp-panel/constants/countries';
import { useAvailableLanguages } from 'adp-panel/hooks/api/useAvailableLanguages';
import i18n from 'i18n';
import React, { ReactElement } from 'react';
import {
  Autocomplete,
  InputLabel,
  TextField,
  Typography,
  CircularProgress,
  Avatar,
  Box,
  InputAdornment,
  IconButton
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { commonFormStyles, LabelStyle, LabelWrapper } from './commonStyles';

const StyledFlag = styled.div`
  line-height: 0;
  display: inline-block;
  margin-right: 8px;
  border-radius: 50%;
  transition: linear 0.25s;
  height: 20px;
  width: 20px;

  img,
  svg {
    width: 20px;
    height: auto;
    border-radius: 50%;
  }
`;

export interface LanguageType {
  code: string;
  label: string;
  icon: ReactElement | undefined;
}

export const defaultLanguage: LanguageType = {
  label: codeNameMap.get('EN') || 'common:countries.english',
  code: 'en',
  icon: codeIconMap.get('EN')
};
const CustomLanguageSelect = ({
  id,
  label,
  control,
  optional,
  showLabel,
  loading,
  filterOptions
}: {
  id: string;
  label: string;
  control: any;
  optional: boolean;
  showLabel: boolean;
  loading: boolean;
  filterOptions?: Array<{ code: string }>;
}) => {
  const { t } = useTranslation();
  const { result: languages, isLoading } = useAvailableLanguages();
  const filteredLanguages =
    filterOptions && filterOptions.length > 0
      ? languages.filter((language) =>
          filterOptions.some((filter) => filter.code === language.code)
        )
      : languages;

  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => (
        <div>
          {showLabel && (
            <div style={{ display: 'flex', gap: '8px' }}>
              <InputLabel
                shrink={false}
                htmlFor={id}
                sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
                <LabelWrapper>
                  <Typography sx={{ ...LabelStyle }}>{label}</Typography>
                  {optional && <Typography>(optional)</Typography>}
                </LabelWrapper>
              </InputLabel>
              {loading && <CircularProgress size={20} />}
            </div>
          )}
          <Autocomplete
            {...field}
            id={id}
            options={filteredLanguages}
            autoHighlight
            loading={isLoading}
            clearIcon={false}
            onChange={(e, data) => {
              field.onChange(data);
            }}
            getOptionLabel={(option) => t(option.label)}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box key={key} component='li' {...optionProps}>
                  <StyledFlag>{option.icon}</StyledFlag>
                  {t(option.label)}
                </Box>
              );
            }}
            renderInput={(params) => (
              <div style={{ position: 'relative' }}>
                <TextField
                  {...params}
                  size='small'
                  variant='outlined'
                  fullWidth
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  id={id}
                  name={id}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      paddingLeft: '18px !important'
                    }
                  }}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      startAdornment: (() => {
                        const language = languages.find(
                          (lang) => t(lang.label) === params.inputProps.value
                        );
                        return language ? (
                          <InputAdornment position='start'>
                            <StyledFlag style={{ marginRight: 0 }}>{language.icon}</StyledFlag>
                          </InputAdornment>
                        ) : null;
                      })()
                    }
                  }}
                />
              </div>
            )}
          />
        </div>
      )}
    />
  );
};

export default CustomLanguageSelect;
