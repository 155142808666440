import { useConfigStore } from 'configurator/reducers/configStore';
import { useCallback } from 'react';

interface BackupConfig {
  data: {
    common: any;
    modes: any;
  };
}

export const useBackupConfigActions = (deviceId: string | number) => {
  const { importBackupConfig } = useConfigStore((state) => ({
    importBackupConfig: state.importBackupConfig
  }));

  const getBackupConfig = useCallback((): BackupConfig | null => {
    const backup = localStorage.getItem(`localSessionBackupConfig-${deviceId}`);
    return backup ? JSON.parse(backup) : null;
  }, [deviceId]);

  const removeBackupConfig = useCallback(() => {
    localStorage.removeItem(`localSessionBackupConfig-${deviceId}`);
  }, [deviceId]);

  const handleAcceptBackup = useCallback(
    (onClose?: () => void) => {
      const backupConfig = getBackupConfig();

      if (backupConfig) {
        importBackupConfig({ config: backupConfig.data });
        removeBackupConfig();
      }

      onClose?.();
    },
    [deviceId, getBackupConfig, importBackupConfig, removeBackupConfig]
  );

  const handleDenyBackup = useCallback(
    (onClose?: () => void) => {
      removeBackupConfig();
      onClose?.();
    },
    [removeBackupConfig]
  );

  return {
    getBackupConfig,
    removeBackupConfig,
    handleAcceptBackup,
    handleDenyBackup
  };
};
