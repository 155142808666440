import { useEffect, useRef, useState } from 'react';

const ping = ({ url, timeout }) => {
  return new Promise((resolve) => {
    const isOnline = () => resolve(true);
    const isOffline = () => resolve(false);

    const xhr = new XMLHttpRequest();

    xhr.onerror = isOffline;
    xhr.ontimeout = isOffline;
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 1) {
        if (xhr.status) {
          isOnline();
        } else {
          isOffline();
        }
      }
    };

    xhr.open('GET', url);
    xhr.timeout = timeout;
    xhr.send();
  });
};

const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const interval = useRef(null);

  useEffect(() => {
    if (!interval.current) {
      // @ts-ignore
      interval.current = setInterval(() => {
        ping({ url: 'https://httpbin.org/get', timeout: 10000 }).then((online) => {
          setIsOnline(Boolean(online));
        });
      }, 10000);
    } else {
      clearInterval(interval.current);
      interval.current = null;
    }

    return () => {
      // @ts-ignore
      clearInterval(interval.current);
    };
  }, []);

  return isOnline;
};

export default useIsOnline;
