import React from 'react';
import { StyledCheck } from 'configurator/components/atoms/Icons/Icons';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { useTranslation } from 'react-i18next';

interface ToggleHistoryViewProps {
  status?: boolean;
  handler?: any;
}

export const ToggleHistoryView = ({
  status = false,
  handler = () => false
}: ToggleHistoryViewProps) => (
  <ToggleButtonGroup value={Number(status)} sx={{ height: '40px' }}>
    <ToggleButton value={0} key='table' onClick={handler} sx={{ width: '44px' }}>
      <ListOutlinedIcon sx={{ width: '22px' }} />
    </ToggleButton>
    <ToggleButton value={1} key='calendar' onClick={handler} sx={{ width: '44px' }}>
      <CalendarTodayOutlinedIcon sx={{ width: '18px' }} />
    </ToggleButton>
  </ToggleButtonGroup>
);

export const CheckComponent = () => {
  const { t } = useTranslation();

  return (
    <span>
      <StyledCheck style={{ marginRight: '9px' }} />
      {t('configurator:restore_point_indicator', 'Yes')}
    </span>
  );
};
