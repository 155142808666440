import React, { useEffect, useState } from 'react';
import Divider from 'configurator/components/atoms/Divider/Divider';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { Alert, Button, CircularProgress, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ProcedureIndicator, {
  PROCEDURE_INDICATOR_VARIANTS
} from 'configurator/components/atoms/ProcedureIndicator/ProcedureIndicator';
import {
  Header1,
  IndicatorIconWrapper,
  InfoWrapper,
  Loader,
  Paragraph,
  Regular,
  StyledCheck,
  StyledTimes,
  UpdateButtonsWrapper
} from './styled';
import { UPDATE_STATES } from './utils';
import CircularProgressWithLabel from 'configurator/components/atoms/CircularProgressWithLabel/CircularProgressWithLabel';
import CustomButton from 'components/Button/CustomButton';

export const MessagesDisplay = ({ message, updateStatus, updateStatesFailed }) => (
  <>
    <InfoWrapper>
      {updateStatus === UPDATE_STATES.successful && <StyledCheck />}
      {updateStatus === UPDATE_STATES.inProgress && <Loader />}
      {updateStatesFailed && <StyledTimes />}
      <Paragraph>{message}</Paragraph>
    </InfoWrapper>
  </>
);

export const NewestVersionIndicator = ({ allowUpdate, availableFirmwares, isLoadingFirmwares }) => (
  <>
    <Header1>
      {allowUpdate ? (
        <>
          Newest version:{' '}
          {isLoadingFirmwares ? (
            <CircularProgress />
          ) : (
            <Regular>{availableFirmwares?.[0]?.name}</Regular>
          )}
        </>
      ) : (
        <div style={{ color: 'green' }}>Your firmware is up to date</div>
      )}
    </Header1>
    <Divider margin='20px' />
  </>
);

const ManualUpdateComponent = ({ loadFile }) => (
  <>
    <input
      type='file'
      id='firmwareInput'
      accept='.bin'
      onChange={loadFile}
      style={{ display: 'none' }}
    />
    <Button
      onClick={() => {
        const selectFirmwareButton: HTMLElement | null = document.querySelector('#firmwareInput');

        if (!selectFirmwareButton) return;

        selectFirmwareButton.click();
      }}>
      Update from file
    </Button>
  </>
);

export const StartUpdateComponent = ({
  updateAutomatic,
  firmwareUpdateState,
  allowUpdate,
  pcbVersion,
  inBootloaderMode,
  loadFile,
  allowManualUpdate
}) => (
  <>
    {allowUpdate &&
      (pcbVersion ? (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
          <LoadingButton
            onClick={updateAutomatic}
            loading={firmwareUpdateState === FETCHING_STATES.loading}>
            <span>Update</span>
          </LoadingButton>
          {(allowManualUpdate || inBootloaderMode) && <ManualUpdateComponent loadFile={loadFile} />}
        </div>
      ) : (
        <Alert severity='error'>
          PCB version can&rsquo;t be determined. Firmware update can&rsquo;t be performed. Please
          contact Aether support.
        </Alert>
      ))}
  </>
);

export const VersionNotSupported = () => (
  <>
    <Divider margin='10px' />
    <Alert severity='warning'>Version 1.8.0 is needed to use ADP, please update firmware.</Alert>
  </>
);

export const UpdateInProgress = ({
  part,
  updateRunningNoErrors,
  updateStatus,
  handleRestartUpdate,
  isMacOs,
  deviceConnected,
  handleClose,
  isRemote,
  handleCloseRemote
}: {
  part: number;
  updateRunningNoErrors: boolean;
  updateStatus: UPDATE_STATES;
  handleRestartUpdate: () => void;
  isMacOs: boolean;
  deviceConnected: boolean;
  handleClose: () => void;
  isRemote: boolean;
  handleCloseRemote: () => void;
}) => {
  const [infoMessage, setInfoMessage] = useState<string | null>(null);

  useEffect(() => {
    switch (updateStatus) {
      case UPDATE_STATES.notInitialized:
        setInfoMessage(null);
        break;
      case UPDATE_STATES.successful:
        setInfoMessage(
          isRemote
            ? 'Update successful'
            : `Update successful, please ${isMacOs ? 'reconnect' : 'wait for reconnection'}`
        );
        break;
      case UPDATE_STATES.fixSuccessful:
        setInfoMessage('Bootloader updated successfully, wait for the firmware update to finish');
        break;
      default:
        break;
    }
  }, [updateStatus]);

  const decideVariantUpdate = (status: UPDATE_STATES) => {
    if (
      status === UPDATE_STATES.fixFailed ||
      status === UPDATE_STATES.failed ||
      updateStatus === UPDATE_STATES.reconnectionUnsuccessful
    ) {
      return PROCEDURE_INDICATOR_VARIANTS.failed;
    }
    if (status === UPDATE_STATES.successful) {
      return PROCEDURE_INDICATOR_VARIANTS.done;
    }
  };

  const updateStatusIndicator =
    updateStatus === UPDATE_STATES.fixFailed ||
    updateStatus === UPDATE_STATES.failed ||
    updateStatus === UPDATE_STATES.successful ||
    updateStatus === UPDATE_STATES.reconnectionUnsuccessful;

  const updateFailed =
    updateStatus === UPDATE_STATES.fixFailed || updateStatus === UPDATE_STATES.failed;

  return (
    <div style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
      <div style={{ margin: '0 auto' }}>
        <Typography>Firmware update</Typography>
        <IndicatorIconWrapper>
          {updateRunningNoErrors && <CircularProgressWithLabel value={part} />}
          {updateStatusIndicator && (
            <ProcedureIndicator variant={decideVariantUpdate(updateStatus)} />
          )}
        </IndicatorIconWrapper>
      </div>
      {infoMessage && updateStatus !== UPDATE_STATES.reconnectionUnsuccessful && (
        <div>
          <Alert severity='info'>{infoMessage}</Alert>
        </div>
      )}
      {isRemote && updateStatus === UPDATE_STATES.successful && (
        <UpdateButtonsWrapper>
          <CustomButton onClick={handleCloseRemote} color='light'>
            Close
          </CustomButton>
        </UpdateButtonsWrapper>
      )}
      {updateStatus === UPDATE_STATES.failedToFetch && (
        <Alert severity='error'>Failed to download files</Alert>
      )}
      {updateStatus === UPDATE_STATES.reconnectionUnsuccessful && (
        <>
          <Alert severity='error'>
            Failed to reconnect to the device. Check if the hand is turned on.
          </Alert>
          <UpdateButtonsWrapper>
            <CustomButton onClick={handleClose}>Cancel</CustomButton>
          </UpdateButtonsWrapper>
        </>
      )}
      {updateFailed && (
        <>
          <Alert severity='error'>
            {!deviceConnected &&
              !isRemote &&
              `There was a problem with the firmware update, please ${
                isMacOs ? 'reconnect' : 'wait for reconnection'
              }`}
            {(deviceConnected || isRemote) && `There was a problem with the firmware update.`}
          </Alert>
          <UpdateButtonsWrapper>
            {deviceConnected && (
              <div style={{ display: 'flex', gap: '8px' }}>
                <CustomButton onClick={handleClose} color='light'>
                  Cancel
                </CustomButton>
                <CustomButton onClick={handleRestartUpdate}>Try again</CustomButton>
              </div>
            )}
            {isRemote && (
              <CustomButton onClick={handleCloseRemote} color='light'>
                Close
              </CustomButton>
            )}
          </UpdateButtonsWrapper>
        </>
      )}
    </div>
  );
};
