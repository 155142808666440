import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { getTicket, getTicketConfig } from '../../api/tickets/tickets';
import { SupportTicketQueryParams } from '../../api/tickets/tickets.types';
import { mapErrorMessage } from 'utils/notifications';

export const TICKET_QUERY_KEY = 'ticket';
export const TICKET_CONFIG_QUERY_KEY = 'ticket_config';

export const useTicket = ({
  ticketId,
  enabled = false,
  params = {}
}: {
  ticketId: number;
  enabled?: boolean;
  params?: SupportTicketQueryParams;
}) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [TICKET_QUERY_KEY, params],
    () => getTicket({ ticketId, params }),
    {
      enabled,
      onError: (error) => {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data || null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useTicketConfig = ({
  configUrl,
  enabled = false
}: {
  configUrl: string;
  enabled: boolean;
}) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [TICKET_CONFIG_QUERY_KEY, configUrl],
    () => getTicketConfig(configUrl),
    {
      enabled,
      onSuccess(data) {
        console.log('success', data);
      },
      onError: (error) => {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data || null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};
