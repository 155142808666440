import { ReactElement } from 'react';
import { Autocomplete, InputLabel, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { commonFormStyles, LabelStyle, LabelWrapper } from './commonStyles';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

const StyledFlag = styled.div`
  line-height: 0;
  display: inline-block;
  margin-right: 8px;
  border-radius: 50%;
  transition: linear 0.25s;
  height: 20px;
  width: 20px;

  img,
  svg {
    width: 20px;
    height: auto;
    border-radius: 50%;
  }
`;

export interface LanguageType {
  code: string;
  label: string;
  icon: ReactElement | undefined;
}

export const getRegions = () => [
  {
    label: i18n.t('common:united_state', 'United States'),
    region: 'us'
  },
  {
    label: i18n.t('common:europe', 'Europe'),
    region: 'eu'
  }
];

const CustomRegionSelect = ({
  id,
  label,
  control,
  invitedUser
}: {
  id: string;
  label: string;
  control: any;
  invitedUser?: boolean;
}) => {
  const { t } = useTranslation();

  const regions = getRegions();

  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => (
        <div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <InputLabel
              shrink={false}
              htmlFor={id}
              sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
              <LabelWrapper>
                <Typography sx={{ ...LabelStyle }}>{label}</Typography>
              </LabelWrapper>
            </InputLabel>
          </div>
          <Autocomplete
            {...field}
            id={id}
            options={regions}
            autoHighlight
            disabled={invitedUser}
            onChange={(e, data) => {
              field.onChange(data);
            }}
            renderInput={(params) => (
              <div style={{ position: 'relative' }}>
                <TextField
                  {...params}
                  size='small'
                  variant='outlined'
                  placeholder={t('common:choose.list', 'Choose from the list')}
                  fullWidth
                  error={Boolean(fieldState.error)}
                  helperText={
                    invitedUser
                      ? t(
                          'auth:register.form.region.helpertext',
                          'You can not change the region of your data because you were invited by a person from a specific region.'
                        )
                      : fieldState.error?.message
                        ? fieldState.error?.message
                        : t(
                            'auth:register.form.region.helpertext_info',
                            'Choose the region where your data will be.'
                          )
                  }
                  id={id}
                  name={id}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      paddingLeft: '18px !important'
                    }
                  }}
                />
              </div>
            )}
          />
        </div>
      )}
    />
  );
};

export default CustomRegionSelect;
