import useCheckUserAuthorization from 'adp-panel/components/AutoLogout/useCheckUserAuthorization';
import { useModal } from 'adp-panel/hooks/api/useModal';
import wakeup_script from 'adp-panel/workers/wakeup-script';
import axios from 'axios';
import useModes from 'configurator/hooks/useModes';
import useUnsaved from 'configurator/hooks/useUnsaved';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { useMeetingStore } from 'configurator/reducers/meetingStore';
import { LOGIN } from 'constants/routes';
import useLogout from 'hooks/useLogout';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserEntry } from '../../api/users/users.types';
import {
  AUTOLOGOUT_TIME_IN_SECONDS,
  AUTOLOGOUT_WARNING_TIME_IN_SECONDS
} from '../../constants/config';
import AutoLogoutModal from '../Modals/AutoLogoutModal';
import { useAutoLogoutWarningSound } from './useAutoLogoutWarningSound';
import { useTimerWorker } from './useTimerWorker';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';

const wakeupWorker = new Worker(wakeup_script);
let timer: ReturnType<typeof setTimeout> | null = null;
let warningInactiveInterval: ReturnType<typeof setInterval> | null = null;
let saveModePromise: Promise<void> | null = null;

const autoLogoutMaxTime: number = AUTOLOGOUT_TIME_IN_SECONDS; // number of seconds to perform auto logout
const autoLogoutWarningTime: number = AUTOLOGOUT_WARNING_TIME_IN_SECONDS; // number of seconds to display modal

const UnMemoizedAutoLogout = ({
  children,
  user,
  forceRunTimer = false
}: {
  children: React.ReactNode;
  user: UserEntry;
  forceRunTimer?: boolean;
}) => {
  const [startListen, setStartListen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { offlineLogout, logout } = useLogout();
  const { startTimer, resetTimer, stopTimer, autoLogoutRemainingTime, timerWorker } =
    useTimerWorker();
  const { handleSaveModeBeforeAutoLogout } = useModes();
  const { isUnsaved } = useUnsaved();
  const isUnsavedRef = useRef(isUnsaved);
  const { enabled: remoteSessionEnabled } = useLiveConfiguratorStore();
  const { meetingStatus: meetingEnabled } = useMeetingStore();
  const playWarningSound = useAutoLogoutWarningSound();
  const broadcast = new BroadcastChannel('activity');

  const navigate = useNavigate();
  const { isError: isFetchUserError, refetch, error } = useCheckUserAuthorization();
  const {
    isOpen: autoLogoutWarningOpen,
    handleOpen: handleAutoLogoutWarningOpen,
    handleClose: handleAutoLogoutWarningClose
  } = useModal();

  const { deviceId } = useDeviceInfoStore((state) => ({
    deviceId: state.deviceId
  }));
  const { config } = useConfigStore((state) => ({
    config: state.config
  }));

  const warningInactive = () => {
    if (timer) clearTimeout(timer);

    warningInactiveInterval = setInterval(() => {
      refetch();
    }, 60000);
  };
  const timeChecker = () => {
    timer = setTimeout(() => {
      warningInactive();
    }, 60000);
  };

  const openAutoLogoutWarning = () => {
    handleAutoLogoutWarningOpen();
    playWarningSound();
  };

  const resetAutoLogout = () => {
    broadcast.postMessage({ type: 'CANCEL_LOGOUT' });
    cancelAutoLogout();
  };

  const softLogout = debounce(() => {
    if (saveModePromise) return;
    if (isUnsavedRef.current) {
      setIsLoading(true);

      if (!remoteSessionEnabled && config) {
        const backupConfig = {
          data: {
            common: config.common.config,
            modes: config.modes
          }
        };

        saveModePromise = new Promise<void>((resolve) => {
          try {
            localStorage.setItem(
              `localSessionBackupConfig-${deviceId}`,
              JSON.stringify(backupConfig)
            );
            resolve();
          } catch (error) {
            console.error('Failed to save backup config:', error);
            resolve(); // Still resolve to proceed with logout
          }
        }).then(() => {
          saveModePromise = null;
          logout();
        });
        return;
      }

      // Create ticket for remote session
      saveModePromise = handleSaveModeBeforeAutoLogout({ note: 'Automatic session logout' });
      saveModePromise.then(() => {
        saveModePromise = null;
        logout();
      });
      return;
    }
    setIsLoading(true);
    logout();
  }, 500);

  const checkLastActivityData = () => {
    const lastActivity = sessionStorage.getItem('lastActivity');
    if (lastActivity) {
      const lastActivityDate = new Date(lastActivity);
      const currentDate = new Date();
      const diff = Math.floor((currentDate.getTime() - lastActivityDate.getTime()) / 1000);

      if (diff > autoLogoutMaxTime) {
        logout();
      }
    }
  };

  const emitActivityEventWithErrorCallback = () => {
    const lastActivityDateTime = new Date().toISOString();
    sessionStorage.setItem('lastActivity', lastActivityDateTime);
    broadcast.postMessage({ type: 'LAST_ACTIVITY', lastActivityDateTime });
    resetTimer();
  };

  const emitLogoutEvent = () => {
    broadcast.postMessage({ type: 'CLICK_LOGOUT_BUTTON' });
  };

  const saveLastActivity = (event: any) => {
    sessionStorage.setItem('lastActivity', event.data.lastActivityDateTime);
    resetTimer();
  };

  const cancelAutoLogout = () => {
    resetTimer();
    handleAutoLogoutWarningClose();
    refetch();
  };

  useEffect(() => {
    if (autoLogoutRemainingTime === autoLogoutWarningTime) openAutoLogoutWarning();
    if (autoLogoutRemainingTime === autoLogoutMaxTime) softLogout();
  }, [autoLogoutRemainingTime]);

  useEffect(() => {
    const callback = () => {
      checkLastActivityData();
      refetch();
    };

    if (startListen) {
      document.addEventListener('visibilitychange', callback);
    } else {
      document.removeEventListener('visibilitychange', callback);
    }

    return () => {
      document.removeEventListener('visibilitychange', callback);
    };
  }, [startListen]);

  useEffect(() => {
    if (isFetchUserError && error) {
      if (!axios.isAxiosError(error)) return;
      if (
        error.response?.status === 401 &&
        error.response.data?.method !== undefined &&
        error.response.data.message === 'MFA is required for this request.'
      )
        return;
      if (warningInactiveInterval) clearInterval(warningInactiveInterval);

      offlineLogout();
      navigate(LOGIN);
    }
  }, [isFetchUserError]);

  useEffect(() => {
    timeChecker();
    setStartListen(true);

    return () => {
      setStartListen(false);
      if (timer) clearTimeout(timer);
      if (warningInactiveInterval) clearInterval(warningInactiveInterval);
    };
  }, [timer]);

  useEffect(() => {
    broadcast.onmessage = (message: any) => {
      switch (message.data.type) {
        case 'LAST_ACTIVITY':
          saveLastActivity(message);
          break;
        case 'CANCEL_LOGOUT':
          cancelAutoLogout();
          break;
      }
    };
  }, []);

  useEffect(() => {
    startTimer();
    emitActivityEventWithErrorCallback();

    return () => {
      stopTimer();
    };
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('lastActivity');
    document.addEventListener('click', emitActivityEventWithErrorCallback);
    document.addEventListener('clickOnMedia', emitActivityEventWithErrorCallback);
    document.addEventListener('logoutEvent', emitLogoutEvent);
    emitActivityEventWithErrorCallback();

    return () => {
      document.removeEventListener('click', emitActivityEventWithErrorCallback);
      document.removeEventListener('clickOnMedia', emitActivityEventWithErrorCallback);
      document.removeEventListener('logoutEvent', emitLogoutEvent);
    };
  }, []);

  useEffect(() => {
    if (remoteSessionEnabled || meetingEnabled) {
      timerWorker.postMessage({ action: 'fakeActivity' });
    } else {
      timerWorker.postMessage({ action: 'fakeActivityReset' });
    }

    return () => {
      timerWorker.postMessage({ action: 'fakeActivityReset' });
    };
  }, [remoteSessionEnabled, meetingEnabled]);

  useEffect(() => {
    startTimer();

    wakeupWorker.onmessage = ({ data }) => {
      if (data === 'wakeup') {
        checkLastActivityData();
      }
    };

    return () => {
      stopTimer();
    };
  }, [forceRunTimer]);

  useEffect(() => {
    isUnsavedRef.current = isUnsaved;
  }, [isUnsaved]);

  return (
    <>
      {autoLogoutWarningOpen && (
        <AutoLogoutModal
          remainingTimeInSeconds={autoLogoutMaxTime - autoLogoutWarningTime}
          isLoading={isLoading}
          resetAction={resetAutoLogout}
          logoutAction={softLogout}
        />
      )}
      {children}
    </>
  );
};

const AutoLogout = React.memo(UnMemoizedAutoLogout) as typeof UnMemoizedAutoLogout;
export default AutoLogout;
