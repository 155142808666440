import { Typography } from '@mui/material';
import styled from 'styled-components';

export const AuthTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.title};
  margin-bottom: ${({ theme }) => theme.dimensions.spacing}px;
  color: ${({ theme }) => theme.palette.headerTitle};
  font-weight: bold;
`;

export const AuthParagraph = styled(Typography)`
  color: #626c84;
  margin-bottom: ${({ theme }) => theme.dimensions.spacing}px !important;
`;

export const AuthWrapperBox = styled.div`
  width: 100%;
  padding: 20px;

  @media (min-width: 780px) {
    width: 575px;
  }
`;

export const CreateAccountBox = styled.div`
  width: 100%;
  margin-top: 20px;
  display: grid;
`;
