import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import {
  updateP2PSession,
  getP2PSession,
  initializeP2PSession
} from '../../api/liveSession/liveSession';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useTranslation } from 'react-i18next';

export const SESSION_QUERY_KEY = 'session_query';

export const useP2PSession = (clinicianId: number, amputeeId: number) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [SESSION_QUERY_KEY],
    () => getP2PSession(clinicianId, amputeeId),
    {
      retry: 0,
      cacheTime: 0,
      staleTime: 0
    }
  );

  return {
    result: data || null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useLiveSession = () => {
  const { t } = useTranslation();
  const { data, mutateAsync: initSessions } = useMutation(initializeP2PSession, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:waiting_for_amputee', 'Waiting for patient...')
      );
    }
  });

  const { mutateAsync: updateSession } = useMutation(updateP2PSession, {
    onSuccess: () => {
      NotificationFactory.successNotification(
        t('notifications:session_was_closed', 'Session was closed')
      );
    }
  });

  return {
    initSessions,
    updateSession,
    data
  };
};
