import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createNewActivationCode,
  deleteActivationCode,
  getActiveDeviceCode
} from 'adp-panel/api/deviceCodes/deviceCodes';
import { NotificationFactory } from 'lib/NotificationFactory';
import { mapErrorMessage } from 'utils/notifications';
import { useTranslation } from 'react-i18next';

export const ACTIVE_CODE_QUERY_KEY = 'activeCode';

export const useGetActivationCodes = (queryParams?: any) => {
  const { data, isLoading, error, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [ACTIVE_CODE_QUERY_KEY, queryParams],
    () => getActiveDeviceCode(queryParams),
    {
      retry: false
    }
  );

  return {
    result: data?.items || null,
    total: data?.paginator ? data.paginator.total : null,
    isLoading,
    error,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useCreateActivationCode = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(createNewActivationCode, {
    onSuccess() {
      NotificationFactory.successNotification(
        t('notifications:response.create.success', 'Activation code created')
      );
      return queryClient.invalidateQueries([ACTIVE_CODE_QUERY_KEY]);
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useDeleteActivationCode = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteActivationCode, {
    onSuccess() {
      NotificationFactory.successNotification(
        t('notifications:response.delete.success', 'Activation code deactivated')
      );
      return queryClient.invalidateQueries([ACTIVE_CODE_QUERY_KEY]);
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
