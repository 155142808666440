import {
  DateRangeType,
  GraphFilters
} from 'adp-panel/components/DeviceUsageMonitoring/UsageMonitoringGraph';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { ActiveView } from '@progress/kendo-react-dateinputs';
import { grips_options, GripsWithAllType } from 'adp-panel/constants/grips';
import {
  DatePeriod,
  PeriodEnum
} from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import { TransformedExercise } from 'adp-panel/pages/Goals/utils';

export const instances_options = [
  { text: 'Instance', id: ['Grip switches'] },
  { text: 'Percentage', id: ['Percentage'] },
  { text: 'Time', id: ['Time spent'] }
];

type BaseGraphTypes = 'pie' | 'column';
export type GraphTypes<T extends string = never> = BaseGraphTypes | T;
export type GraphTypeOption<T extends string = never> = { text: string; id: GraphTypes<T> };

export type InitialFilters = any & {
  graphType?: GraphTypes;
};
export const graph_type_options: GraphTypeOption[] = [
  { text: 'Column', id: 'column' },
  { text: 'Pie', id: 'pie' }
];

export const period_options = [
  { text: 'Today', id: PeriodEnum.today },
  { text: 'Day', id: PeriodEnum.day },
  { text: 'Week', id: PeriodEnum.week },
  { text: 'Month', id: PeriodEnum.month },
  { text: 'Custom', id: PeriodEnum.custom }
];

const detectCalendarView = (period: { text: string; id: PeriodEnum }): ActiveView => {
  switch (period.id) {
    case PeriodEnum.day:
    case PeriodEnum.today:
      return 'month';
    case PeriodEnum.week:
      return 'month';
    case PeriodEnum.month:
      return 'year';
    default:
      return 'month';
  }
};

export interface UsageMonitorGraphProps {
  header: string;
  GraphComponent: any;
  devices: DeviceEntry[];
  initialFilters: InitialFilters;
  deviceFilter?: boolean;
  exercises?: TransformedExercise[];
  dateFilter?: boolean;
  dateRangeFilter?: boolean;
  instancesFilter?: boolean;
  periodFilter?: boolean;
  gripsFilter?: boolean;
  graphDataSource?: any;
  legendMap: Map<string, string>;
  gripCountText?: Map<string, string>;
  instancesOptions?: { text: string; id: string[] }[];
  graphTypeOptions?: GraphTypeOption[];
  selectedDevice?: DeviceEntry;

  onFilterChange(filters: GraphFilters): void;
}

export type FormState = ReturnType<typeof useGraphFormState>;

export const useGraphFormState = (props: UsageMonitorGraphProps) => {
  const {
    initialFilters,
    devices,
    instancesOptions = instances_options,
    graphTypeOptions = graph_type_options,
    exercises,
    selectedDevice,
    onFilterChange
  } = props;
  const grips: GripsWithAllType[] = [{ text: 'All', id: 'all' }, ...grips_options];

  const [date, setDate] = useState<Date>(initialFilters.date ?? new Date());
  const [dateRange, setDateRange] = useState<DateRangeType>(
    initialFilters.dateRange ?? { start: new Date(), end: new Date() }
  );
  const [selectedPeriod, setSelectedPeriod] = useState<DatePeriod>('today');
  const [calendarView, setCalendarView] = useState<ActiveView>('month');
  const [instance, setInstance] = useState(
    initialFilters.instance
      ? instancesOptions.find(({ text }) => text === initialFilters.instance) || instancesOptions[0]
      : instancesOptions[0]
  );
  const [graphType, setGraphType] = useState<GraphTypeOption>(
    initialFilters.graphType
      ? graph_type_options.find(({ id }) => id === initialFilters.graphType) ||
          graph_type_options[0]
      : graph_type_options[0]
  );
  const [period, setPeriod] = useState(
    initialFilters.period
      ? period_options.find(({ id }) => id === initialFilters.period) || period_options[0]
      : period_options[0]
  );
  const [grip, setGrip] = useState<GripsWithAllType>(initialFilters.grip ?? grips_options[0]);
  const [device, setDevice] = useState<DeviceEntry>(selectedDevice ?? (devices && devices[0]));
  const [exercise, setExercise] = useState<TransformedExercise | undefined>(
    exercises ? exercises[0] : undefined
  );

  useEffect(() => {
    switch (period.id) {
      case 'today':
        setDateRange({ end: new Date(), start: new Date() });
        break;
      case 'week':
        setDateRange({ end: new Date(), start: dayjs().subtract(7, 'day').toDate() });
        break;
      case 'month':
        setDateRange({ end: new Date(), start: dayjs().subtract(30, 'day').toDate() });
        break;
    }
  }, [period]);

  useEffect(() => {
    if (devices && devices.length > 0) {
      setDevice(devices[0]);
    }
  }, [devices]);

  useEffect(() => {
    if (selectedDevice) {
      setDevice(selectedDevice);
    }
  }, [selectedDevice]);

  useEffect(() => {
    onFilterChange({
      date,
      dateRange,
      instance: instance?.id,
      graphType: graphType?.id,
      device,
      grip: grip.id,
      period: period?.id,
      exercise
    });
  }, [date, instance, graphType, period, grip, device, dateRange, exercise]);

  useEffect(() => {
    if (period) {
      setCalendarView(detectCalendarView(period));
    }
  }, [period]);

  return {
    date,
    calendarView,
    instance,
    instances: instancesOptions,
    graphType,
    graphTypes: graphTypeOptions,
    period,
    grip,
    grips,
    device,
    devices,
    exercise,
    exercises,
    dateRange,
    selectedPeriod,
    setDate,
    setInstances: setInstance,
    setGraphType,
    setPeriod,
    setGrip,
    setDevice,
    setExercise,
    setDateRange,
    setSelectedPeriod
  };
};
