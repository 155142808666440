import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Alert, Button } from '@mui/material';
import Card from 'configurator/components/atoms/Card/Card';
import DropdownImg from 'configurator/components/atoms/Dropdown/DropdownImg';
import { GRIPS } from 'configurator/consts/consts';
import { gripsImagesMap } from 'configurator/utils/gripsImages';
import {
  gripsGroupsOptionsMap,
  gripsGroupsOptionsReversedMap
} from 'configurator/utils/definesLocal';
import { Grips } from 'bluetooth/Bluetooth/Grips';
import { postCurrentGrip, postGripSpeed } from 'configurator/bluetooth-handler/bluetoothFunctions';
import TelemetryController from 'configurator/bluetooth-handler/telemetryController';
import useWatch from 'configurator/hooks/useWatch';
import { getGripName } from 'configurator/views/GripsConfiguration/GripsConfigurationComponent';
import { useGetCustomGrips } from 'configurator/hooks/api/useDevice';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';

export const Viewer = styled.div`
  width: 100%;
  padding: 20px 10px 0 10px;
  margin: 0 auto;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

const GripsTester = () => {
  const [selectedGrip, setSelectedGrip] = useState<any>(Grips.kGripPower);
  const { deviceId } = useDeviceInfoStore((state) => ({
    deviceId: state.deviceId
  }));
  const { result: customGripsAllowed } = useGetCustomGrips({
    deviceId
  });
  const { t } = useTranslation();
  useWatch(TelemetryController, ['prosthesisGrip', 'gripInTransition']);

  const handleOptions = (option) => {
    const newGrip: any = gripsGroupsOptionsReversedMap.get(option);
    postCurrentGrip(newGrip);
  };

  useEffect(() => {
    if (TelemetryController.prosthesisGrip !== undefined) {
      setSelectedGrip(TelemetryController.prosthesisGrip);
    }
  }, [TelemetryController.prosthesisGrip]);

  return (
    <div>
      <DropdownImg
        options={GRIPS.map((grip) => ({
          value: getGripName(customGripsAllowed, grip),
          img: gripsImagesMap.get(grip)
        }))}
        selected={{
          value: getGripName(customGripsAllowed, selectedGrip),
          img: gripsImagesMap.get(selectedGrip)
        }}
        onChange={(option) => handleOptions(option)}
        showImg
        label={t('configurator:component.grips_tester.grip', 'Current grip')}
        disabled={TelemetryController.gripInTransition}
      />
      <ButtonsWrapper>
        <Button
          fullWidth
          onClick={() => postGripSpeed(-100)}
          disabled={TelemetryController.gripInTransition}>
          Open
        </Button>
        <Button
          fullWidth
          onClick={() => postGripSpeed(100)}
          disabled={TelemetryController.gripInTransition}>
          Close
        </Button>
      </ButtonsWrapper>
      {TelemetryController.gripInTransition && (
        <Alert severity='info'>
          {t('configurator:component.grips_tester.transition', 'Grip in transition, please wait.')}
        </Alert>
      )}
    </div>
  );
};

export default GripsTester;
