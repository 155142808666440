import { useQuery } from '@tanstack/react-query';
import { getAvailableLanguages } from 'adp-panel/api/settings/settings';
import { codeIconMap, codeNameMap, CountryCode } from 'adp-panel/constants/countries';
import { LanguageType } from 'components/FormFields/CustomLanguageSelect';
import { useMemo } from 'react';

export const LANGUAGE_QUERY_KEY = 'available_languages';

export const useAvailableLanguages = () => {
  const { data, isError, refetch, isRefetching, isRefetchError, isInitialLoading } = useQuery(
    [LANGUAGE_QUERY_KEY],
    () => getAvailableLanguages()
  );

  const availableLanguages = useMemo<LanguageType[]>(
    () =>
      data
        ? Array.from(data.languages).map((key) => {
            return {
              label: codeNameMap.get(key.toUpperCase() as CountryCode) || '',
              code: key,
              icon: codeIconMap.get(key.toUpperCase() as CountryCode)
            };
          })
        : [],
    [data]
  );

  return {
    result: availableLanguages,
    isLoading: isInitialLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};
