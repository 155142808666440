import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';
import { DummyPatientEntry } from 'adp-panel/api/devices/device.types';
import { icons } from 'components/CustomIcon/CustomIcon';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useTranslation } from 'react-i18next';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';
import ModalWrapper from 'components/Modals/ModalWrapper';

type DummyPatientModalProps = {
  handleClose: () => void;
  dummyPatientData: DummyPatientEntry;
};

const StyledTopicLabel = styledMui(Typography)`
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #475467;
`;

const StyledTopicText = styledMui(Typography)`
  color: #101828;
  font-size: 14px;
  font-weight: 600;
`;

const DummyPatientModal = ({ handleClose, dummyPatientData }: DummyPatientModalProps) => {
  const { t } = useTranslation('devices');

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(dummyPatientData.email);
      NotificationFactory.successNotification(
        t('devices:dummy_patient_details_modal.copied', 'Email copied to clipboard')
      );
    } catch (error) {
      NotificationFactory.errorNotification(
        t('devices:dummy_patient_details_modal.copied_fail', 'Failed to copy email to clipboard')
      );
    }
  };

  return (
    <ModalWrapper
      sx={{ width: '430px' }}
      title={t('devices:dummy_patient_details_modal.title', 'Dummy patient')}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '30px'
        }}>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#DCFAE6',
            borderRadius: '50%',
            padding: '10px',
            height: '60px',
            width: '60px'
          }}>
          <CheckCircleOutlinedIcon
            sx={{
              color: '#079455',
              fontSize: 30
            }}
          />
        </Box>
        <h2 style={{ margin: '30px 0' }}>
          {t('devices:dummy_patient_details_modal.info', 'Dummy patient was successfully created.')}
        </h2>
      </div>
      <Stack direction='column' spacing={2} mb={2}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Box>
            <StyledTopicLabel>
              {t('devices:dummy_patient_details_modal.email', 'Email')}
            </StyledTopicLabel>
            <StyledTopicText>{dummyPatientData.email}</StyledTopicText>
          </Box>
          <CustomButton onClick={handleCopy} color='lightBlue' Icon={icons['copy-icon']}>
            {t('devices:dummy_patient_details_modal.copy_button', 'Copy')}
          </CustomButton>
        </Stack>
        <Box>
          <StyledTopicLabel>
            {t('devices:dummy_patient_details_modal.password', 'Password')}
          </StyledTopicLabel>
          <StyledTopicText>{dummyPatientData.password}</StyledTopicText>
        </Box>
      </Stack>
      <FormButtonsWrapper>
        <CustomButton
          data-testid='cancel-dummy-patient'
          type='button'
          onClick={handleClose}
          color='light'>
          {t('devices:dummy_patient_details_modal.close', 'Close')}
        </CustomButton>
      </FormButtonsWrapper>
    </ModalWrapper>
  );
};

export default DummyPatientModal;
