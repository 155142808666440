import * as Sentry from '@sentry/react';
import { NotificationFactory } from 'lib/NotificationFactory';
import { detachDevice } from 'adp-panel/api/devices/devices';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DEVICES_QUERY_KEY } from 'adp-panel/hooks/api/useDevices';
import { mapErrorMessage } from 'utils/notifications';

export const useDetachDevice = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(detachDevice, {
    onSuccess() {
      NotificationFactory.successNotification(
        t('notifications:device_detached_clinician_success', 'Device detached')
      );
      queryClient.invalidateQueries({ queryKey: [DEVICES_QUERY_KEY] });
    },
    onError(error: any) {
      Sentry.captureException(error);
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    handleSubmit: mutateAsync,
    isLoading,
    isError
  };
};
