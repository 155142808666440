/* eslint-disable prefer-const */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tab, Tabs } from '@mui/material';
import VerticalGraphSlider from 'configurator/components/molecules/VerticalGraphSlider/VerticalGraphSlider';
import HorizontalGraphSlider from 'configurator/components/molecules/HorizontalGraphSlider/HorizontalGraphSlider';
import PositionsAdjuster from 'configurator/components/molecules/PositionsAdjuster/PositionsAdjuster';
import { emgColors } from 'configurator/theme/emgColors/emgColors';
import { emgThresholdsEntry } from 'configurator/consts/deviceConfig/deviceConfig.types';
import { useGraph } from 'configurator/hooks/useGraph';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { HISTORY_EVENTS } from 'configurator/consts/consts';
import { useMeetingStore } from 'configurator/reducers/meetingStore';
import { getCurrentConfigSelector } from 'configurator/reducers/helpers/selectors';
import { ChartCombined, ChartProportional } from './Charts';
import {
  ActivationWrapper,
  AdjusterWrapper,
  ContractionWrapper,
  ControlsWrapper,
  GainsSlidersWrapper,
  ReplayWrapper,
  Wrapper
} from './styled';
import { useReplay } from '../../hooks/useReplay';
import { InputDevices, SpeedControlStrategies } from '../../../bluetooth/Bluetooth/Control';
import useCanAccess from 'adp-panel/hoc/useCanAccess';
import { parseTelemetryData, updateAllGraphs } from './utils';
import Card from 'adp-panel/components/Card/Card';
import { ConfigurationSection } from '../ProsthesisSettings/styled';

const GraphComponent = () => {
  const { t } = useTranslation();
  const graphContainer = useRef(null);
  const graphContainerProportional = useRef(null);
  const { setConfigProperty, addConfigHistory } = useConfigStore((state) => ({
    setConfigProperty: state.setConfigProperty,
    addConfigHistory: state.addConfigHistory
  }));
  const config = useConfigStore(getCurrentConfigSelector);
  const { emgThresholds, emgGains, speedControlStrategy, inputDevice } = config;
  const { replayData, enabled: replayIsEnabled } = useReplayStore((state) => ({
    replayData: state.replayData,
    enabled: state.enabled
  }));
  const viewMode = useMeetingStore((state) => state.viewMode);
  const { canAccess } = useCanAccess({ resource: 'emg' });
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const liveConfiguratorEnabled = useLiveConfiguratorStore((state) => state.enabled);
  const [emgGainExtension, setEmgGainExtension] = useState(0);
  const [emgGainFlexion, setEmgGainFlexion] = useState(0);
  const [graphMode, setGraphMode] = useState(
    speedControlStrategy?.[0] === SpeedControlStrategies.kThreshold ? 0 : 1
  );
  const [showThresholdProportional, setShowThresholdProportional] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true
  ]);
  const [showThreshold, setShowThreshold] = useState([true, true, true, true]);
  const [slidersStateSingle, setSlidersStateSingle] = useState([
    {
      name: 'Time',
      value: 500
    },
    {
      name: 'Interval',
      value: 400
    }
  ]);

  const [slidersStateDual, setSlidersStateDual] = useState([
    {
      name: 'Activation',
      valueFirst: 0,
      valueSecond: 0
    },
    {
      name: 'Contraction',
      valueFirst: 0,
      valueSecond: 0
    }
  ]);
  const [valuesProportional, setValuesProportional] = useState({
    opening: [0, 0, 0, 0, 0],
    closing: [0, 0, 0, 0, 0]
  });
  const { updateData: updateDataCombined } = useGraph(
    graphContainer,
    {
      time: slidersStateSingle[0].value,
      interval: slidersStateSingle[1].value,
      thresholds: [
        {
          values: slidersStateDual[0].valueFirst,
          label: 'CS Open',
          color: emgColors.csOpen
        },
        {
          values: slidersStateDual[0].valueSecond,
          label: 'CS Close',
          color: emgColors.csClose
        },
        {
          values: slidersStateDual[1].valueFirst,
          label: 'SCS Open',
          color: emgColors.activationOpen
        },
        {
          values: slidersStateDual[1].valueSecond,
          label: 'SCS Close',
          color: emgColors.activationClose
        }
      ],
      showThreshold
    },
    slidersStateSingle[0].value,
    [
      slidersStateDual[0].valueFirst,
      slidersStateDual[0].valueSecond,
      slidersStateDual[1].valueFirst,
      slidersStateDual[1].valueSecond,
      [showThreshold],
      graphMode
    ],
    [graphMode, slidersStateSingle[0].value, viewMode]
  );
  const { updateData: updateDataProportional } = useGraph(
    graphContainerProportional,
    {
      time: slidersStateSingle[0].value,
      interval: slidersStateSingle[1].value,
      thresholds: [
        {
          values: valuesProportional.closing[0],
          label: 'Power Closing',
          color: emgColors.powerClose
        },
        {
          values: valuesProportional.opening[1],
          label: 'Speed 3 Opening',
          color: emgColors.speed3Open
        },
        {
          values: valuesProportional.closing[1],
          label: 'Speed 3 Closing',
          color: emgColors.speed3Close
        },
        {
          values: valuesProportional.opening[2],
          label: 'Speed 2 Opening',
          color: emgColors.speed2Open
        },
        {
          values: valuesProportional.closing[2],
          label: 'Speed 2 Closing',
          color: emgColors.speed2Close
        },
        {
          values: valuesProportional.opening[3],
          label: 'Speed 1 Opening',
          color: emgColors.activationOpen
        },
        {
          values: valuesProportional.closing[3],
          label: 'Speed 1 Closing',
          color: emgColors.activationClose
        },
        {
          values: valuesProportional.opening[4],
          label: 'SCS Opening',
          color: emgColors.csOpen
        },
        {
          values: valuesProportional.closing[4],
          label: 'SCS Closing',
          color: emgColors.csClose
        }
      ],
      showThreshold: showThresholdProportional
    },
    slidersStateSingle[0].value,
    [
      valuesProportional.closing[0],
      valuesProportional.closing[1],
      valuesProportional.closing[2],
      valuesProportional.closing[3],
      valuesProportional.closing[4],
      valuesProportional.opening[0],
      valuesProportional.opening[1],
      valuesProportional.opening[2],
      valuesProportional.opening[3],
      valuesProportional.opening[4],
      [showThresholdProportional],
      graphMode
    ],
    [graphMode, slidersStateSingle[0].value, viewMode]
  );

  useEffect(() => {
    let graphInterval;
    if (deviceConnected) {
      graphInterval = setInterval(updateGraphs, 50);
    }

    if (liveConfiguratorEnabled) {
      graphInterval = setInterval(updateGraphs, 50);
    }

    if (replayIsEnabled) {
      graphInterval = setInterval(() => updateGraphs(false), 50);
    }
    return function clear() {
      if (graphInterval) {
        clearInterval(graphInterval);
      }
    };
  }, [deviceConnected, liveConfiguratorEnabled, replayIsEnabled]);

  const updateGraphs = (fixedUpdate = true) => {
    const emgData = parseTelemetryData(fixedUpdate);
    if (!emgData) return;
    const { emgExtensionOptions, emgFlexionOptions } = emgData;
    updateAllGraphs([
      {
        container: graphContainer,
        updateFunc: () => updateDataCombined([emgExtensionOptions, emgFlexionOptions])
      },
      {
        container: graphContainerProportional,
        updateFunc: () => updateDataProportional([emgExtensionOptions, emgFlexionOptions])
      }
    ]);
  };

  useEffect(() => {
    if (emgThresholds) {
      setSlidersStateDual([
        {
          name: 'Activation',
          valueFirst: emgThresholds[0],
          valueSecond: emgThresholds[1]
        },
        {
          name: 'Contraction',
          valueFirst: emgThresholds[2],
          valueSecond: emgThresholds[5]
        }
      ]);
      const [
        csOpening,
        csClosing,
        speed1Opening,
        speed2Opening,
        speed3Opening,
        Speed1Closing,
        Speed2Closing,
        Speed3Closing,
        powerOpening,
        powerClosing
      ] = emgThresholds;
      setValuesProportional({
        opening: [powerOpening, speed3Opening, speed2Opening, speed1Opening, csOpening],
        closing: [powerClosing, Speed3Closing, Speed2Closing, Speed1Closing, csClosing]
      });
    }
  }, [emgThresholds]);

  useEffect(() => {
    if (emgGains) {
      setEmgGainExtension(emgGains[0]);
      setEmgGainFlexion(emgGains[1]);
    }
  }, [emgGains]);

  const handleTime = (value) => {
    const newArray = slidersStateSingle.map((slider) => {
      if (slider.name === 'Time') {
        slider.value = value;
      }
      return slider;
    });

    setSlidersStateSingle(newArray);
  };

  const updateHistory = (prevState) => {
    addConfigHistory(HISTORY_EVENTS.emgSettings, prevState);
  };

  const handleSliderDualChange = (value, index, { name, sliderIndex }) => {
    const newArray = slidersStateDual.map((slider) => {
      if (slider.name === name) {
        if (sliderIndex === 0) {
          slider.valueFirst = value;
        }
        if (sliderIndex === 1) {
          slider.valueSecond = value;
        }
      }
      return slider;
    });

    setSlidersStateDual(newArray);
  };

  const handleSliderDualOnAfterChange = () => {
    const CSOpen = slidersStateDual[0].valueFirst;
    const CSClose = slidersStateDual[0].valueSecond;
    const SCSOpen = slidersStateDual[1].valueFirst;
    const SCSClose = slidersStateDual[1].valueSecond;
    const {
      opening: [powerOpening, speed3Opening, speed2Opening],
      closing: [powerClosing, speed3Closing, speed2Closing]
    } = valuesProportional;
    const newEMG = [
      CSOpen,
      CSClose,
      SCSOpen,
      speed2Opening,
      speed3Opening,
      SCSClose,
      speed2Closing,
      speed3Closing,
      powerOpening,
      powerClosing
    ] as emgThresholdsEntry;
    updateHistory(setConfigProperty('emgThresholds', newEMG));
  };

  const setEmgGainsStore = () => {
    updateHistory(setConfigProperty('emgGains', [emgGainExtension, emgGainFlexion]));
  };

  const handleSliderChangeProportional = (
    value,
    index,
    { index: fingerIndex, sliderType, min, max }
  ) => {
    const sanitizeInput = () => {
      let sanitizedInputValue = value;
      if (typeof sanitizedInputValue === 'string') {
        sanitizedInputValue = parseInt(value, 10);
        if (Number.isNaN(sanitizedInputValue)) sanitizedInputValue = 0;
      }
      if (value > max) {
        sanitizedInputValue = max;
      }

      return sanitizedInputValue;
    };

    const valueSanitized = sanitizeInput();
    let newOpening = valuesProportional.opening;
    let newClosing = valuesProportional.closing;
    if (sliderType === 'primary') {
      newOpening = newOpening.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    } else {
      newClosing = newClosing.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    }
    const newGripValues = {
      opening: newOpening,
      closing: newClosing
    };
    setValuesProportional(newGripValues);
  };

  const handleSliderOnAfterChangeProportional = () => {
    let {
      opening: [powerOpening, speed3Opening, speed2Opening, speed1Opening, csOpening],
      closing: [powerClosing, speed3Closing, speed2Closing, speed1Closing, csClosing]
    } = valuesProportional;
    const newEMG = [
      csOpening,
      csClosing,
      speed1Opening,
      speed2Opening,
      speed3Opening,
      speed1Closing,
      speed2Closing,
      speed3Closing,
      powerOpening,
      powerClosing
    ] as emgThresholdsEntry;

    updateHistory(setConfigProperty('emgThresholds', newEMG));
  };

  const handleInputChange = (value, index, { index: fingerSlider, sliderType, min, max }) => {
    handleSliderChangeProportional(value, index, {
      index: fingerSlider,
      sliderType,
      min,
      max
    });
  };

  const handleCheckbox = (checkboxIndex, proportional = false) => {
    if (proportional) {
      const newShowThresholdProportional = showThresholdProportional.map((item, index) => {
        if (index === checkboxIndex) {
          item = !item;
        }
        return item;
      });
      setShowThresholdProportional(newShowThresholdProportional);
      return;
    }

    const newShowThreshold = showThreshold.map((item, index) => {
      if (index === checkboxIndex) {
        item = !item;
      }
      return item;
    });
    setShowThreshold(newShowThreshold);
  };

  const HorizontalSliders = useMemo(
    () => (
      <>
        <HorizontalGraphSlider
          // @ts-ignore
          data={{ name: t('configurator:component.graph.time_graph_label', 'Time') }}
          handleChange={(e, value) => handleTime(value)}
          label={t('configurator:component.graph.time_label', {
            defaultValue: 'Time: last {{time}} seconds',
            time: Math.round(slidersStateSingle[0].value / 20)
          })}
          value={slidersStateSingle[0].value}
          limits={{ min: 200, max: 500 }}
        />
      </>
    ),
    [slidersStateSingle[0].value]
  );

  const { isPlay, play, pause, index, handleSlider } = useReplay(replayData ?? [], replayIsEnabled);

  const ReplaySliderActions = useMemo(() => {
    const dataLength = replayData?.length || 1;
    return (
      <ReplayWrapper>
        <Button onClick={() => (isPlay ? pause() : play())}>
          {isPlay
            ? t('configurator:component.graph.pause', 'Pause')
            : t('configurator:component.graph.play', 'Play')}
        </Button>
        <HorizontalGraphSlider
          // @ts-ignore
          data={{ name: 'Test 123' }}
          handleChange={(e, value) => handleSlider(value)}
          label={t('configurator:component.graph.replay_progress', {
            defaultValue: 'Progress: {{progress}} %',
            progress: Math.floor((index / dataLength) * 100)
          })}
          value={index}
          limits={{ min: 0, max: replayData?.length || 1 }}
        />
      </ReplayWrapper>
    );
  }, [replayData, index, isPlay]);

  const coaptSelected = inputDevice?.[0] === InputDevices.kInputOptionPatRec;

  const GainsSliders = useMemo(
    () => (
      // @ts-ignore
      <ConfigurationSection
        tooltipText={t('configurator:component.toggle_tip.no_access', "You don't have access")}
        disabled={!canAccess}>
        <GainsSlidersWrapper emgGains={emgGains}>
          <HorizontalGraphSlider
            // @ts-ignore
            data={{ name: 'Extension' }}
            handleChange={(e, value) => setEmgGainExtension(value)}
            handleOnAfterChange={setEmgGainsStore}
            label={t('configurator:component.graph.open_signal_gain', {
              defaultValue: 'Open signal gain: {{emgGainExtension}}%',
              emgGainExtension
            })}
            value={emgGainExtension}
            limits={{ min: 50, max: 250 }}
            disableSliders={!emgGains || coaptSelected || !canAccess}
          />
          <HorizontalGraphSlider
            // @ts-ignore
            data={{ name: 'Flexion' }}
            handleChange={(e, value) => setEmgGainFlexion(value)}
            handleOnAfterChange={setEmgGainsStore}
            label={t('configurator:component.graph.close_signal_gain', {
              defaultValue: 'Close signal gain: {{emgGainFlexion}}%',
              emgGainFlexion
            })}
            value={emgGainFlexion}
            limits={{ min: 50, max: 250 }}
            disableSliders={!emgGains || coaptSelected || !canAccess}
          />
        </GainsSlidersWrapper>
      </ConfigurationSection>
    ),
    [emgGainExtension, emgGainFlexion, emgGains, coaptSelected, canAccess]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setGraphMode(newValue);
  };

  const disableProportionalSliders = () => {
    if (!canAccess) return [true, true, true, true, true];

    if (speedControlStrategy?.[0] === SpeedControlStrategies.kThreshold)
      return [false, true, true, false, false];

    return true;
  };

  return (
    <Card style={{ paddingTop: '8px' }}>
      {replayIsEnabled && ReplaySliderActions}
      <Tabs
        value={graphMode}
        onChange={handleChange}
        sx={{ marginBottom: '24px', borderBottom: '1px solid #EAECF0' }}
        variant='fullWidth'>
        <Tab label={t('configurator:component.graph.one_speed', 'One speed')} />
        <Tab label={t('configurator:component.graph.proportional', 'Proportional')} />
      </Tabs>
      <Wrapper graphMode={graphMode}>
        {graphMode === 0 && <ChartCombined graphContainer={graphContainer} />}
        {graphMode === 1 && <ChartProportional graphContainer={graphContainerProportional} />}
        <ControlsWrapper data-tour-graph='time'>
          {HorizontalSliders}
          {GainsSliders}
        </ControlsWrapper>
        {graphMode === 0 && (
          <>
            <ActivationWrapper>
              <VerticalGraphSlider
                // @ts-ignore
                data={{ name: t('configurator:component.graph.activation', 'Activation') }}
                handleChange={handleSliderDualChange}
                handleOnAfterChange={handleSliderDualOnAfterChange}
                label={t('configurator:component.graph.cs', 'CS')}
                tooltipDirection='left'
                disableSliders={!canAccess}
                sliders={[
                  {
                    value: slidersStateDual[0].valueFirst,
                    label: t('configurator:component.graph.open', 'Open'),
                    index: 0
                  },
                  {
                    value: slidersStateDual[0].valueSecond,
                    label: t('configurator:component.graph.close', 'Close'),
                    index: 1
                  }
                ]}
                handleCheckbox={handleCheckbox}
                checked={[showThreshold[0], showThreshold[1]]}
                checkboxIndexes={[0, 1]}
                limits={{ min: 2, max: 100 }}
                trackClasses={['cs-open-slider', 'cs-close-slider']}
                disableAccessTooltip={t(
                  'configurator:component.toggle_tip.no_access',
                  "You don't have access"
                )}
                disabledAccess={!canAccess}
              />
            </ActivationWrapper>
            <ContractionWrapper>
              <VerticalGraphSlider
                // @ts-ignore
                data={{ name: t('configurator:component.graph.contraction', 'Contraction') }}
                handleChange={handleSliderDualChange}
                handleOnAfterChange={handleSliderDualOnAfterChange}
                disableSliders={!canAccess}
                label={t('configurator:component.graph.activation', 'Activation')}
                tooltipDirection='right'
                sliders={[
                  {
                    value: slidersStateDual[1].valueFirst,
                    label: t('configurator:component.graph.open', 'Open'),
                    index: 0
                  },
                  {
                    value: slidersStateDual[1].valueSecond,
                    label: t('configurator:component.graph.close', 'Close'),
                    index: 1
                  }
                ]}
                handleCheckbox={handleCheckbox}
                checked={[showThreshold[2], showThreshold[3]]}
                checkboxIndexes={[2, 3]}
                limits={{ min: 2, max: 100 }}
                trackClasses={['activation-open-slider', 'activation-close-slider']}
                disableAccessTooltip={t(
                  'configurator:component.toggle_tip.no_access',
                  "You don't have access"
                )}
                disabledAccess={!canAccess}
              />
            </ContractionWrapper>
          </>
        )}
        {graphMode === 1 && (
          <>
            <AdjusterWrapper>
              <PositionsAdjuster
                values={{
                  columnPrimary: valuesProportional.opening,
                  columnSecondary: valuesProportional.closing
                }}
                handleSliderChange={handleSliderChangeProportional}
                handleOnAfterChange={handleSliderOnAfterChangeProportional}
                handleOnAfterChangeInput={handleSliderOnAfterChangeProportional}
                handleInputChange={handleInputChange}
                columns={[
                  t('configurator:component.graph.opening', 'Opening'),
                  t('configurator:component.graph.closing', 'Closing')
                ]}
                rows={[
                  t('configurator:component.graph.full_power', 'Full power'),
                  t('configurator:component.graph.speed_3', 'Speed 3'),
                  t('configurator:component.graph.speed_2', 'Speed 2'),
                  t('configurator:component.graph.speed_1', 'Speed 1'),
                  t('configurator:component.graph.cs', 'CS')
                ]}
                graphVersion
                limits={[
                  { min: 2, max: 100 },
                  { min: 2, max: 100 },
                  { min: 2, max: 100 },
                  { min: 2, max: 100 },
                  { min: 2, max: 100 }
                ]}
                disableInput={disableProportionalSliders()}
                trackClasses={[
                  ['', 'power-slider'],
                  ['speed3-open-slider', 'speed3-close-slider'],
                  ['speed2-open-slider', 'speed2-close-slider'],
                  ['activation-open-proportional-slider', 'activation-close-proportional-slider'],
                  ['cs-open-slider', 'cs-close-slider']
                ]}
                checked={showThresholdProportional}
                handleCheckbox={handleCheckbox}
                disableAccessTooltip={t(
                  'configurator:component.toggle_tip.no_access',
                  "You don't have access"
                )}
                disableAccess={!canAccess}
              />
            </AdjusterWrapper>
          </>
        )}
      </Wrapper>
    </Card>
  );
};

export default GraphComponent;
