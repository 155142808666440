import { pick } from 'lodash';
import { defaultConfig } from 'configurator/consts/deviceConfig/deviceConfig';
import { DeviceInfoState } from 'configurator/reducers/deviceInfoStore';
import { numericalFirmwareVersion } from 'configurator/utils/funcs';
import { ConfigStoreState } from '../configStore';

export const getCurrentConfigSelector = (state: ConfigStoreState, forceSlot?: number) => {
  let slotUsed = state.slotSelected;
  if (forceSlot !== undefined) slotUsed = forceSlot;

  if (
    state.config.common.config &&
    state.config.modes.find((mode) => mode.slot === slotUsed)?.config
  ) {
    return {
      ...state.config.common.config,
      ...state.config.modes.find((mode) => mode.slot === slotUsed)?.config
    };
  }
  return defaultConfig;
};

export const getCurrentConfigApiSelector = (state: ConfigStoreState, forceSlot?: number) => {
  let slotUsed = state.slotSelected;
  if (forceSlot !== undefined) slotUsed = forceSlot;

  if (
    state.config.common.configAPI &&
    state.config.modes.find((mode) => mode.slot === slotUsed)?.configAPI
  ) {
    return {
      ...state.config.common.configAPI,
      ...state.config.modes.find((mode) => mode.slot === slotUsed)?.configAPI
    };
  }
  return defaultConfig;
};

export const getModeBySlotSelector = (state, slot) =>
  state.config.modes.find((mode) => mode.slot === slot);
export const getFwVersionSelector = (state: DeviceInfoState) => {
  const fwExists = state.versions?.numerical ?? numericalFirmwareVersion(state.firmware?.name);

  if (!fwExists) return -1;

  return state.versions?.numerical ?? numericalFirmwareVersion(state.firmware?.name);
};

export const getSomeConfigSelector = (config, keys) => pick(config, keys);

// Get all thresholds from the config from FW 3.0.0
export const getAllThresholds = (
  state: ConfigStoreState,
  deviceInfoState: DeviceInfoState,
  configConflict: boolean,
  forceSlot?: number
) => {
  let slotUsed = state.slotSelected;
  if (forceSlot !== undefined) slotUsed = forceSlot;
  const fw =
    deviceInfoState.versions?.numerical ?? numericalFirmwareVersion(deviceInfoState.firmware?.name);
  const modeConfig = configConflict
    ? state.config.modes.find((mode) => mode.slot === slotUsed)?.configAPI
    : state.config.modes.find((mode) => mode.slot === slotUsed)?.config;

  if (modeConfig && fw >= 30000) {
    const emgThresholds = modeConfig.emgThresholds;
    const freezeModeEmg = modeConfig.freezeModeEmg;
    const freezeModeEmgSettings = modeConfig.freezeModeEmgSettings;
    const speedClosingThresholds = [emgThresholds[5], emgThresholds[6], emgThresholds[7]];
    const speedOpeningThresholds = [emgThresholds[2], emgThresholds[3], emgThresholds[4]];
    const currentClosingThreshold = emgThresholds[9];
    const gripChangeSignalClosingThreshold = emgThresholds[1];
    const gripChangeSignalOpeningThreshold = emgThresholds[0];
    const relaxationClosingThreshold = freezeModeEmg[0];
    const relaxationOpeningThreshold = freezeModeEmg[1];
    const longDoubleStageClosing = freezeModeEmgSettings[1];
    const longDoubleStageOpening = freezeModeEmgSettings[0];
    return [
      speedClosingThresholds[0],
      speedClosingThresholds[1],
      speedClosingThresholds[2],
      speedOpeningThresholds[0],
      speedOpeningThresholds[1],
      speedOpeningThresholds[2],
      currentClosingThreshold,
      gripChangeSignalClosingThreshold,
      gripChangeSignalOpeningThreshold,
      relaxationClosingThreshold,
      relaxationOpeningThreshold,
      longDoubleStageClosing,
      longDoubleStageOpening
    ];
  }
  return null;
};
