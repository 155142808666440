import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dot from 'dot-object';
import {
  createDevice,
  createDummyPatientForDevice,
  deleteDevice,
  detachDevice,
  getDevice,
  getDeviceConfig,
  getDeviceConfigHistory,
  getDevices,
  getDevicesModels,
  getUserDevices,
  updateDevice
} from 'adp-panel/api/devices/devices';
import { DeviceConfig } from 'adp-panel/api/devices/device.types';
import { useEffect } from 'react';
import { NotificationFactory } from 'lib/NotificationFactory';
import { mapErrorMessage } from 'utils/notifications';
import { useTranslation } from 'react-i18next';

export const DEVICES_QUERY_KEY = 'devices';
export const DEVICE_CONFIG_QUERY_KEY = 'device_config';
const USER_DEVICES_QUERY_KEY = 'user-devices';
const DEVICES_MODELS_QUERY_KEY = 'devices-models';
const DEVICE_CONFIG_HISTORY_QUERY_KEY = 'device_config_history';

export const useDeviceInfo = (deviceId: number, enabled: boolean, queryParams?: any) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [DEVICES_QUERY_KEY, deviceId, queryParams],
    () => getDevice(deviceId, queryParams),
    {
      onError() {
        NotificationFactory.errorNotification('Failed to fetch device');
      },
      enabled
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useDevicesList = (queryParams?: any) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [DEVICES_QUERY_KEY, queryParams],
    () => getDevices(queryParams),
    {
      onError() {
        NotificationFactory.errorNotification('Failed to fetch devices');
      }
    }
  );

  return {
    result: data ? data.items : null,
    total: data?.paginator ? data.paginator.total : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useDevicesListInfinite = (queryParams?: any, enabled: boolean = true) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isSuccess,
    isRefetching,
    isRefetchError,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [DEVICES_QUERY_KEY, queryParams],
    ({ pageParam = 0 }) => getDevices({ ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    isSuccess
  };
};

export const useDevicesListDependent = (
  params?: { clinician: any; enabler: any },
  queryParams?: any
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [DEVICES_QUERY_KEY, params?.clinician, queryParams],
    () => getDevices(queryParams),
    {
      enabled: !!params?.enabler,
      onError(error: any) {
        mapErrorMessage(error);
      }
    }
  );

  return {
    result: data ? data.items : null,
    total: data?.paginator ? data.paginator.total : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useUserDevices = (userId: number, queryParams?: any) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [USER_DEVICES_QUERY_KEY],
    () => getUserDevices(userId, queryParams),
    {
      enabled: !!userId,
      onError() {
        NotificationFactory.errorNotification('Failed to fetch user devices');
      }
    }
  );

  return {
    result: data ? data.items : null,
    total: data?.paginator ? data.paginator.total : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useDeviceModelList = (enabled: boolean = true) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [DEVICES_MODELS_QUERY_KEY],
    getDevicesModels,
    {
      enabled,
      onError() {
        mapErrorMessage('Failed to fetch devices models');
      }
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const parseConfig = (config: any) => {
  let newConfig = {};
  for (const key in config) {
    if (Object.prototype.hasOwnProperty.call(config, key)) {
      newConfig = { ...newConfig, [key]: parseInt(config[key], 10) };
    }
  }
  return dot.object(newConfig);
};

const parseDeviceHistory = (deviceHistory: any) => {
  const configs: Array<DeviceConfig> = [];
  if (deviceHistory.length > 0) {
    let date = deviceHistory[0]['updated_at'];
    let config: any = {};

    deviceHistory.forEach((entry: any) => {
      if (date === entry['updated_at']) {
        const key = entry['key'];
        config[key] = entry['value'];
      } else {
        configs.push({ config: parseConfig(config), updated_at: date });
        config = {};
        date = entry['updated_at'];
        const key = entry['key'];
        config[key] = entry['value'];
      }
    });
    configs.push({ config: parseConfig(config), updated_at: date });
  }

  return configs;
};

const combineData = (data: any) => {
  let newData = {};
  data.pages.forEach((page: any) => {
    newData = Object.assign(newData, page.items);
  });
  return Object.values(newData);
};

export const useDeviceHistory = (deviceId: number, enabled: boolean, queryParams: any) => {
  const { data, isLoading, error, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [DEVICE_CONFIG_HISTORY_QUERY_KEY, deviceId],
    ({ pageParam = 0 }) =>
      getDeviceConfigHistory(deviceId, { ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result: data ? parseDeviceHistory(combineData(data)) : null,
    fetchNextPage,
    isLoading,
    hasNextPage,
    error
  };
};

export const useDeviceCreate = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(createDevice, {
    onSuccess() {
      NotificationFactory.successNotification('Device created');
      queryClient.invalidateQueries({ queryKey: [DEVICES_QUERY_KEY] });
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useDeviceDelete = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteDevice, {
    onSuccess() {
      NotificationFactory.successNotification('Device deleted');
      queryClient.invalidateQueries({ queryKey: [DEVICES_QUERY_KEY] });
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useDeviceDetach = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(detachDevice, {
    onSuccess() {
      NotificationFactory.successNotification(
        t("notifications:device_detached', 'Device removed from the user's devices list")
      );
      queryClient.invalidateQueries({ queryKey: [DEVICES_QUERY_KEY] });
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useDeviceUpdate = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data, mutateAsync, isLoading, isError } = useMutation(updateDevice, {
    onSuccess(_, variables) {
      NotificationFactory.successNotification(
        variables?.successMessage ? variables.successMessage : t('notifications:device_updated')
      );
      // Make sure data is reset and refetched
      queryClient.resetQueries({ queryKey: [DEVICES_QUERY_KEY] });
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useDeviceConfig = (deviceId: number, enabled: boolean) => {
  const { data, isLoading, error } = useQuery(
    [DEVICE_CONFIG_QUERY_KEY],
    () => getDeviceConfig(deviceId),
    { enabled }
  );

  return {
    result: data || null,
    isLoading,
    error
  };
};

export const useDeviceCreateDummyPatient = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(createDummyPatientForDevice, {
    onSuccess() {
      NotificationFactory.successNotification('Patient created');
      queryClient.invalidateQueries({ queryKey: [DEVICES_QUERY_KEY] });
    },
    onError(error: any) {
      mapErrorMessage(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
