/* eslint-disable no-unused-vars */
import { useContext, useEffect, useRef, useState } from 'react';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CONFIGURATOR_DEVICE, DEVICE_EDIT, PATIENTS } from '../../../constants/routes';
import { useDeviceDelete } from '../../hooks/api/useDevices';
import { useModal } from '../../hooks/api/useModal';
import Card from 'adp-panel/components/Card/Card';
import useUserData from '../../../hooks/useUserData';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import { RoleEnum } from 'adp-panel/api/users/users.types';
import CustomTable, { ActionsWrapper } from 'adp-panel/components/Table/CustomTable';
import { useInvitedUsers, useResendInvitation, useUsers } from 'adp-panel/hooks/api/useUsers';
import { UsersQueryParams, UserExtendOptions, UserEntry } from 'adp-panel/api/users/users.types';
import { ReactComponent as AddUserSvg } from 'assets/add_user.svg';
import { ReactComponent as SendSvg } from 'assets/send.svg';
import { ReactComponent as SlidersSvg } from 'assets/sliders.svg';
import CustomButton from 'components/Button/CustomButton';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { TableLink } from 'adp-panel/components/Links/Links';
import PatientsModal from 'adp-panel/components/Modals/PatientsModal';
import CustomChip from 'components/CustomChip/CustomChip';
import PermissionsModal from 'adp-panel/components/Modals/PermissionsModal';
import { InvitationStatusEnum } from 'adp-panel/api/users/users.types';
import { shortenString } from 'utils/utils';
import { Avatar, CircularProgress, TextField, Tooltip } from '@mui/material';
import ConfirmDeleteModal from 'adp-panel/components/Modals/ConfirmDeleteModal';
import { parseRole } from 'utils/roles';
import CustomAvatar from 'adp-panel/components/CustomAvatar/CustomAvatar';
import { debounce } from 'lodash';
import { DEBOUNCE_SEARCH_TIME } from 'adp-panel/constants/search';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import MultipleItemsLinks from 'components/MultipleItemsLinks/MultipleItemsLinks';
import LoaderWrapper from 'components/Loader/Loader';

const mapDeviceRows = (apiData: UserEntry[]) =>
  apiData.map((medicalProfessional) => ({
    id: medicalProfessional.id,
    name: medicalProfessional.name,
    email: medicalProfessional.email,
    role: medicalProfessional?.roles?.[0].name,
    amputees: medicalProfessional?.patients,
    status: medicalProfessional?.invitation_status,
    createdBy: medicalProfessional?.created_by
  }));

const MedicalProfessionalsList = () => {
  const { t } = useTranslation('medical_professionals');
  const invitationStatusMap = {
    [InvitationStatusEnum.pending]: {
      text: t('medical_professionals:table.status.pending.text', 'Pending'),
      color: 'warning'
    },
    [InvitationStatusEnum.accepted]: {
      text: t('medical_professionals:table.status.active.text', 'Active'),
      color: 'success'
    },
    [InvitationStatusEnum.expired]: {
      text: t('medical_professionals:table.status.expired.text', 'Invitation expired'),
      color: 'error'
    }
  };
  const { data: userData, rolesByName } = useUserData();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedDevice, setSelectedDevice] = useState<number>();
  const navigate = useNavigate();
  const tableRef = useRef(null) as any;
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [selectedClinicianId, setSelectedClinicianId] = useState<number | null>(null);
  const [selectedClinicianPatients, setSelectedClinicianPatients] = useState<any>(null);
  const { openModal } = useUiStore((state: any) => ({
    openModal: state.openModal
  }));

  const queryParams: UsersQueryParams = {
    perpage: 10,
    page: paginationModel.page + 1,
    extend: [UserExtendOptions.roles, UserExtendOptions.permissions, UserExtendOptions.patients],
    roles: [RoleEnum.clinicAdmin, RoleEnum.clinicianSupport, RoleEnum.clinician],
    active: '-1',
    search: searchTerm
  };
  const isAdmin = userHasPermissions([RoleEnum.superAdmin], rolesByName);

  const {
    result: medicalProfessionalsData,
    total: totalMedicalProfessionals,
    isLoading: isLoadingMedicalProfessionals
  } = useUsers(queryParams, isAdmin);
  const {
    result: medicalProfessionalsDataClinician,
    total: totalMedicalProfessionalsClinician,
    isLoading: isLoadingMedicalProfessionalsClinician
  } = useInvitedUsers({
    perpage: 10,
    page: paginationModel.page + 1,
    extend: [UserExtendOptions.patients, UserExtendOptions.permissions, UserExtendOptions.roles]
  });
  const { mutateAsync: resendInvitation, isLoading: isLoadingResend } = useResendInvitation();

  const { mutateAsync: deleteDevice, isLoading: isLoadingDelete } = useDeviceDelete();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();
  const {
    isOpen: isPermissionsModalOpen,
    handleOpen: handlePermissionsModalOpen,
    handleClose: handlePermissionsModalClose
  } = useModal();
  const {
    isOpen: isSendAgainModalOpen,
    handleOpen: handleSendAgainModalOpen,
    handleClose: handleSendAgainModalClose
  } = useModal();

  const deleteDev = async () => {
    if (selectedDevice) {
      await deleteDevice(selectedDevice);
      handleModalClose();
      tableRef.current.refresh();
    }
  };

  const resendInvitationToClinician = async (clinicianId) => {
    if (!selectedClinicianId) return;

    try {
      await resendInvitation({ userId: Number(clinicianId) });
      handleSendAgainModalClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleConfigure = (serial: string, bluetooth_id: string, deviceId: string) => {
    navigate(CONFIGURATOR_DEVICE, { state: { serial, bluetoothId: bluetooth_id, deviceId } });
  };

  const handleOpenPatients = (patients) => {
    setSelectedClinicianPatients(patients);
    handleModalOpen();
  };

  const medicalProfessionalsDataFiltered =
    medicalProfessionalsData &&
    userData &&
    medicalProfessionalsData.filter((item) => item.id !== userData.id);

  return (
    <DefaultLayout>
      <Card>
        <div>
          {isModalOpen && (
            <PatientsModal
              handleClose={handleModalClose}
              items={selectedClinicianPatients}
              title='Patients'
              link={PATIENTS}
            />
          )}
          {isSendAgainModalOpen && selectedClinicianId && (
            <ConfirmDeleteModal
              handleClose={handleSendAgainModalClose}
              handleAccept={() => resendInvitationToClinician(selectedClinicianId)}
              isLoading={isLoadingResend}
              title={t('medical_professionals:list.modal.send_again.title', 'Send invitation')}
              message={t(
                'medical_professionals:list.modal.send_again.text',
                'Do you want to send invitation again?'
              )}
              acceptMessage={t(
                'medical_professionals:list.modal.send_again.accept',
                'Send invitation'
              )}
            />
          )}
          {selectedClinicianId && isPermissionsModalOpen && (
            <PermissionsModal
              handleClose={handlePermissionsModalClose}
              clinicianId={selectedClinicianId}
            />
          )}
          {(isAdmin && isLoadingMedicalProfessionals) ||
          (!isAdmin && isLoadingMedicalProfessionalsClinician) ? (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          ) : (
            <CustomTable
              actions={
                <div style={{ display: 'flex', gap: '8px' }}>
                  {isAdmin && (
                    <TextField
                      size='small'
                      variant='outlined'
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder={t(
                        'medical_professionals:list.actions.search.placeholder',
                        'Search by name or email'
                      )}
                      onChange={debounce(
                        (e) => setSearchTerm(e.target.value),
                        DEBOUNCE_SEARCH_TIME
                      )}
                    />
                  )}
                  {!isAdmin && (
                    <CustomButton
                      Icon={AddUserSvg}
                      color='light'
                      onClick={() => openModal(MODALS.inviteMedicalProfessional)}>
                      {t(
                        'medical_professionals:list.actions.invite_new_professional',
                        'Invite new Medical Professional'
                      )}
                    </CustomButton>
                  )}
                </div>
              }
              tableData={
                isAdmin ? medicalProfessionalsDataFiltered : medicalProfessionalsDataClinician
              }
              tableHeader={t(
                'medical_professionals:list.table_header',
                'List of Medical Professionals'
              )}
              totalItems={isAdmin ? totalMedicalProfessionals : totalMedicalProfessionalsClinician}
              isLoading={
                isAdmin ? isLoadingMedicalProfessionals : isLoadingMedicalProfessionalsClinician
              }
              columns={[
                {
                  field: 'name',
                  headerName: t('medical_professionals:list.columns.name', 'Name'),
                  renderCell: (params) => {
                    const name = params.row.name
                      ? params.row.name
                      : t('medical_professionals:table.placeholder_name', 'Invited user');
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CustomAvatar name={name} />
                        <div>{name}</div>
                      </div>
                    );
                  },
                  flex: 1
                },
                {
                  field: 'email',
                  headerName: t('medical_professionals:list.columns.email', 'E-mail'),
                  sortable: false,
                  flex: 1
                },
                {
                  field: 'role',
                  headerName: t('medical_professionals:list.columns.role', 'Role'),
                  sortable: false,
                  width: 150,
                  renderCell: (params) => parseRole(params.row.role)
                },
                {
                  field: 'amputees',
                  minWidth: 200,
                  renderCell: (params) => {
                    const { amputees } = params.row;

                    return (
                      <MultipleItemsLinks
                        items={amputees}
                        maxItems={1}
                        link={PATIENTS}
                        handleOpen={handleOpenPatients}
                      />
                    );
                  },
                  renderHeader: (params) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                          pointerEvents: 'auto'
                        }}>
                        {t('medical_professionals:list.columns.patients', 'Patients')}
                        <CustomTooltip
                          color='#667085'
                          title={t(
                            'medical_professionals:list.columns.patients.tooltip',
                            'Patients'
                          )}
                        />
                      </div>
                    );
                  }
                },
                {
                  field: 'status',
                  width: 150,
                  renderCell: (params) => {
                    const { status }: { status: InvitationStatusEnum } = params.row;

                    if (!status) return null;

                    return (
                      <CustomChip
                        label={invitationStatusMap[status].text}
                        // @ts-ignore
                        color={invitationStatusMap[status].color}
                        size='small'
                      />
                    );
                  },
                  renderHeader: (params) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                          pointerEvents: 'auto'
                        }}>
                        {t('medical_professionals:list.columns.status', 'Status')}
                        <CustomTooltip
                          color='#667085'
                          title={t('medical_professionals:list.columns.status.tooltip', 'Status')}
                        />
                      </div>
                    );
                  }
                },
                {
                  field: 'actions',
                  headerName: '',
                  sortable: false,
                  width: 200,
                  renderCell: (params) => {
                    const { status, role, id, createdBy } = params.row;
                    const sendAgain =
                      status === InvitationStatusEnum.expired ||
                      status === InvitationStatusEnum.pending;

                    let actionsArray: any = [];

                    if (!isAdmin) {
                      actionsArray = [
                        ...actionsArray,
                        <Tooltip
                          title={t(
                            'medical_professionals:list.actions.invite',
                            'Invite medical professional'
                          )}>
                          <span>
                            <CustomButton
                              Icon={AddUserSvg}
                              color='light'
                              onClick={() =>
                                openModal(MODALS.inviteMedicalProfessional, {
                                  args: {
                                    clinician: medicalProfessionalsDataClinician.find(
                                      (clinician) => clinician.id === id
                                    )
                                  }
                                })
                              }
                              data-testid='button-add-user'
                            />
                          </span>
                        </Tooltip>
                      ];
                    }

                    if (sendAgain)
                      actionsArray = [
                        <Tooltip
                          title={t('medical_professionals:list_actions_send_again', 'Send again')}>
                          <span>
                            <CustomButton
                              Icon={SendSvg}
                              color='light'
                              onClick={() => {
                                setSelectedClinicianId(id);
                                handleSendAgainModalOpen();
                              }}
                            />
                          </span>
                        </Tooltip>,
                        ...actionsArray
                      ];

                    if (
                      role === RoleEnum.clinicianSupport &&
                      !isAdmin &&
                      createdBy === userData?.id
                    )
                      actionsArray = [
                        <Tooltip
                          title={t(
                            'medical_professionals:list.actions.edit_permissions',
                            'Edit permissions'
                          )}>
                          <span>
                            <CustomButton
                              Icon={SlidersSvg}
                              color='light'
                              onClick={() => {
                                setSelectedClinicianId(id);
                                handlePermissionsModalOpen();
                              }}
                            />
                          </span>
                        </Tooltip>,
                        ...actionsArray
                      ];

                    return <ActionsWrapper>{actionsArray}</ActionsWrapper>;
                  }
                }
              ]}
              mapTableData={mapDeviceRows}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
            />
          )}
        </div>
      </Card>
    </DefaultLayout>
  );
};

export default MedicalProfessionalsList;
