import * as Sentry from '@sentry/react';
import { RoleEnum } from 'adp-panel/api/users/users.types';
import AutoLogout from 'adp-panel/components/AutoLogout/AutoLogout';
import DocumentsModal from 'adp-panel/components/Modals/DocumentsModal';
import { CHAT } from 'adp-panel/constants/featureToggles';
import {
  ALLIED_PERMISSIONS,
  CHAT_PERMISSIONS,
  CODES_PERMISSIONS,
  DEVICES_PERMISSIONS,
  MEDICAL_PROFESSIONALS_PERMISSIONS,
  PATIENTS_PERMISSIONS,
  TICKET_PERMISSIONS
} from 'adp-panel/constants/rolesPermissions';
import ChatWidgetUnreadMessageListener from 'adp-panel/features/Chat/components/ChatRoomUnreadMessageListener';
import ChatWidget from 'adp-panel/features/Chat/components/ChatWidget';
import { CHAT_NOT_ALLOWED_ROUTES } from 'adp-panel/features/Chat/utils/consts';
import { AuthRoute } from 'adp-panel/hoc/AuthRoute';
import ErrorBoundary from 'adp-panel/layouts/ErrorBoundary';
import NotFoundScreenAuthentication from 'adp-panel/pages/404/NotFoundScreenAuthentication';
import AccountDetails from 'adp-panel/pages/Account/AccountDetails';
import ChangePassword from 'adp-panel/pages/Account/ChangePassword';
import AddClinician from 'adp-panel/pages/Clinicians/AddClinician';
import ClinicianDetails from 'adp-panel/pages/Clinicians/ClinicianDetails';
import EditClinician from 'adp-panel/pages/Clinicians/EditClinician';
import Dashboard from 'adp-panel/pages/Dashboard/Dashboard';
import TicketDetails from 'adp-panel/pages/Dashboard/Inbox/TicketDetails';
import AddDevice from 'adp-panel/pages/Devices/Forms/AddDevice';
import DevicesList from 'adp-panel/pages/Devices/DevicesList';
import TogglesList from 'adp-panel/pages/FeatureToggle/TogglesList';
import AddMessage from 'adp-panel/pages/Message/AddMessage';
import AddPatient from 'adp-panel/pages/Patients/Forms/AddPatient';
import PatientDetails from 'adp-panel/pages/Patients/PatientDetails';
import PatientsList from 'adp-panel/pages/Patients/PatientsList';
import ReleaseNoteAdd from 'adp-panel/pages/ReleaseNote/ReleaseNoteAdd';
import ReleaseNoteAddCompatibility from 'adp-panel/pages/ReleaseNote/ReleaseNoteAddCompatibility';
import ReleaseNoteEditCompatibility from 'adp-panel/pages/ReleaseNote/ReleaseNoteEditCompatibility';
import ReleaseNoteList from 'adp-panel/pages/ReleaseNote/ReleaseNoteList';
import VersionAdd from 'adp-panel/pages/ReleaseNote/Versions/VersionAdd';
import GeneralRedirect from 'adp-panel/pages/TicketRedirect/GeneralRedirect';
import TicketRedirect from 'adp-panel/pages/TicketRedirect/TicketRedirect';
import UserManageEdit from 'adp-panel/pages/UserManage/UserManageEdit';
import UserManageList from 'adp-panel/pages/UserManage/UserManageList';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import { FeatureToggle } from 'AppProviders';
import { HISTORY_EVENTS } from 'configurator/consts/consts';
import LayoutSwitcher from 'configurator/hoc/LayoutHoc';
import useUnsaved from 'configurator/hooks/useUnsaved';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useMeetingStore } from 'configurator/reducers/meetingStore';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { ViewModes } from 'configurator/utils/ViewModes/viewModes';
import ChooseGripsComponent from 'configurator/views/ChooseGrips/ChooseGripsComponent';
import DeviceConfigComponent from 'configurator/views/DeviceConfig/DeviceConfig/DeviceConfigComponent';
import DeviceHistoryComponent from 'configurator/views/DeviceConfig/DeviceHistory/DeviceHistoryComponent';
import SessionHistory from 'configurator/views/DeviceConfig/SessionHistory/SessionHistory';
import DeviceInfo from 'configurator/views/DeviceInfo/DeviceInfo';
import GraphComponent from 'configurator/views/Graph/GraphComponent';
import GripsConfigurationComponent from 'configurator/views/GripsConfiguration/GripsConfigurationComponent';
import MainViews from 'configurator/views/MainViews';
import ProsthesisSettingsComponent from 'configurator/views/ProsthesisSettings/ProsthesisSettingsComponent';
import QuickConfiguration from 'configurator/views/Register/QuickConfiguration/QuickConfiguration';
import Register from 'configurator/views/Register/Register/Register';
import ApplicationSettings from 'configurator/views/Settings/ApplicationSettings';
import SupportTicket from 'configurator/views/SupportTicket/SupportTicket';
import ConfigTemplateDetailsComponent from 'configurator/views/Templates/ConfigTemplateDetails/ConfigTemplateDetailsComponent';
import ConfigTemplatesComponent from 'configurator/views/Templates/ConfigTemplates/ConfigTemplatesComponent';
import ServiceMenu from 'configurator/views/Tools/ServiceMenu';
import * as routes from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { canBeShownBasedOnNotAllowedRoutes } from 'utils/filterRoutes';
import ServicingForm from 'configurator/views/Servicing/ServicingForm';
import EditDevice from 'adp-panel/pages/Devices/Forms/EditDevice';
import MedicalProfessionalsList from 'adp-panel/pages/Clinicians/MedicalProfessionalsList';
import DeviceDetails from 'adp-panel/pages/Devices/DeviceDetails';
import ActivationCodesList from 'adp-panel/pages/ActivationCodes/ActivationCodesList';
import Section2FA from 'adp-panel/pages/Auth/RegisterUser/Section2FA';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

type AuthenticatedAppLayoutProps = {
  currentUser: any;
  documentStatusModalOpen: boolean;
  documentsResults: any;
  submitDocuments: any;
  logoutDocuments: any;
  isLoadingDocuments: boolean;
  rolesByName: any;
};

const ConfiguratorRoutes = () => (
  <MainViews>
    <Outlet />
  </MainViews>
);

const AuthenticatedAppLayout = ({
  currentUser,
  documentStatusModalOpen,
  documentsResults,
  submitDocuments,
  isLoadingDocuments,
  logoutDocuments,
  rolesByName
}: AuthenticatedAppLayoutProps) => {
  const { viewMode } = useMeetingStore((state) => ({
    viewMode: state.viewMode
  }));
  const replayIsEnabled = useReplayStore((state) => state.enabled);
  const consumeHistory = useConfigStore((state) => state.consumeHistory);
  const { isUnsaved } = useUnsaved();
  const { t } = useTranslation('views');

  return (
    <ErrorBoundary>
      {documentStatusModalOpen && (
        <DocumentsModal
          title={documentsResults.texts.title}
          description={documentsResults.texts.description}
          checkboxText={documentsResults.texts.checkbox}
          acceptAction={submitDocuments}
          logoutAction={logoutDocuments}
          files={documentsResults.documents}
          isLoading={isLoadingDocuments}
        />
      )}
      <FeatureToggle name={CHAT}>
        {userHasPermissions(CHAT_PERMISSIONS.VIEW, rolesByName) &&
          canBeShownBasedOnNotAllowedRoutes(window.location.pathname, CHAT_NOT_ALLOWED_ROUTES) && (
            <>
              <ChatWidgetUnreadMessageListener />
              <ChatWidget />
            </>
          )}
      </FeatureToggle>
      <AutoLogout user={currentUser}>
        <SentryRoutes>
          <Route element={<ConfiguratorRoutes />}>
            <Route index path={routes.DASHBOARD} element={<Dashboard />} />
            <Route index path={routes.REGISTER_USER_2FA} element={<Section2FA />} />
            <Route
              path={routes.MEDICAL_PROFESSIONALS}
              element={
                <AuthRoute user={currentUser} roles={MEDICAL_PROFESSIONALS_PERMISSIONS.VIEW}>
                  <MedicalProfessionalsList />
                </AuthRoute>
              }
            />
            {/*<Route*/}
            {/*  path={routes.MANAGE_CLINICIANS_PATIENTS}*/}
            {/*  element={*/}
            {/*    <AuthRoute user={currentUser} roles={PATIENTS_PERMISSIONS.LIST}>*/}
            {/*      <CliniciansManage />*/}
            {/*    </AuthRoute>*/}
            {/*  }*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path={routes.MANAGE_CLINICIANS_ALLIED}*/}
            {/*  element={*/}
            {/*    <AuthRoute user={currentUser} roles={ALLIED_PERMISSIONS.LIST}>*/}
            {/*      <CliniciansManage />*/}
            {/*    </AuthRoute>*/}
            {/*  }*/}
            {/*/>*/}
            <Route
              path={routes.CLINICIAN_ADD}
              element={
                <AuthRoute user={currentUser} roles={ALLIED_PERMISSIONS.CREATE}>
                  <AddClinician />
                </AuthRoute>
              }
            />
            <Route
              path={routes.CLINICIAN_EDIT}
              element={
                <AuthRoute user={currentUser} roles={ALLIED_PERMISSIONS.EDIT}>
                  <EditClinician />
                </AuthRoute>
              }
            />
            <Route
              path={routes.CLINICIAN}
              element={
                <AuthRoute user={currentUser} roles={ALLIED_PERMISSIONS.DETAILS}>
                  <ClinicianDetails />
                </AuthRoute>
              }
            />
            <Route
              path={routes.USER_MANAGE}
              element={
                <AuthRoute user={currentUser} roles={[RoleEnum.superAdmin]}>
                  <UserManageList />
                </AuthRoute>
              }
            />
            <Route
              path={routes.USER_MANAGE_EDIT}
              element={
                <AuthRoute user={currentUser} roles={[RoleEnum.superAdmin]}>
                  <UserManageEdit />
                </AuthRoute>
              }
            />
            <Route
              path={routes.DEVICE}
              element={
                <AuthRoute user={currentUser} roles={DEVICES_PERMISSIONS.LIST}>
                  <DevicesList />
                </AuthRoute>
              }
            />
            <Route
              path={routes.DEVICE_DETAILS}
              element={
                <AuthRoute user={currentUser} roles={DEVICES_PERMISSIONS.DETAILS}>
                  <DeviceDetails />
                </AuthRoute>
              }
            />
            <Route
              path={routes.DEVICE_ADD}
              element={
                <AuthRoute user={currentUser} roles={DEVICES_PERMISSIONS.CREATE}>
                  <AddDevice />
                </AuthRoute>
              }
            />
            <Route
              path={routes.DEVICE_EDIT}
              element={
                <AuthRoute user={currentUser} roles={DEVICES_PERMISSIONS.EDIT}>
                  <EditDevice />
                </AuthRoute>
              }
            />
            <Route
              path={routes.ACCOUNT}
              element={
                <AuthRoute user={currentUser}>
                  <AccountDetails />
                </AuthRoute>
              }
            />
            <Route
              path={routes.ACCOUNT_CHANGE_PASSWORD}
              element={
                <AuthRoute user={currentUser}>
                  <ChangePassword />
                </AuthRoute>
              }
            />
            <Route
              path={routes.MESSAGE}
              element={
                <AuthRoute user={currentUser}>
                  <AddMessage />
                </AuthRoute>
              }
            />
            <Route
              path={routes.PATIENTS}
              element={
                <AuthRoute user={currentUser} roles={PATIENTS_PERMISSIONS.LIST}>
                  <PatientsList />
                </AuthRoute>
              }
            />
            <Route
              path={routes.PATIENT_ADD}
              element={
                <AuthRoute user={currentUser} roles={PATIENTS_PERMISSIONS.CREATE}>
                  <AddPatient />
                </AuthRoute>
              }
            />
            <Route
              path={routes.PATIENT}
              element={
                <AuthRoute user={currentUser} roles={PATIENTS_PERMISSIONS.DETAILS}>
                  <PatientDetails />
                </AuthRoute>
              }
            />
            <Route
              path={routes.RELEASES}
              element={
                <AuthRoute user={currentUser} roles={[RoleEnum.superAdmin]}>
                  <ReleaseNoteList />
                </AuthRoute>
              }
            />
            <Route
              path={routes.RELEASE_ADD}
              element={
                <AuthRoute user={currentUser} roles={[RoleEnum.superAdmin]}>
                  <ReleaseNoteAdd />
                </AuthRoute>
              }
            />
            <Route
              path={routes.RELEASE_EDIT}
              element={
                <AuthRoute user={currentUser} roles={[RoleEnum.superAdmin]}>
                  <ReleaseNoteEditCompatibility />
                </AuthRoute>
              }
            />
            <Route
              path={routes.VERSION_ADD}
              element={
                <AuthRoute user={currentUser} roles={[RoleEnum.superAdmin]}>
                  <VersionAdd />
                </AuthRoute>
              }
            />
            <Route
              path={routes.RELEASE_COMPATIBILITY_ADD}
              element={
                <AuthRoute user={currentUser} roles={[RoleEnum.superAdmin]}>
                  <ReleaseNoteAddCompatibility />
                </AuthRoute>
              }
            />
            <Route
              path={routes.RELEASE_COMPATIBILITY_EDIT}
              element={
                <AuthRoute user={currentUser} roles={[RoleEnum.superAdmin]}>
                  <ReleaseNoteAddCompatibility />
                </AuthRoute>
              }
            />
            <Route
              path={routes.FEATURE_TOGGLES}
              element={
                <AuthRoute user={currentUser} roles={[RoleEnum.superAdmin]}>
                  <TogglesList />
                </AuthRoute>
              }
            />
            <Route
              path={routes.FEATURE_TOGGLES}
              element={
                <AuthRoute user={currentUser} roles={[RoleEnum.superAdmin]}>
                  <TogglesList />
                </AuthRoute>
              }
            />
            <Route
              path={routes.TICKET}
              element={
                <AuthRoute user={currentUser} roles={TICKET_PERMISSIONS.VIEW}>
                  <TicketDetails />
                </AuthRoute>
              }
            />
            <Route path={routes.TICKET_REDIRECT} element={<TicketRedirect />} />
            <Route path={routes.REDIRECT} element={<GeneralRedirect />} />
            {/*<Route path='/' element={<GeneralRedirect />} />*/}
            <Route path={routes.REGISTER} element={<Register />} />
            <Route path={routes.CONFIGURATION} element={<QuickConfiguration />} />
            <Route path={routes.CONFIGURATOR_DEVICE} element={<DeviceInfo />} />
            <Route path={routes.SUPPORT_TICKET} element={<SupportTicket />} />
            <Route path={routes.CONFIGURATOR_HOME}>
              <Route path='*' element={<Navigate to={routes.CHOOSE_GRIPS} replace />} />
            </Route>
            <Route
              path={routes.CHOOSE_GRIPS}
              element={
                <LayoutSwitcher
                  handleUndo={() => consumeHistory(HISTORY_EVENTS.chooseGrips)}
                  isUnsaved={isUnsaved}
                  isStandard={viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG}
                  component={ChooseGripsComponent}
                />
              }
            />
            <Route
              path={routes.PROSTHESIS_SETTINGS}
              element={
                <LayoutSwitcher
                  header={t('configurator:navbar.prosthesis_settings', 'Prosthesis settings')}
                  handleUndo={() => consumeHistory(HISTORY_EVENTS.prosthesisSettings)}
                  isUnsaved={isUnsaved}
                  isStandard={viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG}
                  component={ProsthesisSettingsComponent}
                  tooltipText={t('configurator:header.prosthesis_settings.tooltip', 'Tooltip')}
                />
              }
            />
            <Route
              path={routes.APPLICATION_SETTINGS}
              element={
                <LayoutSwitcher
                  header={t('configurator:navbar.application_settings', 'About application')}
                  isUnsaved={isUnsaved}
                  isStandard={viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG}
                  component={ApplicationSettings}
                  tooltipText={t('configurator:header.application_settings.tooltip', 'Tooltip')}
                />
              }
            />
            <Route
              path={routes.GRIPS_CONFIGURATION}
              element={
                <LayoutSwitcher
                  handleUndo={() => consumeHistory(HISTORY_EVENTS.gripsConfiguration)}
                  isUnsaved={isUnsaved}
                  isStandard={viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG}
                  component={GripsConfigurationComponent}
                />
              }
            />
            <Route
              path={routes.GRAPH}
              element={
                <LayoutSwitcher
                  header={
                    replayIsEnabled
                      ? t('configurator:navbar.emg_playback')
                      : t('configurator:navbar.emg_settings')
                  }
                  handleUndo={() => consumeHistory(HISTORY_EVENTS.emgSettings)}
                  isUnsaved={isUnsaved}
                  isStandard={viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG}
                  component={GraphComponent}
                  tooltipText={t('configurator:header.emg_settings.tooltip', 'Tooltip')}
                />
              }
            />
            <Route
              path={routes.SERVICE_MENU}
              element={
                <LayoutSwitcher
                  header={t('configurator:navbar.tools', 'Tools')}
                  isUnsaved={isUnsaved}
                  isStandard={viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG}
                  component={ServiceMenu}
                  tooltipText={t('configurator:header.tools.tooltip', 'Tooltip')}
                />
              }
            />
            <Route
              path={routes.DEVICE_HISTORY_DETAIL}
              element={
                <LayoutSwitcher
                  isStandard={viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG}
                  isUnsaved={isUnsaved}
                  component={DeviceConfigComponent}
                />
              }
            />
            <Route
              path={routes.SESSION_HISTORY}
              element={
                <LayoutSwitcher
                  isStandard={viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG}
                  component={SessionHistory}
                  isUnsaved={isUnsaved}
                />
              }
            />
            <Route
              path={routes.DEVICE_HISTORY}
              element={
                <LayoutSwitcher
                  isStandard={viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG}
                  isUnsaved={isUnsaved}
                  component={DeviceHistoryComponent}
                />
              }
            />
            <Route
              path={routes.CONFIG_TEMPLATES_DETAIL}
              element={
                <LayoutSwitcher
                  isStandard={viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG}
                  isUnsaved={isUnsaved}
                  component={ConfigTemplateDetailsComponent}
                />
              }
            />
            <Route
              path={routes.CONFIG_TEMPLATES}
              element={
                <LayoutSwitcher
                  isStandard={viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG}
                  isUnsaved={isUnsaved}
                  component={ConfigTemplatesComponent}
                />
              }
            />
            <Route
              path={routes.ACTIVATION_CODES}
              element={
                <AuthRoute user={currentUser} roles={CODES_PERMISSIONS.VIEW}>
                  <ActivationCodesList />
                </AuthRoute>
              }
            />
          </Route>
          <Route path={routes.LOGIN} element={<Navigate to={routes.REDIRECT} replace />} />
          <Route path='/' element={<Navigate to={routes.REDIRECT} replace />} />
          <Route path='*' element={<NotFoundScreenAuthentication />} />
        </SentryRoutes>
      </AutoLogout>
    </ErrorBoundary>
  );
};

export default AuthenticatedAppLayout;
