import CustomTextField from 'components/FormFields/CustomTextField';
import { useTranslation } from 'react-i18next';
import FormComboBox, { deviceOption, mapDevices } from 'components/FormFields/FormComboBox';
import { Button, CircularProgress, Step, StepLabel, Stepper, Typography } from '@mui/material';
import CustomPasswordField from 'components/FormFields/CustomPasswordField';
import { useDevicesListInfinite } from 'adp-panel/hooks/api/useDevices';
import { DeviceExtendOptions, DevicesQueryParams } from 'adp-panel/api/devices/device.types';
import { GroupedFormField } from '../styled';
import { useEffect, useState } from 'react';
import { myTheme } from 'theme/theme';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import useUserData from 'hooks/useUserData';
import LoaderWrapper from 'components/Loader/Loader';
import { WarningHelperText } from 'components/FormFields/utils/WarningHelperText';
import { InviteMedicalProfessionalComponent } from 'adp-panel/components/Modals/InviteMedicalProfessionalModal/InviteMedicalProfessionalsComponent';
import useInviteMedicalProfessional, {
  useMedicalProfessionalsInviteList
} from 'adp-panel/components/Modals/InviteMedicalProfessionalModal/useInviteMedicalProfessional';
import { getDefaultMedicalProfessionalsValues } from 'adp-panel/components/Modals/InviteMedicalProfessionalModal/utils';
import { medicalProfessionalsSchema } from 'adp-panel/components/Modals/InviteMedicalProfessionalModal/schema';
import { patientSchema } from './schema';

interface PatientFormProps {
  existingData?: any;
  handleSubmit: any;
  isLoading: boolean;
  isAdmin?: boolean;
  formStep: any;
  setFormStep: any;
  steps: any;
}

const PatientForm = ({
  existingData,
  handleSubmit: onSubmit,
  isLoading,
  isAdmin = false,
  formStep,
  setFormStep,
  steps
}: PatientFormProps) => {
  const { t } = useTranslation('patients');
  const { cliniciansData } = useMedicalProfessionalsInviteList();

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    retypePassword: '',
    device: null,
    ...getDefaultMedicalProfessionalsValues({
      selectedPatient: null,
      defaultClinician: null,
      noEmptyClinician: true
    })
  };

  const patientSchemas = [patientSchema, medicalProfessionalsSchema(cliniciansData, false)];

  const isEdit = Boolean(existingData);
  const {
    control,
    updatedPatientsData,
    fields,
    watch,
    convertRoleToProperRenderValue,
    errors,
    updatedCliniciansData,
    isHelperMessagesAvailable,
    remove,
    append,
    isLastFilled,
    clinicians,
    handleSubmit,
    reset,
    setValue,
    trigger,
    register
  } = useInviteMedicalProfessional<{
    firstName: string;
    lastName: string;
    email: string;
    password: string | null;
    retypePassword: string | null;
    device: any;
    clinicians: any;
  }>({
    defaultValues: isEdit ? existingData : defaultValues,
    schema: patientSchemas[formStep]
  });

  const [password, device] = watch(['password', 'device']);
  const { data: userData } = useUserData();
  const ME = userData ? [{ ...userData, nameFull: `${userData.name} (${userData.email})` }] : [];

  const queryParamsDevice: DevicesQueryParams = {
    perpage: 1000,
    extend: [DeviceExtendOptions.amputee]
  };
  const { result: devices } = useDevicesListInfinite(queryParamsDevice, true);

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setFormStep((prevActiveStep) => prevActiveStep + 1);
  };

  const initialFetchData = devices;

  useEffect(() => {
    if (password === '' || password === null || password === undefined)
      setValue('retypePassword', '');
  }, [password]);

  const onKeyDown = (e) => {
    if (formStep === steps[0].id && e?.key === 'Enter') {
      e.preventDefault();
      handleNext();
      return;
    }
  };

  if (!initialFetchData)
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={onKeyDown}>
      <Stepper alternativeLabel activeStep={formStep} sx={{ marginBottom: '36px' }}>
        {steps.map((step) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel sx={{ '& .MuiStepLabel-label': { fontSize: '16px' } }} {...labelProps}>
                {step.label}
              </StepLabel>
              <Typography
                sx={{ color: myTheme.palette.subtleText, textAlign: 'center', fontSize: '14px' }}>
                {step.content}
              </Typography>
            </Step>
          );
        })}
      </Stepper>
      <div style={{ display: formStep === steps[0].id ? 'block' : 'none' }}>
        <FormWrapper>
          <GroupedFormField>
            <CustomTextField
              label={t('patients:patient_form.first_name', 'First Name')}
              id='firstName'
              control={control}
            />
            <CustomTextField
              label={t('patients:patient_form.last_name', 'Last Name')}
              id='lastName'
              control={control}
            />
          </GroupedFormField>
          <CustomTextField
            label={t('patients:patient_form.email', 'E-mail')}
            id='email'
            control={control}
          />
          <GroupedFormField>
            <CustomPasswordField
              label={t('patients:patient_form.password', 'Password')}
              id='password'
              control={control}
              optional
            />
            {password && (
              <CustomPasswordField
                label={t('patients:patient_form.retype_password', 'Re-type Password')}
                id='retypePassword'
                control={control}
              />
            )}
          </GroupedFormField>
          <FormComboBox
            label={t('patients:patient_form.device', 'Device')}
            id='device'
            control={control}
            options={mapDevices(devices)}
            optionLabel={'fullName'}
            renderOption={(props, option) =>
              deviceOption(props, option, 'fullName', option?.amputee?.name, t)
            }
            optional
            key='device'
            helperText={
              device?.amputee ? (
                <WarningHelperText>
                  {t(
                    'patients:patient_form.helperText.deviceAssigned',
                    'This device is already assigned to other patient. By assigning it to this patient, you will unassign it from current user.'
                  )}
                </WarningHelperText>
              ) : undefined
            }
          />
          <FormButtonsWrapper>
            <Button data-testid='continue-patient-form' type='button' onClick={handleNext}>
              <span>{t('patients:patient_form.continue', 'Continue')}</span>
            </Button>
          </FormButtonsWrapper>
        </FormWrapper>
      </div>
      <div style={{ display: formStep === steps[1].id ? 'block' : 'none' }}>
        <FormWrapper>
          <InviteMedicalProfessionalComponent
            control={control}
            updatedPatientsData={updatedPatientsData}
            fields={fields}
            watch={watch}
            convertRoleToProperRenderValue={convertRoleToProperRenderValue}
            errors={errors}
            updatedCliniciansData={updatedCliniciansData}
            isHelperMessagesAvailable={isHelperMessagesAvailable}
            remove={remove}
            append={append}
            isLastFilled={isLastFilled}
            patientRequired={false}
            me={ME}
            register={register}
            setValue={setValue}
            trigger={trigger}
          />
          <FormButtonsWrapper>
            <CustomButton
              data-testid='back-patient-form'
              type='button'
              color='light'
              onClick={() => setFormStep(steps[0].id)}>
              {t('patients:patient_form.back', 'Back')}
            </CustomButton>
            <CustomButton data-testid='submit-patient-form' type='submit' loading={isLoading}>
              {t('patients:patient_form.send_invitation', 'Send invitation')}
            </CustomButton>
          </FormButtonsWrapper>
        </FormWrapper>
      </div>
    </form>
  );
};

export default PatientForm;
