import { isEmpty } from 'lodash';
import toast from 'react-hot-toast';
import useSynchronizeBluetooth from 'configurator/hooks/bluetooth/useSynchronizeBluetooth';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { MODALS } from 'configurator/views/Modals';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import {
  useImportTemplate,
  useRestoreConfig,
  useSendTestConfig,
  useUpdateDeviceConfig
} from './useDevice';
import { BLOCK_MODALS } from 'configurator/consts/blockModals';
import { getAllHashes } from 'configurator/bluetooth-handler/bluetoothFunctions';
import { getFwVersionSelector } from 'configurator/reducers/helpers/selectors';
import { updateDeviceHashes } from 'configurator/api/device/device';
import { NotificationFactory } from 'lib/NotificationFactory';
import { useTranslation } from 'react-i18next';

interface ConfigOptions {
  updateConfigWhenDeviceIsConnected?: boolean;
}
export const useDeviceManager = () => {
  const { t } = useTranslation();
  const { updateConfig, isLoading: isLoadingUpdateDeviceConfig } = useUpdateDeviceConfig();
  const { sendTest, isLoading: isLoadingSendTest } = useSendTestConfig();
  const { mutateAsync: restoreConfig, isLoading: isLoadingRestoreConfig } = useRestoreConfig();
  const { mutateAsync: importTemplate, isLoading: isLoadingImportTemplate } = useImportTemplate();
  const { deviceId, deviceConnected } = useDeviceInfoStore((state) => ({
    deviceId: Number(state.deviceId),
    deviceConnected: state.connected
  }));
  const { amputeeId } = useDeviceInfoStore((state) => ({ amputeeId: state.amputeeId }));
  const deviceInfoState = useDeviceInfoStore((state) => state);
  const { getInitialConfig, config, getInitialConfigAPI } = useConfigStore((state) => ({
    config: state.config,
    getInitialConfig: state.getInitialConfig,
    getInitialConfigAPI: state.getInitialConfigAPI,
    importConfig: state.importConfig
  }));
  const { synchronizeConfig } = useSynchronizeBluetooth();
  const { openModal, blockScreen, unblockScreen } = useUiStore((state) => ({
    openModal: state.openModal,
    blockScreen: state.blockScreen,
    unblockScreen: state.unblockScreen
  }));
  const { enabled: sessionEnabled, channel: sessionChannel } = useLiveConfiguratorStore(
    (state) => ({
      enabled: state.enabled,
      channel: state.channel
    })
  );

  const sendConfigToDevice = async () => {
    try {
      if (deviceId) {
        await synchronizeConfig(config, true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const retrieveConfig = async () => {
    openModal(MODALS.restoreRecent);
  };

  const handleChangingConfigState = async (updatedConfig) => {
    try {
      blockScreen(BLOCK_MODALS.CHANGING_CONFIG_STATE);
      const configPayload = {
        deviceId,
        data: {
          common: JSON.stringify(updatedConfig.common),
          modes: updatedConfig.modes.map((mode) => ({
            id: mode.id,
            config: JSON.stringify(mode.config)
          }))
        }
      };
      const newConfigComplete = await updateConfig(configPayload);
      await getInitialConfigAPI();

      const { common, modes } = await getInitialConfig();
      const newConfig = {
        common: { config: common, configAPI: newConfigComplete?.common },
        modes
      };
      await synchronizeConfig(newConfig);
      unblockScreen(BLOCK_MODALS.CHANGING_CONFIG_STATE);
    } catch (err) {
      console.log(err);
      unblockScreen(BLOCK_MODALS.CHANGING_CONFIG_STATE);
    }
  };

  const restoreConfigHistory = async (configId: number) => {
    const updatedConfig = await restoreConfig({
      deviceId,
      configId
    });
    if (!deviceConnected) {
      toast('Config will be imported after patient accepts changes in the mobile application', {
        duration: 5000,
        icon: '❕'
      });
      return;
    }
    const withTicket = updatedConfig?.messages?.[0];
    const newConfig = withTicket
      ? JSON.parse(updatedConfig?.messages?.[0].attachments[1].attachment)
      : updatedConfig;
    handleChangingConfigState(newConfig);
  };

  const importTemplateConfig = async (modeId: number, templateId: number) => {
    const updatedConfigMessage = await importTemplate({ deviceId, modeId, templateId });
    if (!deviceConnected) {
      toast('Template will be imported after patient accepts changes in the mobile application', {
        duration: 5000,
        icon: '❕'
      });
      return;
    }
    const withTicket = updatedConfigMessage?.messages?.[0];
    const newConfig = withTicket
      ? JSON.parse(updatedConfigMessage?.messages?.[0].attachments[1].attachment)
      : updatedConfigMessage;
    handleChangingConfigState(newConfig);
  };

  const sendConfigOnlyToApi = async (
    description?,
    options: ConfigOptions = { updateConfigWhenDeviceIsConnected: true }
  ) => {
    try {
      blockScreen(BLOCK_MODALS.SEND_CONFIG_API);
      const commonConfig = config.common.config;
      let sendTestConfigResponse;

      if (deviceId && !isEmpty(commonConfig)) {
        const modesPayload = config.modes.map((mode) => ({
          id: mode.id!,
          config: JSON.stringify(mode.config)
        }));

        const configPayload = {
          deviceId,
          data: {
            common: JSON.stringify(commonConfig),
            modes: modesPayload
          }
        };

        if (amputeeId) {
          sendTestConfigResponse = await sendTest({
            deviceId: configPayload.deviceId,
            data: {
              ...configPayload.data,
              description,
              p2p_session: sessionEnabled ? sessionChannel.id : null
            }
          });
        }

        if (deviceConnected && options.updateConfigWhenDeviceIsConnected) {
          await updateConfig(configPayload);
          await getInitialConfigAPI();

          const firmwareSupportsHash = getFwVersionSelector(deviceInfoState) >= 20301;
          const hashSupported = await getAllHashes(firmwareSupportsHash);
          if (hashSupported) {
            await updateDeviceHashes({
              deviceId,
              data: {
                hash_global: String(hashSupported.globalHash),
                hash_mode1: String(hashSupported.modeHashes[0]),
                hash_mode2: String(hashSupported.modeHashes[1]),
                hash_mode3: String(hashSupported.modeHashes[2])
              }
            });
          }
        }

        if (sessionEnabled) {
          await updateConfig(configPayload);
          await getInitialConfigAPI();
        }

        NotificationFactory.successNotification(
          t('notifications:device_configuration_updated', 'Zeus hand configuration updated'),
          '',
          {
            id: 'deviceConfigurationUpdatedToast'
          }
        );
      }
      unblockScreen(BLOCK_MODALS.SEND_CONFIG_API);
      return sendTestConfigResponse;
    } catch (error) {
      NotificationFactory.errorNotification('Updating device failed', '', {
        id: 'deviceConfigurationUpdatedFailToast'
      });
      unblockScreen(BLOCK_MODALS.SEND_CONFIG_API);
      console.log('error', error);
      return null;
    }
  };

  return {
    sendConfigToDevice,
    restoreConfigHistory,
    importTemplateConfig,
    sendConfigOnlyToApi,
    retrieveConfig,
    isLoadingDeviceManager:
      isLoadingUpdateDeviceConfig ||
      isLoadingRestoreConfig ||
      isLoadingImportTemplate ||
      isLoadingSendTest
  };
};
